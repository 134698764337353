import _ from 'lodash';
import styled from 'styled-components';
import { ColorName } from '../../Variables';
import RESPONSIVE_VALUE from '../responsive.value';
import generateResponsive from '../responsive';

interface SectionProps {
  colSize?: number;
  gridTemplate?: number;
  direction?: string;
  padding?: string;
  background?: string;
  margin?: string;
  buttonMargin?: string;
  color?: string;
  flex?: string;
  disable?: boolean;
  buttonWidth?: string;
  buttonHeight?: string;
  col?: string;
  colGap?: string;
  rowGap?: string;
  height?: string;
  fontSize?: string;
  width?: string;
  isMaxWidth?: boolean;
  maxWidth?: string;
  minHeight?: string;
  fragment?: string;
  position?: string;
  flexColumn?: boolean;
  flexRow?: boolean;
  isNonePadding?: boolean;
  top?: string;
  left?: string;
  key?: string;
  styleContent?: any;
  mimax?: string;
}

const HorizontalContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: ${(props: SectionProps) => props.flex || 'unset'};
`;

const VerticalContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: ${(props: SectionProps) => props.flex || 'unset'};
`;

const Content = styled.div`
  width: 100%;
  max-width: ${(props: SectionProps) => props.maxWidth || '1440px'};
  margin: 0 auto;
  padding: ${(props: SectionProps) => (props.isNonePadding ? 'unset !important' : '0 80px')};
  display: flex;
  box-sizing: border-box;
  flex-direction: ${(props: SectionProps) => props.direction || 'row'};
  height: ${(props: SectionProps) => props.height || 'auto'};
  min-height: ${(props: SectionProps) => props.minHeight || 'auto'};
  position: ${(props: SectionProps) => props.position || 'unset'};
  top: ${(props: SectionProps) => props.top || 'unset'};
  left: ${(props: SectionProps) => props.left || 'unset'};

  ${generateResponsive(RESPONSIVE_VALUE.CONTENT)}

  &.news {
    @media screen and (max-width: 836px) {
      display: flex;
      flex-direction: column;
    }
  }
  &.header-content {
    max-width: 1920px;
    @media screen and (max-width: 1919px) {
      max-width: 1440px;
    }
  }
`;

const DefaultContent = styled(Content)`
  &.b-0 {
    position: absolute;
    padding-bottom: 50px;
    width: 100%;
    margin: 0 auto;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    align-items: flex-end;
  }
`;

const Link = styled.a`
  text-decoration: none;
  user-select: none;
  position: relative;
  &:hover {
    cursor: pointer;
  }
  &.primary {
    font-weight: 600;
    color: ${ColorName.burntSienna};
    transition: all ease 0.25s;
    &:hover {
      text-decoration: underline;
    }
  }
  &:active,
  &:visited {
    color: ${ColorName.black};
  }
`;

const SectionTitle = styled.h2`
  font-size: ${(props: SectionProps) => props.fontSize || '40px'};
  font-weight: bold;
  margin: 0;
  margin-bottom: 0.7em;
  &.center {
    text-align: center;
  }
  ${generateResponsive(RESPONSIVE_VALUE.SECTION_TITLE)}
`;

const PageContainer = styled.div`
  width: 100vw;
  max-width: 1920px;
  min-height: ${(props: SectionProps) => props.minHeight || 'unset'};
  margin: 0 auto;
  padding: ${(props: SectionProps) => props.padding || 'unset'};
`;

const calculateCol = (gridCol: string | undefined) => {
  if (gridCol) {
    const parseCol = _.parseInt(gridCol);
    if (_.isNumber(parseCol)) {
      if (_.isEqual(parseCol, 2)) return 'repeat(1, 1fr);';
      if (_.isEqual(parseCol, 3)) return 'repeat(1, 1fr);';
      if (_.isEqual(parseCol, 4)) return 'repeat(2, 1fr);';
      return `repeat(${gridCol}, 1fr);`;
    }
  }
};

const GridLayout = styled.div`
  display: grid;
  grid-template-columns: ${(props: SectionProps) => `repeat(${props.col}, 1fr)` || `repeat(1, 1fr)`};
  column-gap: ${(props: SectionProps) => props.colGap};
  row-gap: ${(props: SectionProps) => props.rowGap};
  max-width: ${(props: SectionProps) => props.maxWidth || 'unset'};
  margin: ${(props: SectionProps) => props.margin || 'unset'};

  @media screen and (max-width: 1025px) {
    grid-template-columns: ${(props: SectionProps) => calculateCol(props.col)};
  }

  @media screen and (max-width: 836px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: 426px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: 376px) {
    grid-template-columns: repeat(1, 1fr);
  }

  &.custom-assist {
    @media screen and (max-width: 426px) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &.custom-ourpartners {
    @media screen and (max-width: 836px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  &.none-responsive {
    @media screen and (max-width: 1024px) {
      grid-template-columns: ${(props: SectionProps) => `repeat(${props.col}, 1fr)` || `repeat(1, 1fr)`};
    }
  }
`;

const GridIDRAPage = styled.div`
  display: grid;
  grid-template-columns: ${(props: SectionProps) =>
    `repeat(auto-fill,minmax(${props.mimax ? props.mimax : '350px'}, 1fr))`};
  column-gap: ${(props: SectionProps) => props.colGap};
  row-gap: ${(props: SectionProps) => props.rowGap};
  max-width: ${(props: SectionProps) => props.maxWidth || 'unset'};
  margin: ${(props: SectionProps) => props.margin || 'unset'};
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100vw - 230px);
  height: calc(100vh - 86px);
  padding: 30px 50px 40px;
  box-sizing: border-box;
  overflow: auto;

  &,
  & > div {
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: ${ColorName.aquaHaze};
    }
    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
      background-color: ${ColorName.darkElectricBlue};
    }
    &::-webkit-scrollbar-thumb {
      background-color: ${ColorName.darkElectricBlue};
      border: 2px solid #555555;
    }
  }

  .editable {
    padding: 3px 5px;
    border: 2px solid transparent;
    border-radius: 5px;
    outline: none;
    &:hover {
      border: 2px solid ${ColorName.geyser};
      cursor: text;
    }
    &:focus {
      border: 2px solid ${ColorName.darkElectricBlue};
    }
  }
  .editable:empty:before {
    content: attr(data-placeholder);
  }
  .editable:empty:focus:before {
    content: '';
  }
`;

const PreviewImage = styled.div`
  width: 100%;
  flex: 1;
  background: ${ColorName.geyser};
  position: relative;
  img {
    width: 100%;
    object-fit: contain;
    box-sizing: border-box;
    height: 100%;
    margin: 0 auto;
    display: flex;
  }
  iframe {
    position: relative;

    &:before {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
  }
  &.client {
    height: 200px;
    img {
      height: 100%;
      width: auto;
    }
  }
`;

const ContentForm = styled.div`
  position: relative;
  max-width: ${(props: SectionProps) => props.maxWidth || '720px'};

  h2 {
    font-size: 30px;
    line-height: 43px;
  }
`;

export {
  GridIDRAPage,
  HorizontalContainer,
  VerticalContainer,
  Link,
  Content,
  SectionTitle,
  DefaultContent,
  PageContainer,
  GridLayout,
  ContentContainer,
  PreviewImage,
  ContentForm,
};
