/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { DefaultAdminLayout } from '../../../components/Layout/DefaultLayout';
import { NormalTable } from '../../../components/Layout/CommonLayoutPart/Table';
import history from '../../../history';
import {
  AdminDropdown,
  FlexHorizontal,
  DarkElectriSolidcButton,
  CarrotSolidButton,
  GeyserSolidButton,
  InputSearchField,
  AdminPagination,
  AdminLabel,
  ContentContainer,
} from '../../../components/Common';
import { ArticlesActions } from '../../../actions';
import { statusAlert } from '../../../libs';
import { ColumnsTable, DropdownOptions } from '../../../constants';
import { ROUTES } from '../../../constants';

const {
  setAdminArticlesFilter,
  filterArticles,
  removeSingleArticles,
  removeMultipleArticles,
  setAdminArticlesPagination,
  setRemoveArticlesIds,
  setDefaultAdminArticlesFilter,
  clearRemoveArticlesIds,
} = ArticlesActions;

const ManageArticles = () => {
  // Declare translation
  const { t } = useTranslation('admin');
  // Declare dispatch, reducers
  const dispatch = useDispatch();
  const filter = useSelector(state => _.get(state, 'Articles.filter.admin'));
  const pagination = useSelector(state => _.get(state, 'Articles.pagination'));
  const meta = useSelector(state => _.get(state, 'Articles.meta'));
  const isRequestLoading = useSelector(state => _.get(state, 'Articles.isLoading'));
  const payload = useSelector(state => _.get(state, 'Articles.payload'));
  const removeIds = useSelector(state => _.get(state, 'Articles.removeIds'));
  const timezone = useSelector(state => _.get(state, 'PageSettings.ip.timezone'));

  useEffect(() => {
    dispatch(filterArticles({ ...filter, ...pagination, isDraft: 1 }));
  }, []);

  const handleSearch = async () => {
    await dispatch(filterArticles({ ...filter, ...pagination, isDraft: 1 }));
  };

  const handleChangeFilter = async (value: any, key: string) => {
    const newPayload = {
      ...filter,
      [key]: value,
    };
    if (key === 'features' && (value === 'insights' || value === 'successStories')) {
      _.assign(newPayload, { topic: '' });
    }
    if (key !== 'keyword') {
      if (key === 'dateFrom' || key === 'dateTo') {
        const getDate = new Date(value).toLocaleDateString('en-US', { timeZone: timezone });
        const objectDate = key === 'dateTo' ? `${getDate} 23:59:59` : `${getDate} 00:00:00`;
        _.assign(newPayload, { [key]: objectDate });
      }
      await dispatch(setAdminArticlesPagination({ ...pagination, currentPage: 1 }));
      await dispatch(filterArticles({ ...newPayload, ...pagination, currentPage: 1, isDraft: 1 }));
    }
    await dispatch(setAdminArticlesFilter(newPayload));
  };

  const handleViewDetails = async (value: any, key: string) => {
    if (value && _.isString(value)) {
      if (key === 'delete') {
        const isAgree = await statusAlert('warning', t('alert.warningDeleteDraftArticle'));
        if (isAgree) {
          let currentPage = _.get(pagination, 'currentPage');
          if (payload.length === 1) currentPage = currentPage - 1;
          if (currentPage !== _.get(pagination, 'currentPage') && currentPage !== 1)
            dispatch(setAdminArticlesPagination({ ...pagination, currentPage }));
          await dispatch(removeSingleArticles(value));
          await dispatch(filterArticles({ ...filter, ...pagination, currentPage, isDraft: 1 }));
          const findRemoveIds = _.includes(removeIds, value);
          if (findRemoveIds) {
            const resolveRemoveIds = _.filter(removeIds, id => id !== value);
            dispatch(setRemoveArticlesIds(resolveRemoveIds));
          }
        }
      }
      if (key === 'edit') history.push(`${ROUTES.ADMIN.ARTICLES}/details?id=${value}`);
    }
  };

  const handleSort = async (key: string) => {
    const sortBy = _.get(filter, 'sortBy');
    const resolveFilter = {
      ...filter,
      sortBy: key,
    };
    if (sortBy === key) {
      _.assign(resolveFilter, { sortBy: '', orderBy: 'ASC' });
      await dispatch(filterArticles({ ...resolveFilter, sortBy: key, orderBy: 'ASC', ...pagination, isDraft: 1 }));
    } else {
      _.assign(resolveFilter, { orderBy: 'DESC' });
      await dispatch(filterArticles({ ...resolveFilter, ...pagination, isDraft: 1 }));
    }
    await dispatch(setAdminArticlesFilter(resolveFilter));
  };

  const handleClickPagination = async (currentPage: number) => {
    await dispatch(setAdminArticlesPagination({ ...pagination, currentPage }));
    await dispatch(filterArticles({ ...filter, ...pagination, currentPage, isDraft: 1 }));
    await dispatch(setRemoveArticlesIds([]));
  };

  const handleSelectAll = () => {
    if (_.isArray(removeIds)) {
      const isMaxLength = removeIds.length === payload.length;
      if (isMaxLength) dispatch(setRemoveArticlesIds([]));
      else {
        const ids: string[] = [];
        _.map(payload, item => {
          const id = _.get(item, 'id');
          ids.push(id);
        });
        dispatch(setRemoveArticlesIds(ids));
      }
    }
  };

  const handleDelete = async () => {
    const isAgree = await statusAlert('warning', t('alert.warningDeleteAllDraftArticles'));
    if (isAgree) {
      let currentPage = _.get(pagination, 'currentPage');
      if (payload.length === removeIds.length && currentPage !== 1) currentPage = currentPage - 1;
      else currentPage = _.get(pagination, 'currentPage');
      if (currentPage !== _.get(pagination, 'currentPage'))
        dispatch(setAdminArticlesPagination({ ...pagination, currentPage }));
      await dispatch(removeMultipleArticles(removeIds));
      await dispatch(clearRemoveArticlesIds());
      await dispatch(filterArticles({ ...filter, ...pagination, currentPage, isDraft: 1 }));
    }
  };

  const handleResetFilter = async () => {
    await dispatch(
      filterArticles({ ...filter, page: '', keyword: '', ...pagination, currentPage: 1, isDraft: 1, language: 'en' })
    );
    await dispatch(setDefaultAdminArticlesFilter());
  };

  const renderFilterBar = () => {
    return (
      <FlexHorizontal>
        <FlexHorizontal className="mr-2" width="unset" alignCenter>
          <AdminLabel>{t('label.solution')}</AdminLabel>
          <AdminDropdown
            flex="1"
            width="20rem"
            data={DropdownOptions.FILTER_SOLUTION}
            selectedValue={_.get(filter, 'categories')}
            onChange={(value: any) => handleChangeFilter(value, 'categories')}
          />
        </FlexHorizontal>
        <FlexHorizontal width="unset" alignCenter className="mr-1">
          <AdminLabel width="60px">{t('label.language')}</AdminLabel>
          <AdminDropdown
            width="150px"
            data={[
              { label: 'english', value: 'en' },
              { label: 'albanian', value: 'al' },
            ]}
            selectedValue={_.get(filter, 'language')}
            onChange={(value: any) => handleChangeFilter(value, 'language')}
          />
        </FlexHorizontal>
        <FlexHorizontal className="mr-2" width="unset" alignCenter>
          <AdminLabel>{t('label.search')}</AdminLabel>
          <InputSearchField
            placeholder={t('placeholder.searchByTitle')}
            value={_.get(filter, 'keyword')}
            onChange={(e: any) => handleChangeFilter(e.target.value, 'keyword')}
            onClick={() => handleSearch()}
            onKeydown={() => handleSearch()}
          />
        </FlexHorizontal>
        <GeyserSolidButton onClick={() => handleResetFilter()}>{t('button.reset')}</GeyserSolidButton>
      </FlexHorizontal>
    );
  };

  const renderTable = () => {
    return (
      <NormalTable
        className="mt-3"
        column={ColumnsTable.ARTICLES}
        data={payload}
        onClick={(value: any, key: string) => handleViewDetails(value, key)}
        onSort={(key: string) => handleSort(key)}
        sortBy={_.get(filter, 'sortBy')}
        fragment="0.1fr 3fr 1.2fr 1.2fr 0.7fr 0.4fr 0.4fr 0.4fr"
        checked={removeIds}
        handleChecked={(value: string[]) => dispatch(setRemoveArticlesIds(value))}
        lang={_.get(filter, 'language')}
      />
    );
  };

  const renderPagination = () => {
    if (!_.isEmpty(payload))
      return (
        <FlexHorizontal minWidth="1035px" justifySpaceBetween className="mt-3 pb-3">
          <FlexHorizontal>
            <DarkElectriSolidcButton onClick={() => handleSelectAll()} className="mr-1">
              {removeIds.length === payload.length ? t('button.deselectAll') : t('button.selectAll')}
            </DarkElectriSolidcButton>
            <CarrotSolidButton
              onClick={() => !_.isEmpty(removeIds) && handleDelete()}
              className={`${_.isEmpty(removeIds) ? 'disabled' : ''}`}
            >
              {t('button.delete')} ({_.isArray(removeIds) && removeIds.length})
            </CarrotSolidButton>
          </FlexHorizontal>
          <AdminPagination
            maxPage={_.get(meta, 'totalPages')}
            currentPage={_.get(meta, 'currentPage')}
            numberPageDisplay={2}
            totalCount={_.get(meta, 'totalItems')}
            clickLinkPagination={(page: number) => handleClickPagination(page)}
            clickLinkNext={(page: number) => handleClickPagination(page)}
            clickLinkPrevious={(page: number) => handleClickPagination(page)}
            clickLinkLast={() => handleClickPagination(_.get(meta, 'totalPages'))}
            clickLinkFirst={() => handleClickPagination(1)}
          />
        </FlexHorizontal>
      );
  };

  const renderMain = () => {
    return (
      <ContentContainer>
        {renderFilterBar()}
        {renderTable()}
        {renderPagination()}
      </ContentContainer>
    );
  };

  return <DefaultAdminLayout content={renderMain()} currentPage={ROUTES.ADMIN.DRAFT_NEWS} loading={isRequestLoading} />;
};

export default ManageArticles;
