/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";
import { useTranslation } from "react-i18next";

import { DefaultAdminLayout } from "../../../components/Layout/DefaultLayout";
import {
  AdminDropdown,
  AdminLabel,
  Checkbox,
  FlexHorizontal,
  CarrotSolidButton,
  GeyserSolidButton,
  PreviewImage,
  ContentForm,
  ContentContainer,
  UpdatedLabel,
} from "../../../components/Common";
import { ROUTES, DropdownOptions } from "../../../constants";
import { SelectMedia } from "../../../components/Layout/Popups";
import { ClientsActions, PageSettingsActions } from "../../../actions";
import { statusAlert } from "../../../libs";
import { LOGOS_PAGE_KEY, TAB_BAR } from "../../../constants/Constants";
import history from "../../../history";

const {
  createClient,
  updateClient,
  setClientDetails,
  getClientById,
  clearClientDetails,
} = ClientsActions;
const { setStatusEdited } = PageSettingsActions;

const CHECKBOX_DATA = [
  {
    label: "homepage",
    value: LOGOS_PAGE_KEY.HOMEPAGE,
  },
  {
    label: "ourClients",
    value: LOGOS_PAGE_KEY.OUR_CLIENTS,
  },
  {
    label: "researchClients",
    value: LOGOS_PAGE_KEY.RESEARCH_CLIENTS,
  },
  {
    label: "developmentClients",
    value: LOGOS_PAGE_KEY.DEVELOPMENT_CLIENTS,
  },
];

const ClientDetails = () => {
  // Declare translation
  const { t } = useTranslation("admin");
  // Declare query string to get id in param
  const location = useLocation();
  const queryStringValue = queryString.parse(location.search);
  // Declare dispatch, reducers
  const dispatch = useDispatch();
  const isRequest = useSelector((state) => _.get(state, "Clients.isLoading"));
  const isUploadingMedia = useSelector((state) =>
    _.get(state, "Media.isLoading")
  );
  const timezone = useSelector((state) =>
    _.get(state, "PageSettings.ip.timezone")
  );
  const details = useSelector((state) => _.get(state, "Clients.details"));
  const isEditing = useSelector((state) =>
    _.get(state, "PageSettings.isEdited")
  );
  // Declare ref
  // Declare states
  const [isShowPopup, setIsShowPopup] = useState<boolean>(false);

  useEffect(() => {
    const id = _.get(queryStringValue, "id");
    if (id && _.includes(id, "-")) dispatch(getClientById(id));

    return () => {
      dispatch(clearClientDetails());
      dispatch(setStatusEdited(false));
    };
  }, []);

  const onChangeHandler = (value: any, key: string) => {
    if (!isEditing) dispatch(setStatusEdited(true));
    const resolveDetails = {
      ...details,
    };
    if (key === "pages") {
      const pages = _.get(resolveDetails, "pages");
      const checkExist = _.includes(pages, value);
      if (!checkExist) {
        pages.push(value);
        _.assign(resolveDetails, { pages });
      } else {
        const removePage = _.without(pages, value);
        _.assign(resolveDetails, { pages: removePage });
      }
    } else _.assign(resolveDetails, { [key]: value });
    dispatch(setClientDetails(resolveDetails));
  };

  const handleSubmit = async () => {
    const dataCheck = [
      {
        value: _.trim(_.get(details, "companyLogo")),
        label: t("alert.companyLogo"),
      },
      {
        value: _.trim(_.get(details, "pages")),
        label: t("alert.pages"),
      },
      {
        value: _.trim(_.get(details, "type"), " "),
        label: t("alert.type"),
      },
    ];

    let emptyFields = "";
    let flag = true;
    _.map(dataCheck, (item) => {
      const value = _.get(item, "value");
      if (!value) {
        flag = false;
        emptyFields = emptyFields + `\n${item.label}`;
      }
    });
    if (!flag)
      return await statusAlert(
        "warning",
        `${t("alert.foundEmptyFields")} : ${emptyFields}`
      );
    else {
      const isHasId = _.has(details, "id");
      if (isHasId) await dispatch(updateClient(details));
      else await dispatch(createClient(details));
    }
  };

  const handleHidePopup = (value: string) => {
    if (value) onChangeHandler(value, "companyLogo");
    setIsShowPopup(false);
  };

  const renderContent = () => {
    const lastUpdated = _.get(details, "updatedAt");
    return (
      <>
        {lastUpdated && (
          <UpdatedLabel className="mb-1">
            {t("label.lastUpdated")}:{" "}
            {new Date(lastUpdated).toLocaleString("en-US", {
              timeZone: timezone,
            })}
          </UpdatedLabel>
        )}
        <FlexHorizontal margin="0" alignCenter>
          <AdminLabel width="100px">{t("label.page")}*</AdminLabel>
          <FlexHorizontal justifySpaceBetween flex="1">
            {_.map(CHECKBOX_DATA, (item, index) => {
              const { value, label } = item;
              return (
                <Checkbox
                  key={`checkbox-clients-${index}`}
                  checked={_.includes(_.get(details, "pages"), value)}
                  label={t(`label.${label}`)}
                  onChange={(value: string | number) =>
                    onChangeHandler(value, "pages")
                  }
                  value={value}
                />
              );
            })}
          </FlexHorizontal>
        </FlexHorizontal>
        <FlexHorizontal alignCenter margin="15px 0 0">
          <AdminLabel width="100px">{t("label.type")}*</AdminLabel>
          <AdminDropdown
            data={_.get(
              DropdownOptions.CATEGORY_CLIENTS,
              LOGOS_PAGE_KEY.RESEARCH_CLIENTS
            )}
            flex="1"
            selectedValue={_.get(details, "type")}
            onChange={(value: any) => onChangeHandler(value, "type")}
          />
          <CarrotSolidButton
            className="ml-2"
            onClick={() => setIsShowPopup(true)}
          >
            {t("button.selectMedia")}
          </CarrotSolidButton>
        </FlexHorizontal>
        <FlexHorizontal alignCenter className="mt-1">
          <AdminLabel width="100px">{t("label.preview")}</AdminLabel>
          <PreviewImage className="client">
            {_.get(details, "companyLogo") && (
              <img src={_.get(details, "companyLogo")} alt="" />
            )}
          </PreviewImage>
        </FlexHorizontal>
      </>
    );
  };

  const renderButtonField = () => {
    return (
      <FlexHorizontal justifySpaceBetween className="mt-3">
        <GeyserSolidButton className="mr-1" onClick={() => history.goBack()}>
          {t("button.back")}
        </GeyserSolidButton>
        <FlexHorizontal justifyFlexEnd>
          <GeyserSolidButton
            className="mr-1"
            onClick={() => dispatch(clearClientDetails())}
          >
            {t("button.reset")}
          </GeyserSolidButton>
          <CarrotSolidButton onClick={() => handleSubmit()}>
            {t("button.save")}
          </CarrotSolidButton>
        </FlexHorizontal>
      </FlexHorizontal>
    );
  };

  const renderMain = () => {
    return (
      <ContentContainer>
        {isShowPopup && (
          <SelectMedia
            className={`${isShowPopup ? "show" : ""}`}
            hidePopup={(value: string) => handleHidePopup(value)}
            tabBar={TAB_BAR.CLIENTS_POPUP}
            selectedTab="image"
            onClose={() => setIsShowPopup(false)}
          />
        )}
        <ContentForm>
          {renderContent()}
          {renderButtonField()}
        </ContentForm>
      </ContentContainer>
    );
  };

  return (
    <DefaultAdminLayout
      content={renderMain()}
      currentPage={ROUTES.ADMIN.CLIENTS_DETAILS}
      loading={isRequest || isUploadingMedia}
    />
  );
};

export default ClientDetails;
