const ROUTES = {
  ADMIN: {
    PROFILE: "/profile",
    LOGIN: "/authentication",
    DASHBOARD: "/dashboard",
    ARTICLES: "/manage/news",
    RECENT_ACTIVITY: "/manage/recent-activity",
    ARTICLES_DETAILS: "/manage/news/details",
    ARTICLES_CREATE_NEW: "/manage/news/create",
    ORDER_NEWS: "/manage/order-news",
    DRAFT_NEWS: "/manage/draft-news",
    CLIENTS: "/manage/clients",
    CLIENTS_DETAILS: "/manage/clients/details",
    CLIENTS_CREATE_NEW: "/manage/clients/create",
    CAREER: "/manage/career",
    CAREER_DETAILS: "/manage/career/details",
    CAREER_CREATE_NEW: "/manage/career/create",
    SITE_SETTINGS: "/manage/site-settings",
    TEAM_MEMBERS: "/manage/team-members",
    TEAM_MEMBERS_DETAILS: "/manage/team-members/details",
    CONTENT_EDITABLE: "/manage/content-editable",
    ALUMNI_NETWORK: "/manage/alumni-network",
    ALUMNI_NETWORK_DETAILS: "/manage/alumni-network/details",
  },
  USER: {
    HOMEPAGE: "/",
    WHO_WE_ARE: "/who-we-are",
    NEWS: "/news",
    NEWS_DETAILS: "/news/:id",
    CONTACT: "/contact",
    CAREER: "/career",
    CAREER_DETAILS: "/career/:id",
    IDRA_RESEARCH: "/idra-research",
    IDRA_DIGIS: "/idra-digis",
    IDRA_ADVANCE: "/idra-advance",
    RESEARCH_SOLUTIONS: "/research-solutions",
    RESEARCH_WORK: "/research-work",
    RESEARCH_WORK_DETAILS: "/research-work/:id",
    RESEARCH_NEWS: "/research-news",
    RESEARCH_NEWS_DETAILS: "/research-news/:id",
    RESEARCH_CLIENTS: "/research-clients",
    RESEARCH_SERVICES: "/research-services",
    DEVELOPMENT_SOLUTIONS: "/development-solutions",
    DEVELOPMENT_NEWS_DETAILS: "/development-news/:id",
    DEVELOPMENT_NEWS: "/development-news",
    DEVELOPMENT_CLIENTS: "/development-client",
    DEVELOPMENT_WORK_DETAILS: "/development-work/:id",
    DEVELOPMENT_WORK: "/development-work",
    SEARCH: "/search",
    SERVICES_SOLUTIONS: "/services-solutions",
    ALUMNI_NETWORK: "/alumni-network",
    ALUMNI_NETWORK_REGISTER: "/alumni-network-register",
  },
  NOT_FOUND: "*",
};

export default ROUTES;
