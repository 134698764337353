import styled from 'styled-components';
import {
  BlackRegularParagraph,
  FlexHorizontal,
  FlexVertical,
  HorizontalContainer,
} from '../../../../components/Common';
import { ColorName } from '../../../../components/Variables';
import { ContentEditableWrapper } from '../ContentEditableDetails.styles';

const EditServicesSolutionsContainer = styled(ContentEditableWrapper)`
  & .services-content {
    border: 1px solid transparent;
    &:hover {
      border: 1px solid ${ColorName.geyser};
    }
  }

  h3 {
    min-height: 30px;
  }
`;

const Title = styled.h2``;
const Description = styled(BlackRegularParagraph)``;

const SolutionCategory = styled.h3`
  font-size: 2.3rem;
  font-weight: 700;
  line-height: 34px;
  color: ${ColorName.black};
  padding-right: 50px;
`;

const SolutionSubCategory = styled.h3`
  font-weight: 700;
  line-height: 2.7rem;
  color: ${ColorName.darkElectricBlue};
  display: flex;
  align-items: center;
  font-size: 1.8rem;
  padding-right: 50px;
  margin-top: 5px;
`;

const ShowButton = styled.div`
  position: absolute;
  width: 50px;
  height: 50px;
  background: ${ColorName.pampas};
  border-radius: 50%;
  right: 10px;
  top: calc(50% - 25px);

  display: flex;
  align-items: center;
  justify-content: center;

  img {
    transition: all ease 0.5s;
  }

  &:hover {
    cursor: pointer;
  }
  &.show img {
    transform: rotate(45deg);
  }
`;

const SolutionContent = styled(FlexVertical)`
  height: 100%;
  transition: all ease 0.5s;
  margin: 0;

  strong {
    font-weight: 700;
    text-transform: uppercase;
    font-size: 1.8rem;

    @media screen and (max-width: 426px) {
      font-size: 16px;
    }
  }
`;

const GifImage = styled.div`
  margin-right: 3rem;

  img {
    max-width: 60px;
    object-fit: contain;
    @media screen and (max-width: 426px) {
      max-width: 30px;
    }
  }
`;

const HeadingWrapper = styled(FlexVertical)`
  position: relative;
  border-bottom: 1px solid rgba(0, 0, 0, 0.07);
`;

const ShowMoreContentContainer = styled.div`
  width: 100%;
  padding-bottom: 3rem;

  @media screen and (max-width: 426px) {
    ${SolutionCategory} {
      font-size: 18px;
      line-height: 26px;
    }
    ${SolutionSubCategory} {
      font-size: 14px;
      line-height: 21px;
    }
    ${ShowButton} {
      width: 40px;
      height: 40px;
    }
  }
`;

const AddMoreContent = styled(FlexHorizontal)`
  width: 100%;
  flex: 1;
  align-items: center;
  box-sizing: border-box;
  justify-content: center;
  font-size: 14px;
  border: 1px solid ${ColorName.darkElectricBlue};
  border-radius: 5px;
  height: 35px;

  &:hover {
    cursor: pointer;
    background: ${ColorName.darkElectricBlue};
    color: ${ColorName.white};
  }
`;

const DevelopmentSolutionDetails = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const RemoveButton = styled(HorizontalContainer)`
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  position: absolute;
  left: 100%;
  top: 0;
  background: white;
  border: 1px solid ${ColorName.black};
  color: ${ColorName.black};
  font-size: 16px;
  border-radius: 1px;

  &:hover {
    cursor: pointer;
    color: ${ColorName.white};
    background: ${ColorName.burntSienna};
    border-color: ${ColorName.burntSienna};
  }
`;

export {
  EditServicesSolutionsContainer,
  Title,
  Description,
  ShowMoreContentContainer,
  SolutionCategory,
  SolutionSubCategory,
  SolutionContent,
  ShowButton,
  GifImage,
  HeadingWrapper,
  AddMoreContent,
  DevelopmentSolutionDetails,
  RemoveButton,
};
