import React from "react";
import { render } from "react-dom";
import { createStore, applyMiddleware, compose } from "redux";
import { Provider } from "react-redux";
// import { logger } from 'redux-logger';
import { routerMiddleware } from "react-router-redux";
import thunk from "redux-thunk";
// import 'react-image-crop/dist/ReactCrop.css';
import "react-lazy-load-image-component/src/effects/blur.css";
import "aos/dist/aos.css";
import reducers from "./reducers";
// import Homepage from './containers/UserPage/Homepage';
import history from "./history";
// import { ROUTES } from './constants';
import { PortalRoute, ValidateRoute } from "./router";
import * as serviceWorker from "./serviceWorker";
import ScrollToTop from "./scrollToTop";
import "./i18next";
import { Router } from "react-router";

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const composeEnhancers =
  (window["__REDUX_DEVTOOLS_EXTENSION_COMPOSE__"] as typeof compose) || compose;

const middlewares = [thunk];

if (process.env.NODE_ENV === `development`) {
  const { logger } = require(`redux-logger`);
  middlewares.push(logger);
}

const store = createStore(
  reducers,
  composeEnhancers(applyMiddleware(...middlewares, routerMiddleware(history)))
);

render(
  <Provider store={store}>
    <Router history={history}>
      <div id="alert"></div>
      <ScrollToTop />
      <ValidateRoute />
      <PortalRoute />
      {/* <Route exact path={ROUTES.USER.HOMEPAGE} component={Homepage} /> */}
    </Router>
  </Provider>,
  document.getElementById("root")
);

serviceWorker.unregister();
