import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FilterContainer,
  FilterItem,
  Title,
  FilterGroup,
  ResetButton,
  FilterButton,
  FilterChildOptions,
} from './FilterNews.styles';
import { GenerateValue } from '../../../libs';
import { FlexHorizontal } from '../FlexLayout';
import { FilterDropdown } from '../Dropdown';

interface ItemsStructure {
  value: string;
  label: string;
  icon?: string;
}

interface DataStructure {
  title: string;
  key: string;
  items: ItemsStructure[] | any[];
}

interface ActiveFilterStructure {
  [key: string]: string;
}

const { GenerateYear, GenerateYearFrom } = GenerateValue;

interface SectionProps {
  data: DataStructure[];
  onChange(value: ActiveFilterStructure): unknown;
  onClick?(): unknown;
  activeFilter: ActiveFilterStructure;
  isWorkPage?: boolean;
  className?: string;
}

const FilterNews: React.FC<SectionProps> = (props: SectionProps) => {
  // Declare props
  const { onChange, data, activeFilter, isWorkPage, onClick, className } = props;
  const [isDisabledResetFilter, setIsDisabledResetFilter] = useState<boolean>(true);
  const [isShowFilter, setIsShowFilter] = useState(false);
  const [collapse, setCollapse] = useState<any>({
    status: false,
    categories: false,
    topic: false,
    implement: false,
    year: false,
  });
  useEffect(() => {
    let flag = true;
    const activeFilterKeys = _.keys(activeFilter);
    _.map(activeFilterKeys, key => {
      if (key !== 'page') {
        const value = _.get(activeFilter, key);
        if (!_.isEmpty(value)) flag = false;
      }
    });
    setIsDisabledResetFilter(flag);
  }, [activeFilter]);

  // Declare translation
  const { t } = useTranslation('user');

  const handleChangeFilter = (key: string, value: string) => {
    if (onChange) {
      const getCurrentValue = _.get(activeFilter, key);
      const resolveFilter = { ...activeFilter };
      if (getCurrentValue === value) _.assign(resolveFilter, { [key]: '' });
      else _.assign(resolveFilter, { [key]: value });
      const categories = _.get(activeFilter, 'categories');
      if (_.includes(['general', 'DiGISNews'], categories)) _.assign(resolveFilter, { topic: '' });

      onChange(resolveFilter);
    }
  };

  return (
    <FilterContainer className={className}>
      <FilterButton
        className={`${isShowFilter ? 'show' : ''}`}
        onClick={() => {
          const newStatus = !isShowFilter;
          if (!newStatus)
            setCollapse({
              status: false,
              categories: false,
              topic: false,
              implement: false,
              year: false,
            });

          setIsShowFilter(newStatus);
        }}
      >
        {isShowFilter ? (
          <>
            {t('label.collapseFilters')} <i className="fa fa-window-minimize" />
          </>
        ) : (
          <>
            {t(`label.filter${isWorkPage ? 'Work' : 'News'}`)} <i className="fas fa-filter" />
          </>
        )}
      </FilterButton>
      <FilterChildOptions className={`${isShowFilter ? 'show' : ''}`}>
        {_.map(data, (item, index) => {
          const { title, items, key } = item;
          const render = _.map(items, (child, innerIndex) => {
            const { value, label } = child;
            const keyItem = `${key}-${innerIndex}`;
            const activeClass = _.get(activeFilter, key) === value ? 'active' : '';
            return (
              <FilterItem key={keyItem} className={activeClass} onClick={() => handleChangeFilter(key, value)}>
                {key === 'country' ? label : t(`label.${label}`)}
              </FilterItem>
            );
          });
          if (key === 'country') {
            return (
              <div key={`filter-${index}`}>
                <Title
                  className={`mt-3 ${_.get(collapse, key) ? 'uncollapse' : 'collapse'}`}
                  onClick={() => setCollapse({ ...collapse, implement: !_.get(collapse, 'implement') })}
                >
                  {t('label.implementedIn')}
                  {_.get(collapse, 'implement') ? (
                    <i className="fa fa-window-minimize" />
                  ) : (
                    <i className="fas fa-filter" />
                  )}
                </Title>
                <FilterGroup className={`${_.get(collapse, 'implement') ? 'uncollapse' : 'collapse'}`}>
                  <FilterDropdown
                    options={items}
                    onChange={(value: any) => onChange && onChange({ ...activeFilter, country: _.toString(value) })}
                    selectedValue={_.get(activeFilter, 'country')}
                    placeholder={t('label.all')}
                  />
                </FilterGroup>
              </div>
            );
          }
          return (
            <div key={`filter-${index}`}>
              <Title
                onClick={() => setCollapse({ ...collapse, [key]: !_.get(collapse, key) })}
                className={`${_.get(collapse, key) ? 'uncollapse' : 'collapse'} ${index === 0 ? '' : 'mt-3'}`}
              >
                {t(`label.${title}`)}{' '}
                {_.get(collapse, key) ? <i className="fa fa-window-minimize" /> : <i className="fas fa-filter" />}
              </Title>
              <FilterGroup className={`${_.get(collapse, key) ? 'uncollapse' : 'collapse'}`}>{render}</FilterGroup>
            </div>
          );
        })}
        {isWorkPage && (
          <div>
            <Title
              className={`mt-3 ${_.get(collapse, 'year') ? 'uncollapse' : 'collapse'}`}
              onClick={() => setCollapse({ ...collapse, year: !_.get(collapse, 'year') })}
            >
              {t(`label.filterByYear`)}
              {_.get(collapse, 'year') ? <i className="fa fa-window-minimize" /> : <i className="fas fa-filter" />}
            </Title>
            <FilterGroup className={`${_.get(collapse, 'year') ? 'uncollapse' : 'collapse'}`}>
              <FlexHorizontal width="100%">
                <FilterDropdown
                  options={GenerateYearFrom(_.get(activeFilter, 'to_year'))}
                  onChange={(value: any) => onChange && onChange({ ...activeFilter, from_year: _.toString(value) })}
                  selectedValue={_.toNumber(_.get(activeFilter, 'from_year'))}
                  placeholder={t('label.from')}
                />

                <FilterDropdown
                  options={GenerateYear(_.get(activeFilter, 'from_year'))}
                  onChange={(value: any) => onChange && onChange({ ...activeFilter, to_year: _.toString(value) })}
                  selectedValue={_.toNumber(_.get(activeFilter, 'to_year'))}
                  placeholder={t('label.to')}
                />
              </FlexHorizontal>
            </FilterGroup>
          </div>
        )}
        <ResetButton
          className={`${isDisabledResetFilter ? 'disabled' : ''}`}
          onClick={() => !isDisabledResetFilter && onClick && onClick()}
        >
          {t('label.resetFilters')}
        </ResetButton>
      </FilterChildOptions>
    </FilterContainer>
  );
};

export default FilterNews;
