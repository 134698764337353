/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { EditContentActions, PageSettingsActions } from '../../../../actions';
import { ROUTES } from '../../../../constants';
import { Richtextbox } from '../../../../components/Common';
import { ContentEditableWrapper } from '../ContentEditableDetails.styles';

interface SectionProps {
  className?: string;
}
const { getAllContentForUser, setEditContentPayload } = EditContentActions;
const { setStatusEdited, setContentEdited } = PageSettingsActions;

const EditCareersPage = (props: SectionProps) => {
  // Declare props
  const { className } = props;
  // Declare dispatch, reducers
  const dispatch = useDispatch();
  const contentPayload = useSelector(state => _.get(state, 'EditContent.payload'));
  const payload = useSelector(state => _.get(state, `EditContent.payload.${ROUTES.USER.CAREER}`));
  const lang = useSelector(state => _.get(state, 'EditContent.langEditing'));
  const isEditing = useSelector(state => _.get(state, 'PageSettings.isEdited'));
  const contentEdited = useSelector(state => _.get(state, 'PageSettings.contentEdited'));

  useEffect(() => {
    dispatch(getAllContentForUser());

    return () => {
      dispatch(setStatusEdited(false));
    };
  }, []);

  const handleChange = (value: string) => {
    const isEdited = !_.isEqual(value, contentEdited);
    if (!isEditing && isEdited) dispatch(setStatusEdited(true));
    dispatch(
      setEditContentPayload({
        ...contentPayload,
        [ROUTES.USER.CAREER]: {
          ...payload,
          content: {
            ...payload.content,
            [lang]: value,
          },
        },
      })
    );
  };

  return (
    <ContentEditableWrapper className={className}>
      <Richtextbox
        data={_.get(payload, `content.${lang}`) || ''}
        onFocus={(value: any) => dispatch(setContentEdited(_.trim(value)))}
        onChange={(value: any) => handleChange(value)}
        fontSize="15px"
        toolbar={['bold', 'link', '|', 'numberedList', 'bulletedList', '|', 'undo', 'redo']}
      />
    </ContentEditableWrapper>
  );
};

export default EditCareersPage;
