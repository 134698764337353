import styled from 'styled-components';
import { ColorName } from '../../../Variables';

interface SectionProps {
  flex?: string;
  width?: number;
  col?: number;
  fragment?: string;
  minWidth?: string;
}

const TableContainer = styled.div`
  width: 100%;
  min-width: ${(props: SectionProps) => props.minWidth || '1035px'};

  & .content.no-result {
    border-bottom: 1px solid transparent;
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    color: ${ColorName.grannySmith};
    min-height: 38px;
    display: flex;
    align-items: center;
  }
`;

const TableContent = styled.div`
  display: grid;
  grid-template-columns: ${(props: SectionProps) => props.fragment || 'repeat(5, 1fr)'};
  & .header {
    font-size: 15px;
    line-height: 24px;
    font-weight: 600;
    color: ${ColorName.darkElectricBlue};
    text-transform: capitalize;
    display: flex;
    align-items: center;
    border-bottom: 1px solid ${ColorName.darkElectricBlue};
    padding: 5px 10px;
    &.center {
      text-align: center;
    }
    i:hover {
      cursor: pointer;
    }
  }
  & .content {
    min-height: 45px;
    display: flex;
    align-items: center;
    padding: 10px;
    box-sizing: border-box;

    &.background {
      background: ${ColorName.aquaHaze};
    }

    &.show_homepage {
      i {
        font-size: 16px;
        &:hover {
          // cursor: not-allowed;
          color: ${ColorName.grannySmith};
        }
      }
    }

    span {
      font-size: 14px;
      line-height: 24px;
      font-weight: 400;
      color: ${ColorName.grannySmith};
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;

      &.active {
        font-weight: 600;
        color: ${ColorName.shamrock};
      }

      &.inactive {
        font-weight: 600;
        color: ${ColorName.geraldine};
      }

      &.request-type {
        width: 90px;
        text-align: center;
        padding: 3px 10px;
        border-radius: 4px;
        margin-left: 5px;
        text-transform: uppercase;
        font-weight: 500;

        &.update {
          border: 1px solid #96c2ed;
          color: #96c2ed;
          background: rgba(150, 194, 237, 0.2);
        }
        &.create {
          border: 1px solid #fdbb1e;
          color: #fdbb1e;
          background: rgba(253, 187, 30, 0.2);
        }
        &.delete {
          border: 1px solid #ec5f56;
          color: #ec5f56;
          background: rgba(236, 95, 86, 0.2);
        }
      }
    }

    img {
      max-height: 35px;
      object-fit: contain;
    }

    &.center {
      text-align: center;
    }
    i {
      &:hover {
        cursor: pointer;
        color: ${ColorName.black};
      }
    }

    &.view {
      & .button {
        width: 100px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        border-radius: 5px;
        background: ${ColorName.burntSienna};
        color: ${ColorName.white};
        padding: 0 5px;
      }
      &:hover {
        cursor: pointer;
      }
    }
    &.add {
      line-height: 22px;
      font-weight: 600;
      color: ${ColorName.darkElectricBlue};

      &:hover {
        cursor: pointer;
      }
      i {
        margin-right: 5px;
      }
    }
  }
`;

const OrderDropdown = styled.div`
  font-size: 14px;
  position: relative;
  width: 100%;

  & .label {
    width: 60%;
    display: flex !important;
    align-items: center;
    margin: 0 auto;
  }

  & .options {
    position: absolute;
    width: 100%;
    top: 100%;
    left: 0;
    z-index: -1;
    border-radius: 5px;
    overflow: hidden;
    opacity: 0;

    & .option {
      height: 30px;
      width: 100%;
      background: ${ColorName.geyser};
      display: flex;
      align-items: center;
      justify-content: center;
      color: ${ColorName.grannySmith};

      &.selected,
      &:hover {
        color: ${ColorName.white};
        background: ${ColorName.darkElectricBlue};
        cursor: pointer;
      }
    }
  }

  &:hover {
    cursor: pointer;
    & .options {
      opacity: 1;
      z-index: 2;
    }
  }
`;

export { TableContainer, TableContent, OrderDropdown };
