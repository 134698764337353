import styled from 'styled-components';
import { HorizontalContainer, VerticalContainer } from '../../../../components/Common';
import { ColorName } from '../../../../components/Variables';
import { ContentEditableWrapper } from '../ContentEditableDetails.styles';

interface SectionProps {
  width?: string;
}

const EditWhoWeArePageContainer = styled(ContentEditableWrapper)``;

const OurValueBox = styled(VerticalContainer)`
  width: 100%;
  height: auto;
  align-items: flex-start;

  & .image-value {
    width: auto;
    img {
      height: 40px;
      object-fit: contain;
      margin-right: 15px;
    }
  }

  & .description {
    display: flex;
    align-items: center;
  }
  & .title {
    font-weight: 700;
    font-size: 24px;
    line-height: 44px;
  }
  & .subtitle {
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: ${ColorName.grannySmith};
    margin-top: 5px;
  }
`;

const PartOfContent = styled(HorizontalContainer)`
  flex-direction: column;
  height: 100%;
  padding-right: 100px;

  h2 {
    font-size: 22px;
    font-weight: 700;
    line-height: 32px;
    color: ${ColorName.black};
    margin: 0;
  }

  & .logo {
    margin-bottom: 25px;
    img {
      object-fit: contain;
      max-height: 51px;
    }
  }
`;

const TimelineLabel = styled.h2`
  font-size: 40px;
  font-weight: 700;
  line-height: 58px;
  color: ${ColorName.gullGray};
  width: ${(props: SectionProps) => props.width || '110px'};
  margin-right: 20px;

  @media screen and (max-width: 426px) {
    font-size: 24px;
    line-height: 31px;
  }
`;

export { EditWhoWeArePageContainer, OurValueBox, PartOfContent, TimelineLabel };
