import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { InputField } from "./InputCalendar.styles";
import "react-datepicker/dist/react-datepicker.css";
interface SectionProps {
  value: string;
  onChange(value: string): unknown;
  className?: string;
  disabled?: boolean;
  isMaxDate?: string;
  isMinDate?: string;
}

const InputCalendar: React.FC<SectionProps> = (props: SectionProps) => {
  const { value, onChange, className, disabled, isMaxDate, isMinDate } = props;

  const [isShowCalendar, setIsShowCalendar] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<string>("");

  useEffect(() => {
    setSelectedDate(value);
  }, [value]);

  const onChangeHandler = (e: any) => {
    const newSelectedDate = new Date(e).toLocaleString("en-US");
    if (newSelectedDate) {
      if (onChange) onChange(newSelectedDate);
      setIsShowCalendar(false);
    }
  };

  const onFocus = () => {
    if (!disabled) {
      const inputCalendar = document.getElementById(
        "date-jumper"
      ) as HTMLInputElement;
      if (inputCalendar) {
        inputCalendar.focus();
        setIsShowCalendar(!isShowCalendar);
      }
    }
  };

  return (
    <InputField
      className={`${className} ${disabled ? "disabled" : ""} ${
        isShowCalendar ? "focus" : ""
      }`}
      onClick={() => onFocus()}
    >
      <div className="content flex-row flex-justify-between">
        <DatePicker
          // open={isShowCalendar}
          selected={selectedDate ? new Date(value) : null}
          onChange={(date: any) => onChangeHandler(date)}
          onBlur={() => setIsShowCalendar(false)}
          minDate={isMinDate ? new Date(isMinDate) : null}
          maxDate={isMaxDate ? new Date(isMaxDate) : null}
          dateFormat="dd/MM/yyyy"
          showDisabledMonthNavigation
          placeholderText="dd/mm/yyyy"
          id="date-jumper"
          disabled={disabled}
          className={className}
        />
        <i className="fas fa-calendar-alt" />
      </div>
    </InputField>
  );
};

export default InputCalendar;
