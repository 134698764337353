import { ROUTES } from "../../../constants";

const PAGES_DATA = [
  {
    label: "homepage",
    value: ROUTES.USER.HOMEPAGE,
  },
  {
    label: "careers",
    value: ROUTES.USER.CAREER,
  },
  {
    label: "contact",
    value: ROUTES.USER.CONTACT,
  },
  {
    label: "whoWeAre",
    value: ROUTES.USER.WHO_WE_ARE,
  },
  {
    label: "IDRAResearch",
    value: ROUTES.USER.IDRA_RESEARCH,
  },
  {
    label: "IDRADiGIS",
    value: ROUTES.USER.IDRA_DIGIS,
  },
  {
    label: "IDRAAdvance",
    value: ROUTES.USER.IDRA_ADVANCE,
  },
  {
    label: "researchServices",
    value: ROUTES.USER.RESEARCH_SERVICES,
  },
  {
    label: "researchSolutions",
    value: ROUTES.USER.RESEARCH_SOLUTIONS,
  },
  {
    label: "developmentSolutions",
    value: ROUTES.USER.DEVELOPMENT_SOLUTIONS,
  },
  {
    label: "alumniNetwork",
    value: ROUTES.USER.ALUMNI_NETWORK,
  },
];

export { PAGES_DATA };
