import { ROUTES } from "../../../../constants";

const NAVIGATION_LINK = [
  {
    label: "whoWeAre",
    location: ROUTES.USER.WHO_WE_ARE,
    submenu: [
      {
        label: "aboutUs",
        location: `${ROUTES.USER.WHO_WE_ARE}?category=aboutUs`,
      },
      {
        label: "ourValues",
        location: `${ROUTES.USER.WHO_WE_ARE}?category=ourValues`,
      },
      {
        label: "qualityAssurance",
        location: `${ROUTES.USER.WHO_WE_ARE}?category=qualityAssurance`,
      },
      {
        label: "ourTeam",
        location: `${ROUTES.USER.WHO_WE_ARE}?category=ourTeam`,
      },
      {
        label: "ourPartners",
        location: `${ROUTES.USER.WHO_WE_ARE}?category=ourPartners`,
      },
      {
        label: "ourClients",
        location: `${ROUTES.USER.WHO_WE_ARE}?category=ourClients`,
      },
    ],
  },
  {
    label: "solutions",
    location: ROUTES.USER.SERVICES_SOLUTIONS,
    submenu: [
      {
        label: "IDRAResearch",
        location: ROUTES.USER.IDRA_RESEARCH,
      },
      {
        label: "IDRAAdvance",
        location: ROUTES.USER.IDRA_ADVANCE,
      },
      {
        label: "IDRADiGIS",
        location: ROUTES.USER.IDRA_DIGIS,
      },
    ],
  },
  {
    label: "news",
    location: ROUTES.USER.NEWS,
  },
  {
    label: "careers",
    location: ROUTES.USER.CAREER,
  },
  {
    label: "alumni",
    location: ROUTES.USER.ALUMNI_NETWORK,
  },
  {
    label: "contact",
    location: ROUTES.USER.CONTACT,
  },
];

const MENU_MOBILE = [
  {
    label: "whoWeAre",
    location: `${ROUTES.USER.WHO_WE_ARE}?category=aboutUs`,
    child: [
      {
        label: "aboutUs",
        location: `${ROUTES.USER.WHO_WE_ARE}?category=aboutUs`,
      },
      {
        label: "ourValues",
        location: `${ROUTES.USER.WHO_WE_ARE}?category=ourValues`,
      },
      {
        label: "qualityAssurance",
        location: `${ROUTES.USER.WHO_WE_ARE}?category=qualityAssurance`,
      },
      {
        label: "ourTeam",
        location: `${ROUTES.USER.WHO_WE_ARE}?category=ourTeam`,
      },
      {
        label: "ourPartners",
        location: `${ROUTES.USER.WHO_WE_ARE}?category=ourPartners`,
      },
      {
        label: "ourClients",
        location: `${ROUTES.USER.WHO_WE_ARE}?category=ourClients`,
      },
    ],
  },
  {
    label: "solutions",
    child: [
      {
        label: "IDRAResearch",
        location: ROUTES.USER.IDRA_RESEARCH,
      },
      {
        label: "IDRAAdvance",
        location: ROUTES.USER.IDRA_ADVANCE,
      },
      {
        label: "IDRADiGIS",
        location: ROUTES.USER.IDRA_DIGIS,
      },
    ],
  },
  {
    label: "news",
    location: ROUTES.USER.NEWS,
  },
  {
    label: "careers",
    location: ROUTES.USER.CAREER,
  },
  {
    label: "alumni",
    location: ROUTES.USER.ALUMNI_NETWORK,
  },
  {
    label: "contact",
    location: ROUTES.USER.CONTACT,
  },
  {
    label: "language",
    child: [
      {
        label: "english",
        value: "en",
      },
      {
        label: "albanian",
        value: "al",
      },
    ],
  },
];

export { NAVIGATION_LINK, MENU_MOBILE };
