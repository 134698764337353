/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import i18next from 'i18next';
import { HorizontalContainer, Loading } from '../../../components/Common';
import { DefaultLayoutContainer, ContainerContent } from './DefaultLayout.styles';
import SideMenu from '../CommonLayoutPart/SideMenu';
import SideBar from '../CommonLayoutPart/SideBar';
import Utils from '../../../libs/Utils';
import createMarkup from './DOMPurify';

interface SectionProps {
  content: JSX.Element;
  currentPage: string;
  loading?: boolean;
}

const DefaultAdminLayout: React.FC<SectionProps> = (props: SectionProps) => {
  // Declare all props
  const { content, loading, currentPage } = props;
  // Declare states

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    Utils.setTypeUser('admin');
    const lang = Utils.getAdminLocale();
    i18next.changeLanguage(lang);

    document.onpaste = (e: any) => {
      var cb = e.clipboardData;
      if (cb.types.indexOf('text/html') !== -1) {
        e.preventDefault();
        const dom = createMarkup(cb.getData('text/html'));
        document.execCommand('insertHTML', false, dom.__html);
      } else if (cb.types.indexOf('text/plain') !== -1) {
        e.clipboardData.setData('text/plain', cb.types.indexOf('text/plain'));
        document.execCommand('paste');
      }
    };

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  return (
    <DefaultLayoutContainer>
      {loading && <Loading />}
      <HorizontalContainer>
        <SideMenu currentPage={currentPage} />
        <ContainerContent className="m-0">
          <SideBar currentPage={currentPage} />
          {content}
        </ContainerContent>
      </HorizontalContainer>
    </DefaultLayoutContainer>
  );
};

export default DefaultAdminLayout;
