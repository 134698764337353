/* eslint import/no-anonymous-default-export: off */
import ActionTypes from '../constants/ActionTypes';
import API from '../api';
import history from '../history';
import { ROUTES } from '../constants';
import { statusAlert } from '../libs';
import get from 'lodash/get';
import filter from 'lodash/filter';

// SINGLE ACTIONS
const setPayloadOrderedArticles = (payload: any) => {
  return {
    type: ActionTypes.SET_PAYLOAD_ORDERED_ARTICLES,
    payload,
  };
};

const clearPayloadOrderedArticles = () => {
  return {
    type: ActionTypes.CLEAR_PAYLOAD_ORDERED_ARTICLES,
  };
};

const setPayloadUnorderedArticles = (payload: any) => {
  return {
    type: ActionTypes.SET_PAYLOAD_UNORDERED_ARTICLES,
    payload,
  };
};

const clearPayloadUnorderedArticles = () => {
  return {
    type: ActionTypes.CLEAR_PAYLOAD_UNORDERED_ARTICLES,
  };
};

const setPayloadArticles = (payload: any) => {
  return {
    type: ActionTypes.SET_PAYLOAD_ARTICLES,
    payload,
  };
};

const clearPayloadArticles = () => {
  return {
    type: ActionTypes.CLEAR_PAYLOAD_ARTICLES,
  };
};

const setArticlesDetails = (payload: any) => {
  return {
    type: ActionTypes.SET_ARTICLES_DETAILS,
    payload,
  };
};

const clearArticlesDetails = () => {
  return {
    type: ActionTypes.CLEAR_ARTICLES_DETAILS,
  };
};

const setAdminArticlesFilter = (payload: any) => {
  return {
    type: ActionTypes.SET_ADMIN_ARTICLES_FILTER,
    payload,
  };
};

const setDefaultAdminArticlesFilter = () => {
  return {
    type: ActionTypes.SET_DEFAULT_ADMIN_ARTICLES_FILTER,
  };
};

const setUserArticlesFilter = (payload: any) => {
  return {
    type: ActionTypes.SET_USER_ARTICLES_FILTER,
    payload,
  };
};

const setDefaultUserArticlesFilter = () => {
  return {
    type: ActionTypes.SET_DEFAULT_USER_ARTICLES_FILTER,
  };
};

const setUserArticlesMetaPagination = (payload: any) => {
  return {
    type: ActionTypes.SET_USER_ARTICLES_META_PAGINATION,
    payload,
  };
};

const setDefaultUserArticlesMetaPagination = () => {
  return {
    type: ActionTypes.SET_DEFAULT_USER_ARTICLES_META_PAGINATION,
  };
};

const setAdminArticlesMeta = (payload: any) => {
  return {
    type: ActionTypes.SET_ADMIN_ARTICLES_META,
    payload,
  };
};

const setAdminArticlesPagination = (payload: any) => {
  return {
    type: ActionTypes.SET_ADMIN_ARTICLES_PAGINATION,
    payload,
  };
};

const setDefaultAdminArticlesMetaPagination = () => {
  return {
    type: ActionTypes.SET_DEFAULT_ADMIN_ARTICLES_META_PAGINATION,
  };
};

const setRemoveArticlesIds = (payload: string[]) => {
  return {
    type: ActionTypes.SET_REMOVE_ARTICLES_IDS,
    payload,
  };
};

const clearRemoveArticlesIds = () => {
  return {
    type: ActionTypes.CLEAR_REMOVE_ARTICLES_IDS,
  };
};

// ASYNC ACTIONS
const getArticlesByIdError = () => {
  return {
    type: ActionTypes.GET_ARTICLES_BY_ID_FAILURE,
  };
};

const getArticlesByIdLoading = () => {
  return {
    type: ActionTypes.GET_ARTICLES_BY_ID_REQUEST,
  };
};

const getArticlesByIdSuccess = () => {
  return {
    type: ActionTypes.GET_ARTICLES_BY_ID_SUCCESS,
  };
};

const getArticlesById = (id: string, type?: string) => {
  return async (dispatch: any) => {
    dispatch(getArticlesByIdLoading());
    await API.getArticlesById(id, type)
      .then(async (res: any) => {
        const { payload, status } = res;
        if (status) {
          dispatch(setArticlesDetails(payload));
          dispatch(getArticlesByIdSuccess());
        } else dispatch(getArticlesByIdError());
      })
      .catch(() => dispatch(getArticlesByIdError()));
  };
};

// const getAllArticlesError = () => {
//   return {
//     type: ActionTypes.GET_ALL_ARTICLES_FAILURE,
//   };
// };

// const getAllArticlesLoading = () => {
//   return {
//     type: ActionTypes.GET_ALL_ARTICLES_REQUEST,
//   };
// };

// const getAllArticlesSuccess = () => {
//   return {
//     type: ActionTypes.GET_ALL_ARTICLES_SUCCESS,
//   };
// };

// const getAllArticles = (params?: any) => {
//   return async (dispatch: any) => {
//     dispatch(getAllArticlesLoading());
//     await API.getAllArticles(params)
//       .then(async (res: any) => {
//         const { payload, status } = res;
//         if (status) {
//           const { items, meta } = payload;
//           dispatch(setPayloadArticles(items));
//           dispatch(setAdminArticlesMeta(meta));
//           dispatch(getAllArticlesSuccess());
//         } else dispatch(getAllArticlesError());
//       })
//       .catch(() => dispatch(getAllArticlesError()));
//   };
// };

const filterArticlesError = () => {
  return {
    type: ActionTypes.FILTER_ARTICLES_FAILURE,
  };
};

const filterArticlesLoading = () => {
  return {
    type: ActionTypes.FILTER_ARTICLES_REQUEST,
  };
};

const filterArticlesSuccess = () => {
  return {
    type: ActionTypes.FILTER_ARTICLES_SUCCESS,
  };
};

const filterArticles = (params: any) => {
  return async (dispatch: any) => {
    dispatch(filterArticlesLoading());
    await API.filterArticles(params)
      .then(async (res: any) => {
        const { payload, status } = res;
        if (status) {
          const { items, meta } = payload;
          dispatch(setAdminArticlesMeta(meta));
          dispatch(setPayloadArticles(items));
          dispatch(filterArticlesSuccess());
        } else dispatch(filterArticlesError());
      })
      .catch(() => dispatch(filterArticlesError()));
  };
};

const createArticlesError = () => {
  return {
    type: ActionTypes.CREATE_ARTICLES_FAILURE,
  };
};

const createArticlesLoading = () => {
  return {
    type: ActionTypes.CREATE_ARTICLES_REQUEST,
  };
};

const createArticlesSuccess = () => {
  return {
    type: ActionTypes.CREATE_ARTICLES_SUCCESS,
  };
};

const createArticles = (data: any) => {
  return async (dispatch: any) => {
    dispatch(createArticlesLoading());
    await API.createArticles(data)
      .then(async (res: any) => {
        const { status, message } = res;
        if (status) {
          await history.push(ROUTES.ADMIN.ARTICLES);
          dispatch(createArticlesSuccess());
          statusAlert('success', message);
        } else {
          dispatch(createArticlesError());
          statusAlert('error', message);
        }
      })
      .catch(() => dispatch(createArticlesError()));
  };
};

const updateArticlesError = () => {
  return {
    type: ActionTypes.UPDATE_ARTICLES_FAILURE,
  };
};

const updateArticlesLoading = () => {
  return {
    type: ActionTypes.UPDATE_ARTICLES_REQUEST,
  };
};

const updateArticlesSuccess = (payload: any) => {
  return {
    type: ActionTypes.UPDATE_ARTICLES_SUCCESS,
    payload
  };
};

const updateArticles = (data: any) => {
  return async (dispatch: any) => {
    dispatch(updateArticlesLoading());
    await API.updateArticles(data)
      .then(async (res: any) => {
        const { message, status, payload } = res;
        if (status) {
          await history.push(ROUTES.ADMIN.ARTICLES);
          dispatch(updateArticlesSuccess(payload));
          statusAlert('success', message);
        } else {
          dispatch(updateArticlesError());
          statusAlert('error', message);
        }
      })
      .catch(() => dispatch(updateArticlesError()));
  };
};

const removeSingleArticlesError = () => {
  return {
    type: ActionTypes.REMOVE_SINGLE_ARTICLES_FAILURE,
  };
};

const removeSingleArticlesLoading = () => {
  return {
    type: ActionTypes.REMOVE_SINGLE_ARTICLES_REQUEST,
  };
};

const removeSingleArticlesSuccess = () => {
  return {
    type: ActionTypes.REMOVE_SINGLE_ARTICLES_SUCCESS,
  };
};

const removeSingleArticles = (id: string) => {
  return async (dispatch: any) => {
    dispatch(removeSingleArticlesLoading());
    await API.removeSingleArticles(id)
      .then(async (res: any) => {
        const { status, message } = res;
        if (status) {
          dispatch(removeSingleArticlesSuccess());
          statusAlert('success', message);
        } else {
          dispatch(removeSingleArticlesError());
          statusAlert('error', message);
        }
      })
      .catch(() => dispatch(removeSingleArticlesError()));
  };
};

const removeMultipleArticlesFail = () => {
  return {
    type: ActionTypes.REMOVE_MULTIPLE_ARTICLES_FAILURE,
  };
};

const removeMultipleArticlesRequest = () => {
  return {
    type: ActionTypes.REMOVE_MULTIPLE_ARTICLES_REQUEST,
  };
};

const removeMultipleArticlesSuccess = () => {
  return {
    type: ActionTypes.REMOVE_MULTIPLE_ARTICLES_SUCCESS,
  };
};

const removeMultipleArticles = (data: string[]) => {
  return async (dispatch: any) => {
    dispatch(removeMultipleArticlesRequest());
    await API.removeMultipleArticles(data)
      .then(async (response: any) => {
        const { message, status } = response;
        if (status) {
          dispatch(removeMultipleArticlesSuccess());
          statusAlert('success', message);
        } else {
          dispatch(removeMultipleArticlesFail());
          await statusAlert('error', message);
        }
      })
      .catch(async () => dispatch(removeMultipleArticlesFail()));
  };
};

const getArticlesOrderedFail = () => {
  return {
    type: ActionTypes.GET_ORDERED_ARTICLES_FAILURE,
  };
};

const getArticlesOrderedRequest = () => {
  return {
    type: ActionTypes.GET_ORDERED_ARTICLES_REQUEST,
  };
};

const getArticlesOrderedSuccess = () => {
  return {
    type: ActionTypes.GET_ORDERED_ARTICLES_SUCCESS,
  };
};

const getArticlesOrdered = (params?: any) => {
  return async (dispatch: any) => {
    dispatch(getArticlesOrderedRequest());
    await API.getArticlesOrdered(params)
      .then(async (response: any) => {
        const { status, payload } = response;
        if (status) {
          dispatch(setPayloadOrderedArticles(payload));
          dispatch(getArticlesOrderedSuccess());
        } else dispatch(getArticlesOrderedFail());
      })
      .catch(async () => dispatch(getArticlesOrderedFail()));
  };
};

const getArticlesUnorderedFail = () => {
  return {
    type: ActionTypes.GET_UNORDERED_ARTICLES_FAILURE,
  };
};

const getArticlesUnorderedRequest = () => {
  return {
    type: ActionTypes.GET_UNORDERED_ARTICLES_REQUEST,
  };
};

const getArticlesUnorderedSuccess = () => {
  return {
    type: ActionTypes.GET_UNORDERED_ARTICLES_SUCCESS,
  };
};

const getArticlesUnordered = (params?: any) => {
  return async (dispatch: any) => {
    dispatch(getArticlesUnorderedRequest());
    await API.getArticlesUnordered(params)
      .then(async (response: any) => {
        const { status, payload } = response;
        if (status) {
          dispatch(setPayloadUnorderedArticles(payload));
          dispatch(getArticlesUnorderedSuccess());
        } else dispatch(getArticlesUnorderedFail());
      })
      .catch(async () => dispatch(getArticlesUnorderedFail()));
  };
};

const updateOrderArticlesError = () => {
  return {
    type: ActionTypes.UPDATE_ORDER_ARTICLES_FAILURE,
  };
};

const updateOrderArticlesLoading = () => {
  return {
    type: ActionTypes.UPDATE_ORDER_ARTICLES_REQUEST,
  };
};

const updateOrderArticlesSuccess = () => {
  return {
    type: ActionTypes.UPDATE_ORDER_ARTICLES_SUCCESS,
  };
};

const updateOrderArticles = (data: any) => {
  return async (dispatch: any) => {
    dispatch(updateOrderArticlesLoading());
    await API.updateArticles(data)
      .then(async (res: any) => {
        const { message, status } = res;
        if (status) {
          await dispatch(updateOrderArticlesSuccess());
          await dispatch(getArticlesOrdered());
          statusAlert('success', message);
        } else {
          dispatch(updateOrderArticlesError());
          statusAlert('error', message);
        }
      })
      .catch(() => dispatch(updateOrderArticlesError()));
  };
};

const getRecentArticlesError = () => {
  return {
    type: ActionTypes.GET_RECENT_ARTICLES_FAILURE,
  };
};

const getRecentArticlesLoading = () => {
  return {
    type: ActionTypes.GET_RECENT_ARTICLES_REQUEST,
  };
};

const getRecentArticlesSuccess = (payload: any) => {
  return {
    type: ActionTypes.GET_RECENT_ARTICLES_SUCCESS,
    payload,
  };
};

const getRecentArticles = (data: any, id: string) => {
  return async (dispatch: any) => {
    dispatch(getRecentArticlesLoading());
    await API.filterArticles(data)
      .then(async (res: any) => {
        const { payload, status } = res;
        if (status) {
          const items = get(payload, 'items');
          const resolveRecentNews = filter(items, item => {
            if (get(item, 'id') !== id && get(item, 'slug') !== id) return item;
          });
          await dispatch(getRecentArticlesSuccess(resolveRecentNews));
        } else await dispatch(getRecentArticlesError());
      })
      .catch(() => dispatch(getRecentArticlesError()));
  };
};

const singleActions = {
  setPayloadOrderedArticles,
  clearPayloadOrderedArticles,
  setPayloadUnorderedArticles,
  clearPayloadUnorderedArticles,
  setPayloadArticles,
  clearPayloadArticles,
  setArticlesDetails,
  clearArticlesDetails,
  setAdminArticlesFilter,
  setDefaultAdminArticlesFilter,
  setUserArticlesFilter,
  setDefaultUserArticlesFilter,
  setUserArticlesMetaPagination,
  setDefaultUserArticlesMetaPagination,
  setAdminArticlesMeta,
  setAdminArticlesPagination,
  setDefaultAdminArticlesMetaPagination,
  setRemoveArticlesIds,
  clearRemoveArticlesIds,
};

const asyncActions = {
  getArticlesById,
  filterArticles,
  createArticles,
  updateArticles,
  removeSingleArticles,
  removeMultipleArticles,
  getArticlesOrdered,
  getArticlesUnordered,
  updateOrderArticles,
  getRecentArticles,
};

export default {
  ...singleActions,
  ...asyncActions,
};
