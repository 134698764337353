import Service from './Base';
import API from '../constants/API';

interface FilterContentStructure {
  page: string;
  type: string;
}

// interface LanguageContent {
//   en: string;
//   al: string;
// }

// interface ContentDevelopmentSolutions {
//   id?: string;
//   page: string;
//   type: string;
//   content: {
//     al: string;
//     en: string;
//   };
// }

// interface ContentResearchServices {
//   id?: string;
//   page: string;
//   type: string;
//   content: LanguageContent[];
// }

interface ContentIDRAPages {
  id: string;
  page: {
    al: string;
    en: string;
  };

  pageDescription: {
    al: string;
    en: string;
  };

  icon: string;

  sections: {
    title: {
      en: string;
      al: string;
    };
    description: {
      en: string;
      al: string;
    };
    icon: string;
    link: string;
    order: number;
  }[];
}

const { CONTENT_EDITABLE } = API;

export function filterContent(params: FilterContentStructure) {
  return Service.get(CONTENT_EDITABLE.FILTER, params);
}

export function getContentForUser() {
  return Service.get(CONTENT_EDITABLE.GET_FOR_USER);
}

export function updateIDRAPages(data: ContentIDRAPages[]) {
  return Service.put(CONTENT_EDITABLE.IDRA_PAGES, data);
}

export function updateContentWhoWeAre(data: any) {
  return Service.put(CONTENT_EDITABLE.WHO_WE_ARE, data);
}

export function updateContentContact(data: any) {
  return Service.put(CONTENT_EDITABLE.CONTACT, data);
}

export function updateContentCareer(data: any) {
  return Service.put(CONTENT_EDITABLE.CAREER, data);
}

export function updateContentServicesSolutions(data: any) {
  return Service.put(CONTENT_EDITABLE.SERVICES_SOLUTIONS, data);
}

export function updateContentHomepage(data: any) {
  return Service.put(CONTENT_EDITABLE.HOMEPAGE, data);
}
