import React from 'react';
import map from 'lodash/map';
import { ContentCol, LoadingLayer } from './News.styles';

interface SectionProps {
  marginLeft?: number;
}

const SkeletonLoading = (props: SectionProps) => {
  const { marginLeft } = props;

  const layerLoading = [0, 1, 2, 3, 4];

  const render = map(layerLoading, item => {
    return (
      <LoadingLayer key={`layer-loading-${item}`}>
        <div className="image" />
        <div className="flex-column">
          <div className="title" />
          <div className="description" />
          <div className="button" />
        </div>
      </LoadingLayer>
    );
  });

  return <ContentCol marginLeft={marginLeft}>{render}</ContentCol>;
};

export default SkeletonLoading;
