/* eslint import/no-anonymous-default-export: off */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { create } from 'apisauce';
import Utils from '../libs/Utils';
import history from '../history';
import { ROUTES } from '../constants';
import { statusAlert } from '../libs';

let token = Utils.getSavedToken();
const device = Utils.getDeviceType();
// const type = Utils.getTypeUser();
const BASE_URL = process.env.REACT_APP_BE_URL;
// DEFINE THE URL API
const api = create({
  baseURL: `${BASE_URL}api/`,
  headers: {
    Accept: 'application/json',
    device,
    type: Utils.getTypeUser(),
    // Authorizations: `${_.get(token, 'type')} ${_.get(token, 'refresh_token')}`,
    windowLocation: window.location,
    lang: Utils.getCurrentLocale(),
    'Cache-Control': 'must-revalidate, public, max-age=31536000',
  },
});

// HANDLE RESPONSR FUNCTION
const handleResponse = (res: any, resolve: any, reject: any) => {
  const userType = Utils.getTypeUser();
  if (res.status === 401) {
    Utils.clearAllSavedData();
    if (userType === 'admin')
      statusAlert(
        'error',
        'Your login session has expired or your token was invalid, please login again, system will redirect to login screen ...'
      ).then(() => history.push(ROUTES.ADMIN.LOGIN));
    reject(res.data);
  } else if (res.status === 501 || res.status === 422 || res.status === 400) {
    reject(res);
  } else if (res.problem) {
    const error = 'Server error';
    if (userType === 'admin') statusAlert('error', `Error detail: ${error}`);
    reject(error);
  } else {
    resolve(res.data);
  }
};

// REQUEST FUNCTIONS
const authRequest = async (url: string, data = {}) => {
  // token = Utils.getSavedToken();
  // data = { ...data, token };
  data = { ...data };
  const res = await api.post(url, data);
  return new Promise((resolve, reject) => {
    if (res.status === 401) {
      reject(res.data);
    } else if (res.status === 501 || res.status === 422) {
      reject(res);
    } else if (res.problem) {
      reject('Server error');
    } else {
      resolve(res);
    }
  });
};

const post = async (url: string, data = {}) => {
  token = Utils.getSavedToken();
  data = { ...data };
  const res = await api.post(url, data);
  return new Promise((resolve, reject) => {
    handleResponse(res, resolve, reject);
  });
};

const postMany = async (url: string, data = []) => {
  // token = Utils.getSavedToken();
  const res = await api.post(url, data);
  return new Promise((resolve, reject) => {
    handleResponse(res, resolve, reject);
  });
};

const putMany = async (url: string, data = []) => {
  // token = Utils.getSavedToken();
  const res = await api.put(url, data);
  return new Promise((resolve, reject) => {
    handleResponse(res, resolve, reject);
  });
};

const postFormData = async (url: string, data: any) => {
  const headers = {
    'Content-Type': 'multipart/form-data',
  };
  const res = await api.post(url, data, { headers });
  return new Promise((resolve, reject) => {
    handleResponse(res, resolve, reject);
  });
};

const get = async (url: string, data = {}) => {
  const res = await api.get(url, data);
  return new Promise((resolve, reject) => {
    handleResponse(res, resolve, reject);
  });
};

const put = async (url: string, data = {}) => {
  token = Utils.getSavedToken();
  data = { ...data };
  const res = await api.put(url, data);
  return new Promise((resolve, reject) => {
    handleResponse(res, resolve, reject);
  });
};

const patch = async (url: string, data = {}) => {
  token = Utils.getSavedToken();
  data = { ...data };
  const res = await api.patch(url, data);
  return new Promise((resolve, reject) => {
    handleResponse(res, resolve, reject);
  });
};

const del = async (url: string, data = {}) => {
  token = Utils.getSavedToken();
  data = { ...data };
  const res = await api.delete(url, data);
  return new Promise((resolve, reject) => {
    handleResponse(res, resolve, reject);
  });
};

const getIp = async () => {
  const res = await api.get('http://ip-api.com/json');
  return res;
};

const upload = (url: string, data: any) => {
  return api.put(url, data.payload, {
    onUploadProgress: data.onUploadProgress,
  });
};

const setToken = (newToken: string) => {
  token = newToken;
  api.setHeader('Authorization', `Bearer ${token}`);
};

const getToken = () => {
  return token;
};

const setLangHeader = (value: string) => {
  api.setHeader('lang', value);
};

export default {
  postFormData,
  post,
  put,
  del,
  patch,
  upload,
  get,
  authRequest,
  postMany,
  putMany,
  getIp,
};

export { setToken, getToken, setLangHeader };
