import styled from "styled-components";
import { FlexHorizontal } from "../../Common";
import { ColorName } from "../../Variables";

const InputField = styled(FlexHorizontal)`
  width: max-content;
  height: auto;
  align-items: center;
  position: relative;
  border: 1px solid transparent;
  box-sizing: border-box;
  border-radius: 5px;

  label {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400px;
    color: ${ColorName.darkElectricBlue};
    margin-right: 15px;
  }
  &:hover {
    cursor: pointer;
  }
  &.focus {
    border: 1px solid ${ColorName.darkElectricBlue};
  }

  &.disabled {
    &:hover {
      cursor: not-allowed;
      label {
        cursor: not-allowed;
      }
    }
    i:hover {
      cursor: not-allowed !important;
    }
  }

  & .content {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background: ${ColorName.aquaHaze};
    padding: 19px 13px;
    box-sizing: border-box;
    width: 200px;
    height: 40px;
    border-radius: 5px;
    position: relative;

    & .placeholder {
      opacity: 0.5;
      color: ${ColorName.darkElectricBlue};
      font-size: 14px;
    }

    & .value {
      color: ${ColorName.darkElectricBlue};
    }

    p {
      /* position: absolute; */
      left: 20px;
    }

    i {
      font-size: 17px;
      color: ${ColorName.darkElectricBlue};
      opacity: 1;
    }
  }

  & .react-datepicker {
    border-radius: 10px;
    overflow: hidden;
  }

  & .react-datepicker__input-container input {
    flex: 1;
    box-sizing: border-box;
    width: 100%;
    background: transparent;
    border: none;
    outline: none;
    font-size: 14px;
    color: ${ColorName.darkElectricBlue};
  }

  & .react-datepicker__month-container {
    width: 400px;
  }

  & .react-datepicker__month {
    padding: 20px 0;
  }

  .react-datepicker__header {
    background-color: white;
  }

  & .react-datepicker__navigation--previous,
  .react-datepicker__navigation--next {
    top: 22px;
  }
  & .react-datepicker__navigation--previous {
    left: 30px;
  }
  .react-datepicker__navigation--next {
    right: 30px;
  }

  & .react-datepicker__header {
    & .react-datepicker__current-month {
      font-size: 28px;
      line-height: 61px;
      color: ${ColorName.blueBayoux};
    }
  }

  & .react-datepicker__day-name {
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    color: ${ColorName.burntSienna};
    text-transform: uppercase;
    width: 44px;
  }

  & .react-datepicker__triangle {
    display: none;
  }

  & .react-datepicker__week {
    & .react-datepicker__day {
      font-weight: normal;
      font-size: 18px;
      line-height: 23px;
      color: ${ColorName.blueBayoux};
      width: 44px;
      height: 44px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
    & .react-datepicker__day--outside-month,
    & .react-datepicker__day--disabled {
      opacity: 0.5;
    }
    & .react-datepicker__day--today {
      background: ${ColorName.darkElectricBlue};
      color: white;
      border-radius: 10px;
    }
    & .react-datepicker__day--selected {
      background: ${ColorName.burntSienna};
      color: white;
      border-radius: 10px;
      .react-datepicker__day--today {
        background: ${ColorName.burntSienna};
      }
    }
    & .react-datepicker__day--keyboard-selected {
      background: ${ColorName.burntSienna};
    }
  }
`;

export { InputField };
