const createActionMap = (actionList: any) => {
  const map: any = {};
  const actions = actionList.split("\n");
  actions.forEach((action: any) => {
    const name = action.trim();
    if (name !== "") {
      map[`${name}_REQUEST`] = `${name}_REQUEST`;
      map[`${name}_SUCCESS`] = `${name}_SUCCESS`;
      map[`${name}_FAILURE`] = `${name}_FAILURE`;
    }
  });
  return map;
};

const asyncActions = createActionMap(`
    UPDATE_PASSWORD

    GET_ARTICLES_BY_ID
    GET_ALL_ARTICLES
    FILTER_ARTICLES
    CREATE_ARTICLES
    UPDATE_ARTICLES
    UPDATE_ORDER_ARTICLES
    GET_ORDERED_ARTICLES
    GET_UNORDERED_ARTICLES
    REMOVE_SINGLE_ARTICLES
    REMOVE_MULTIPLE_ARTICLES
    GET_RECENT_ARTICLES

    LOGIN

    GET_ALL_CAREERS
    FILTER_CAREERS
    CREATE_CAREER
    UPDATE_CAREER
    GET_CAREER_BY_ID
    REMOVE_SINGLE_CAREER
    REMOVE_MULTIPLE_CAREERS
    APPLY_CAREER

    GET_ALL_CLIENTS
    GET_CLIENTS_FOR_USER
    GET_CLIENT_BY_ID
    CREATE_CLIENT
    UPDATE_CLIENT
    REMOVE_SINGLE_CLIENT
    REMOVE_MULTIPLE_CLIENTS

    SEND_MAIL_CONTACT

    GET_COUNT_DASHBOARD
    GET_LOGS_ACTIVITY

    UPDATE_CONTENT_SERVICES_SOLUTIONS
    UPDATE_CONTENT_IDRA_PAGES
    UPDATE_CONTENT_WHO_WE_ARE
    UPDATE_CONTENT_CONTACT
    UPDATE_CONTENT_CAREERS
    UPDATE_CONTENT_HOMEPAGE
    GET_EDIT_CONTENT
    
    GET_MEDIA_BY_TYPE
    UPLOAD_MEDIA
    REMOVE_MEDIA
    GET_VIDEOS

    GET_MEMBER_BY_ID
    CREATE_MEMBER
    UPDATE_MEMBER
    GET_ALL_MEMBERS
    REMOVE_SINGLE_MEMBER
    REMOVE_MULTIPLE_MEMBERS
    GET_MEMBERS_FOR_USER

    GET_OUR_WORK_PROJECT_BY_ID
    GET_ALL_OUR_WORK_PROJECT

    SEARCH

    GET_SITE_SETTINGS
    UPDATE_SITE_SETTINGS
    REMOVE_SITE_SETTINGS

    GET_IP_LOCATION
    
    FETCH_ALUMNI_NETWORK
    GET_ALUMNI_NETWORK_BY_ID
    CREATE_ALUMNI_NETWORK
    GET_ALUMNI_NETWORK_CONTENT
    UPDATE_ALUMNI_NETWORK_CONTENT
    APPROVE_ALUMNI_NETWORK
    DENY_ALUMNI_NETWORK
    
`);

const singleActions = {
  // ACCOUNT
  SET_ACCOUNT_DETAILS: "SET_ACCOUNT_DETAILS",
  CLEAR_ACCOUNT_DETAILS: "CLEAR_ACCOUNT_DETAILS",

  // ARTICLES
  SET_PAYLOAD_ORDERED_ARTICLES: "SET_PAYLOAD_ORDERED_ARTICLES",
  CLEAR_PAYLOAD_ORDERED_ARTICLES: "CLEAR_PAYLOAD_ORDERED_ARTICLES",
  SET_PAYLOAD_UNORDERED_ARTICLES: "SET_PAYLOAD_UNORDERED_ARTICLES",
  CLEAR_PAYLOAD_UNORDERED_ARTICLES: "CLEAR_PAYLOAD_UNORDERED_ARTICLES",
  SET_PAYLOAD_ARTICLES: "SET_PAYLOAD_ARTICLES",
  CLEAR_PAYLOAD_ARTICLES: "CLEAR_PAYLOAD_ARTICLES",
  SET_ARTICLES_DETAILS: "SET_ARTICLES_DETAILS",
  CLEAR_ARTICLES_DETAILS: "CLEAR_ARTICLES_DETAILS",
  SET_ADMIN_ARTICLES_FILTER: "SET_ADMIN_ARTICLES_FILTER",
  SET_DEFAULT_ADMIN_ARTICLES_FILTER: "SET_DEFAULT_ADMIN_ARTICLES_FILTER",
  SET_USER_ARTICLES_FILTER: "SET_USER_ARTICLES_FILTER",
  SET_DEFAULT_USER_ARTICLES_FILTER: "SET_DEFAULT_USER_ARTICLES_FILTER",
  SET_USER_ARTICLES_META_PAGINATION: "SET_USER_ARTICLES_META_PAGINATION",
  SET_DEFAULT_USER_ARTICLES_META_PAGINATION:
    "SET_DEFAULT_USER_ARTICLES_META_PAGINATION",
  SET_ADMIN_ARTICLES_META: "SET_ADMIN_ARTICLES_META",
  SET_ADMIN_ARTICLES_PAGINATION: "SET_ADMIN_ARTICLES_PAGINATION",
  SET_DEFAULT_ADMIN_ARTICLES_META_PAGINATION:
    "SET_DEFAULT_ADMIN_ARTICLES_META_PAGINATION",
  SET_REMOVE_ARTICLES_IDS: "SET_REMOVE_ARTICLES_IDS",
  CLEAR_REMOVE_ARTICLES_IDS: "CLEAR_REMOVE_ARTICLES_IDS",

  // AUTHENTICATION
  LOGOUT: "LOGOUT",
  IS_LOGGED: "IS_LOGGED",

  // CAREERS
  SET_CAREERS_PAYLOAD: "SET_CAREERS_PAYLOAD",
  CLEAR_CAREERS_PAYLOAD: "CLEAR_CAREERS_PAYLOAD",
  SET_CAREERS_DETAILS: "SET_CAREERS_DETAILS",
  CLEAR_CAREERS_DETAILS: "CLEAR_CAREERS_DETAILS",
  SET_CAREERS_FILTER: "SET_CAREERS_FILTER",
  CLEAR_CAREERS_FILTER: "CLEAR_CAREERS_FILTER",
  SET_CAREERS_PAGINATION: "SET_CAREERS_PAGINATION",
  SET_CAREERS_META: "SET_CAREERS_META",
  SET_DEFAULT_CAREERS_META_PAGINATION: "SET_DEFAULT_CAREERS_META_PAGINATION",
  SET_REMOVE_CAREERS_IDS: "SET_REMOVE_CAREERS_IDS",
  CLEAR_REMOVE_CAREERS_IDS: "CLEAR_REMOVE_CAREERS_IDS",

  // CLIENTS
  SET_CLIENTS_PAYLOAD: "SET_CLIENTS_PAYLOAD",
  CLEAR_CLIENTS_PAYLOAD: "CLEAR_CLIENTS_PAYLOAD",
  SET_CLIENTS_DETAILS: "SET_CLIENTS_DETAILS",
  CLEAR_CLIENTS_DETAILS: "CLEAR_CLIENTS_DETAILS",
  SET_CLIENTS_FILTER: "SET_CLIENTS_FILTER",
  CLEAR_CLIENTS_FILTER: "CLEAR_CLIENTS_FILTER",
  SET_CLIENTS_PAGINATION: "SET_CLIENTS_PAGINATION",
  SET_CLIENTS_META: "SET_CLIENTS_META",
  SET_DEFAULT_CLIENTS_META_PAGINATION: "SET_DEFAULT_CLIENTS_META_PAGINATION",
  SET_REMOVE_CLIENTS_IDS: "SET_REMOVE_CLIENTS_IDS",
  CLEAR_REMOVE_CLIENTS_IDS: "CLEAR_REMOVE_CLIENTS_IDS",

  // DASHBOARD
  SET_PAYLOAD_COUNT: "SET_PAYLOAD_COUNT",
  SET_PAYLOAD_LOGS: "SET_PAYLOAD_LOGS",
  CLEAR_PAYLOAD_LOGS: "CLEAR_PAYLOAD_LOGS",
  SET_LOGS_META: "SET_LOGS_META",
  SET_LOGS_PAGINATION: "SET_LOGS_PAGINATION",
  SET_DEFAULT_LOGS_META_PAGINATION: "SET_DEFAULT_LOGS_META_PAGINATION",

  // EDIT CONTENT
  SET_LANGUAGE_EDITING: "SET_LANGUAGE_EDITING",
  SET_PAGE_EDITING: "SET_PAGE_EDITING",
  SET_EDIT_CONTENT_PAYLOAD: "SET_EDIT_CONTENT_PAYLOAD",
  CLEAR_EDIT_CONTENT_PAYLOAD: "CLEAR_EDIT_CONTENT_PAYLOAD",

  // MEDIA
  SET_MEDIA_FILTER: "SET_MEDIA_FILTER",
  CLEAR_MEDIA_FILTER: "CLEAR_MEDIA_FILTER",
  SET_MEDIA_PAYLOAD: "SET_MEDIA_PAYLOAD",
  CLEAR_MEDIA_PAYLOAD: "CLEAR_MEDIA_PAYLOAD",

  // MEMBERS
  SET_MEMBER_PAYLOAD: "SET_MEMBER_PAYLOAD",
  CLEAR_MEMBER_PAYLOAD: "CLEAR_MEMBER_PAYLOAD",
  SET_MEMBER_DETAILS: "SET_MEMBER_DETAILS",
  CLEAR_MEMBER_DETAILS: "CLEAR_MEMBER_DETAILS",
  SET_MEMBER_FILTER: "SET_MEMBER_FILTER",
  CLEAR_MEMBER_FILTER: "CLEAR_MEMBER_FILTER",
  SET_MEMBERS_META: "SET_MEMBERS_META",
  SET_MEMBERS_PAGINATION: "SET_MEMBERS_PAGINATION",
  SET_DEFAULT_MEMBERS_META_PAGINATION: "SET_DEFAULT_MEMBERS_META_PAGINATION",
  SET_REMOVE_MEMBERS_IDS: "SET_REMOVE_MEMBERS_IDS",
  CLEAR_REMOVE_MEMBERS_IDS: "CLEAR_REMOVE_MEMBERS_IDS",

  // OUR WORK
  SET_OUR_WORK_META_PAGINATION: "SET_OUR_WORK_META_PAGINATION",
  SET_DEFAULT_OUR_WORK_META_PAGINATION: "SET_DEFAULT_OUR_WORK_META_PAGINATION",
  SET_OUR_WORK_COUNTRY: "SET_OUR_WORK_COUNTRY",
  SET_OUR_WORK_DETAILS: "SET_OUR_WORK_DETAILS",
  CLEAR_OUR_WORK_DETAILS: "CLEAR_OUR_WORK_DETAILS",
  SET_OUR_WORK_FILTER: "SET_OUR_WORK_FILTER",
  CLEAR_OUR_WORK_FILTER: "CLEAR_OUR_WORK_FILTER",
  SET_OUR_WORK_PAYLOAD: "SET_OUR_WORK_PAYLOAD",
  CLEAR_OUR_WORK_PAYLOAD: "CLEAR_OUR_WORK_PAYLOAD",

  // PAGE SETTINGS
  SET_USER_LANG: "SET_USER_LANG",
  SET_ADMIN_LANG: "SET_ADMIN_LANG",
  SET_EXPAND_SIDE_MENU: "SET_EXPAND_SIDE_MENU",
  SET_FIRST_LOAD_FOR_USER: "SET_FIRST_LOAD_FOR_USER",
  SET_STATUS_EDITED: "SET_STATUS_EDITED",
  SET_CONTENT_EDITED: "SET_CONTENT_EDITED",
  SET_PAGE_ROUTER: "SET_PAGE_ROUTER",

  // SEARCH
  SET_SEARCH_FILTER: "SET_SEARCH_FILTER",
  CLEAR_SEARCH_FILTER: "CLEAR_SEARCH_FILTER",
  SET_SEARCH_PAYLOAD: "SET_SEARCH_PAYLOAD",
  CLEAR_SEARCH_PAYLOAD: "CLEAR_SEARCH_PAYLOAD",

  // SITE SETTINGS
  SET_SITE_SETTINGS_PAYLOAD: "SET_SITE_SETTINGS_PAYLOAD",
  CLEAR_SITE_SETTINGS_PAYLOAD: "CLEAR_SITE_SETTINGS_PAYLOAD",

  //ALUMNI NETWORK
  SET_ALUMNI_NETWORK_IS_LOADING: "SET_ALUMNI_NETWORK_IS_LOADING",
  SET_ALUMNI_NETWORK_PAGINATION: "SET_ALUMNI_NETWORK_PAGINATION",
  SET_ALUMNI_NETWORK_ORDER: "SET_ALUMNI_NETWORK_ORDER",
  SET_ALUMNI_NETWORK_META: "SET_ALUMNI_NETWORK_META",
  RESET_ALUMNI_NETWORK_REDUCER: "RESET_ALUMNI_NETWORK_REDUCER",
};

const types = {
  ...asyncActions,
  ...singleActions,
};

export default types;
