import styled from 'styled-components';
import { ColorName } from '../../../Variables';
import { FlexVertical, FlexHorizontal } from '../../../Common';

const NavigationGroup = styled(FlexVertical)`
  width: 100%;
`;
const NavigationItem = styled(FlexHorizontal)`
  height: 40px;
  padding-left: 14px;
  background: transparent;
  border-radius: 4px;
  box-sizing: border-box;
  align-items: center;
  position: relative;
  width: 100%;
  margin-bottom: 5px;

  &.disabled {
    &:hover,
    &.active {
      background: transparent;
      cursor: normal;
      p {
        font-weight: 400;
      }
    }
  }

  & .menu-icon {
    width: 20px;
    display: inline-block;
    margin-right: 10px;
    position: relative;

    & .line {
      position: absolute;
      left: 10px;
      top: 0;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background: ${ColorName.darkElectricBlue};
    }
  }
  p {
    font-size: 13px;
    color: ${ColorName.darkElectricBlue};
    /* transition: all ease 0.15s; */
    width: 100%;
    flex: 1;
  }

  &:hover,
  &.active {
    background: ${ColorName.gainsboro};
    cursor: pointer;
    p {
      font-weight: 600;
    }
  }
`;

const SideMenuContainer = styled(FlexVertical)`
  height: 100vh;
  min-width: 230px;
  max-width: 230px;
  /* transition: all ease 0.25s; */
  background: ${ColorName.aquaHaze};
  padding: 34px 20px;
  overflow: visible;
  box-sizing: border-box;
  position: relative;

  img:hover {
    cursor: pointer;
  }
  &.expand {
    min-width: 80px;
    max-width: 80px;

    img {
      &.show {
        opacity: 0;
      }
      &.hide {
        opacity: 1;
      }
    }

    ${NavigationGroup} {
      align-items: center;
    }
    & .menu-icon {
      margin-right: 0px;
    }

    ${NavigationItem} {
      width: 40px;
      height: 40px;
      align-items: center;
      justify-content: center;
      margin-bottom: 10px;
      padding: 0;
      p {
        position: absolute;
        left: 100%;
        width: 0px;
        opacity: 0;
        height: 100%;
        overflow: hidden;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        margin: 0;
        top: 0;
        display: flex;
        align-items: center;
        padding-left: 15px;
      }

      &:hover {
        p {
          opacity: 1;
          left: calc(100% - 10px);
          width: 160px;
          z-index: 10;
          background: ${ColorName.gainsboro};
        }
      }
    }
  }
`;

const ExpandIcon = styled.div`
  position: absolute;
  width: 40px;
  height: 40px;
  left: calc(100% - 20px);
  top: 35px;
  z-index: 3;
  img {
    /* transition: all ease 0.25s; */
    position: absolute;
    &:hover {
      cursor: pointer;
    }
    &.rotate {
      transform: rotate(180deg);
    }
  }
`;

const LogoName = styled(FlexHorizontal)`
  align-items: flex-end;
  width: 78px;
  overflow: hidden;
  /* transition: all ease 0.25s; */

  &.hide {
    width: 0px;
  }
`;

export { SideMenuContainer, NavigationGroup, NavigationItem, ExpandIcon, LogoName };
