import { combineReducers } from "redux";
import Account from "./Account.reducer";
import Articles from "./Articles.reducer";
import Authentication from "./Authentication.reducer";
import Careers from "./Careers.reducer";
import Clients from "./Clients.reducer";
import Contact from "./Contact.reducer";
import Dashboard from "./Dashboard.reducer";
import EditContent from "./EditContent.reducer";
import Media from "./Media.reducer";
import Members from "./Members.reducer";
import PageSettings from "./PageSettings.reducer";
import OurWork from "./OurWork.reducer";
import Search from "./Search.reducer";
import SiteSettings from "./SiteSettings.reducer";
import AlumniNetwork from "./AlumniNetwork.reducer";

const rootReducer = combineReducers({
  Account,
  Articles,
  Authentication,
  Careers,
  Clients,
  Contact,
  Dashboard,
  EditContent,
  Media,
  Members,
  OurWork,
  PageSettings,
  Search,
  SiteSettings,
  AlumniNetwork,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
