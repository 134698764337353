import styled from 'styled-components';
import { HorizontalContainer, VerticalContainer } from '../CommonLayout';
import { ColorName } from '../../Variables';

const Title = styled.h3`
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  i {
    margin-left: 10px;
    font-size: 15px;
    display: inline;
    @media screen and (min-width: 837px) {
      display: none;
    }
  }

  @media screen and (max-width: 426px) {
    font-size: 18px;
    margin-bottom: 10px;
  }
  @media screen and (max-width: 836px) {
    border: 1px solid black;
    padding: 5px 10px;

    &.collapse {
      border: 1px solid black;
    }
    &.uncollapse {
      border: 1px solid transparent;
    }
    &:hover {
      cursor: pointer;
    }
    margin-bottom: 0px;
    margin-top: 0px;
    transition: all ease 0.3s;
  }
`;

const ResetButton = styled.div`
  width: 100%;
  height: 45px;
  font-size: 20px;
  font-weight: 600;
  color: ${ColorName.black};
  display: flex;
  align-items: center;
  text-transform: uppercase;
  margin: 20px 10px;
  &:hover {
    cursor: pointer;
  }
  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
    user-select: none;
  }

  @media screen and (max-width: 426px) {
    font-size: 16px;
  }
`;

const DropdownGroup = styled(VerticalContainer)`
  position: absolute;
  top: calc(100% + 2px);
  left: 0;
  width: calc(100% - 2px);
  max-height: 0px;
  overflow-y: hidden;
  z-index: 1;
  transition: all ease 0.25s;
  border: 1px solid transparent;

  &.show {
    background: ${ColorName.white};
    color: ${ColorName.black};
    border: 1px solid ${ColorName.darkElectricBlue};
    max-height: 180px;
    overflow-y: auto;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: ${ColorName.aquaHaze};
  }

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background-color: ${ColorName.darkElectricBlue};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${ColorName.darkElectricBlue};
    border: 2px solid #555555;
  }
`;

const FilterGroup = styled(VerticalContainer)`
  @media screen and (max-width: 836px) {
    flex-direction: row;
    flex-wrap: wrap;
    transition: all ease-in-out 0.3s;
    &.uncollapse {
      max-height: 400px;
      margin-top: 2rem;
      overflow: visible;
    }
    &.collapse {
      max-height: 0px;
      margin-top: 0rem;
      overflow: hidden;
    }
  }
`;

const DropdownItem = styled(HorizontalContainer)`
  height: auto;
  min-height: 25px;
  padding: 10px 0;
  background: ${ColorName.white};
  display: flex;
  align-items: center;
  padding-left: 26px;

  &:hover {
    color: ${ColorName.white};
    background: ${ColorName.darkElectricBlue};
  }
`;

const FilterContainer = styled(VerticalContainer)`
  width: 320px;

  & .filter-mobile {
    display: none;
  }
  @media screen and (max-width: 836px) {
    width: 100%;
    flex: unset;
  }
`;
const FilterItem = styled(HorizontalContainer)`
  align-items: center;
  width: 100%;
  height: 45px;

  background: ${ColorName.pampas};
  display: flex;
  align-items: center;
  box-sizing: border-box;
  margin-bottom: 1em;
  font-size: 1.6rem;
  transition: all ease 0.25s;
  padding-left: 26px;
  padding-right: 14px;
  position: relative;

  p {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  i {
    font-size: 2rem;
  }

  &.active {
    background: ${ColorName.darkElectricBlue};
    color: ${ColorName.white};
  }
  & .filter_group {
    width: 100%;
  }

  &:hover {
    background: ${ColorName.darkElectricBlue};
    color: ${ColorName.white};
    cursor: pointer;
  }

  &.implement {
    width: 100%;
  }
  &.disabled {
    opacity: 0.5;
    &:hover {
      background: ${ColorName.pampas};
      color: ${ColorName.black};
      cursor: not-allowed;

      ${DropdownGroup} {
        background: ${ColorName.pampas};
        color: ${ColorName.black};
      }
      ${DropdownItem} {
        background: ${ColorName.white};
        color: ${ColorName.black};
      }
    }
  }

  @media screen and (max-width: 836px) {
    width: max-content;
    margin: 3px;
    white-space: pre;
    border-right: 1px solid #ccc;
    &:last-child {
      border-right: unset;
    }
  }

  @media screen and (max-width: 426px) {
    font-size: 14px;
    p {
      font-size: 14px;
    }
  }
`;

const FilterButton = styled(Title)`
  display: flex;
  justify-content: space-between;
  &.show {
    justify-content: flex-end;
    border-color: transparent;
  }

  @media screen and (max-width: 836px) {
    margin-bottom: 2rem;
    font-size: 20px;
  }

  @media screen and (max-width: 436px) {
    font-size: 18px;
  }

  @media screen and (min-width: 837px) {
    display: none;
  }
`;

const FilterChildOptions = styled.div`
  height: auto;
  @media screen and (max-width: 836px) {
    overflow: hidden;
    height: 0px;

    &.show {
      height: auto;
      overflow: visible;
    }
  }
`;

export {
  FilterContainer,
  FilterItem,
  Title,
  FilterGroup,
  ResetButton,
  DropdownGroup,
  DropdownItem,
  FilterButton,
  FilterChildOptions,
};
