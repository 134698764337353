import _ from 'lodash';
import React from 'react';
import { Wrapper, Link } from './AdminPagination.styles';
import { usePagination } from './PaginationCalculation';

interface SectionProps {
  maxPage: number;
  currentPage: number;
  numberPageDisplay: number;
  totalCount: number;
  clickLinkPagination(page: number): unknown;
  clickLinkNext(page: number): unknown;
  clickLinkPrevious(page: number): unknown;
  clickLinkLast(): unknown;
  clickLinkFirst(): unknown;
}

const Pagination: React.FC<SectionProps> = (props: SectionProps) => {
  const {
    maxPage,
    currentPage,
    totalCount,
    clickLinkPagination,
    clickLinkFirst,
    clickLinkLast,
    clickLinkNext,
    clickLinkPrevious,
  } = props;

  const paginationRange: any = usePagination({
    currentPage,
    totalCount,
    siblingCount: 1,
    pageSize: 10,
  });

  const renderPrevious = () => {
    return (
      <>
        <Link
          className={`${currentPage > 1 ? 'clickable' : ''} arrow`}
          onClick={() => (currentPage > 1 ? clickLinkFirst() : null)}
        >
          <i className="fas fa-caret-left" />
          <i className="fas fa-caret-left" />
        </Link>
        <Link
          className={`${currentPage > 1 ? 'clickable' : ''} arrow`}
          onClick={() => (currentPage > 1 ? clickLinkPrevious(currentPage - 1) : null)}
        >
          <i className="fas fa-caret-left" />
        </Link>
      </>
    );
  };

  const renderNext = () => {
    return (
      <>
        <Link
          className={`${currentPage < maxPage ? 'clickable' : ''} arrow`}
          onClick={() => (currentPage < maxPage ? clickLinkNext(currentPage + 1) : null)}
        >
          <i className="fas fa-caret-right" />
        </Link>
        <Link
          className={`${currentPage < maxPage ? 'clickable' : ''} arrow`}
          onClick={() => (currentPage < maxPage ? clickLinkLast() : null)}
        >
          <i className="fas fa-caret-right" />
          <i className="fas fa-caret-right" />
        </Link>
      </>
    );
  };

  const renderLinkBefore = () => {
    return renderPrevious();
  };

  const renderLinkAfter = () => {
    return renderNext();
  };

  const renderLink = (value: number | string, key: number) => {
    if (value === 'DOTS')
      return (
        <Link key={key} className="for-more">
          ...
        </Link>
      );
    else {
      const isDisabled = maxPage === 1;
      return (
        <Link
          key={key}
          className={`${isDisabled ? 'disabled' : ''} ${value === currentPage ? 'active' : ''}`}
          onClick={() => !isDisabled && clickLinkPagination(_.toNumber(value))}
        >
          {value}
        </Link>
      );
    }
  };

  const renderPagination = () => {
    return (
      <Wrapper>
        {renderLinkBefore()}
        {_.map(paginationRange, function (value, key) {
          return renderLink(value, _.toNumber(key));
        })}
        {renderLinkAfter()}
      </Wrapper>
    );
  };

  const renderMain = () => {
    return renderPagination();
  };

  return renderMain();
};

export default Pagination;
