/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { FlexHorizontal } from '../FlexLayout';
import { useSelector } from 'react-redux';
import { ROUTES } from '../../../constants';

const CarouselContainer = styled.div`
  width: 100%;
  overflow: hidden;
  scroll-behavior: smooth;
  @media screen and (max-width: 436px) {
    padding-top: 15px;
  }
`;
const CarouselContent = styled(FlexHorizontal)`
  transition: all ease-in-out 0.5s;
`;

const ClientCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  box-sizing: border-box;
  user-select: none;
  img {
    user-select: none;
    object-fit: contain;
    max-height: 100px;

    @media screen and (max-width: 836px) {
      max-height: 85px;
    }

    @media screen and (max-width: 436px) {
      max-height: 70px;
    }
  }
`;

const Carousel: React.FC = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const payload = useSelector(state => _.get(state, `Clients.payload.${ROUTES.USER.HOMEPAGE}`));
  const [loadedLogo, setLoadedLogo] = useState<number>(0);

  useEffect(() => {
    const resolvePayload: any[] = payload as any;
    if (!_.isEmpty(resolvePayload) && loadedLogo >= resolvePayload.length) {
      let index = 1;
      let positionX = 0;
      const interval = setInterval(() => {
        let flag = true;
        if (containerRef && containerRef.current) {
          if (index === 22) flag = false;
          if (flag) {
            containerRef.current.style.transition = `all ease-in-out 0.5s`;
            const logo = document.querySelector<HTMLElement>(`#logo-${index}`);
            if (logo) {
              const widthLogo = logo.offsetWidth;
              positionX = widthLogo + positionX;
              containerRef.current.style.transform = `translateX(-${positionX}px)`;
              index++;
            }
          } else {
            containerRef.current.style.transform = `translateX(0px)`;
            containerRef.current.style.transition = `unset`;
            index = 1;
            positionX = 0;
            const logo = document.querySelector<HTMLElement>(`#logo-${index}`);
            if (logo) {
              const widthLogo = logo.offsetWidth;
              positionX = widthLogo + positionX;
              containerRef.current.style.transition = `all ease-in-out 0.5s`;
              containerRef.current.style.transform = `translateX(-${positionX}px)`;
              index++;
            }
          }
        }
      }, 2000);
      return () => clearInterval(interval);
    }
  }, [payload, loadedLogo]);

  const renderCarousel = () => {
    const render = _.map(payload, (item, index) => {
      const companyLogo = _.get(item, 'companyLogo');
      const companyType = _.get(item, 'type');
      const id = _.get(item, 'id');
      return (
        <ClientCard id={`logo-${index + 1}`} key={`trusted-by-${id}-${index}`}>
          <img src={companyLogo} alt={`logo-${companyType}`} onLoad={() => setLoadedLogo(loadedLogo + 1)} />
        </ClientCard>
      );
    });
    return render;
  };

  return (
    <CarouselContainer>
      <CarouselContent ref={containerRef}>{renderCarousel()}</CarouselContent>
    </CarouselContainer>
  );
};

export default Carousel;
