/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import get from 'lodash/get';
import find from 'lodash/find';
import forEach from 'lodash/forEach';
import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';
import map from 'lodash/map';
import assign from 'lodash/assign';
import cloneDeep from 'lodash/cloneDeep';
import take from 'lodash/take';
import round from 'lodash/round';
import includes from 'lodash/includes';
import sanitizeHtml from 'sanitize-html';

import SkeletonNewsDetails from './SkeletonLoading';
import { RelatedPost, OutputImage, DetailsContentColumn } from './NewsDetails.styles';
import {
  Title,
  Description,
  TimeStamp,
  FlexVertical,
  DetailsContent,
  RelatedContent,
  LineSection,
  PreviewListImage,
} from '../../../components/Common';
import { GenerateValue } from '../../../libs';
import { Link, useLocation } from 'react-router-dom';
import GridImageLayout from './GridImageLayout';
import { useTranslation } from 'react-i18next';

const { convertTime } = GenerateValue;

interface SectionProps {
  recentNews?: any[];
  details?: any;
  recentLabel?: string;
  locale: string;
  onViewDetails(id: string): unknown;
  isLoading?: boolean;
}

const CATEGORY_LINK = {
  developmentNews: '/development-news',
  researchNews: '/research-news',
  general: '/news',
};

const NewsContent = (props: SectionProps) => {
  const { t } = useTranslation('user');
  const { recentNews, details, recentLabel, locale, isLoading } = props;
  const [localLoading, setLocalLoading] = useState<boolean>(false);
  const [resolveRecentNews, setResolveRecentNews] = useState<any[]>([]);
  const [isShowPreviewListImages, setIsShowPreviewListImages] = useState<boolean>(false);
  const [listImages, setListImages] = useState<string[]>([]);
  const [selectedImage, setSelectedImage] = useState<string>('');
  const descriptionRef = useRef<HTMLParagraphElement>(null);
  const location = useLocation();
  const pathname = get(location, 'state.from');
  const search = get(location, 'state.query');

  useEffect(() => {
    if (!isEmpty(recentNews)) {
      const cloneRecent = cloneDeep(recentNews);
      setResolveRecentNews(take(cloneRecent, 3));
    }
  }, [recentNews]);

  const [iframeRatio, setIframeRatio] = useState<any>({
    width: '100%',
    height: '400px',
  });

  const resizeWindow = () => {
    if (descriptionRef && descriptionRef.current) {
      const innerWidth = descriptionRef.current.clientWidth;
      const innerHeight = round((innerWidth / 16) * 9);
      const resolveIframeRatio = {
        width: `${innerWidth}px`,
        height: `${innerHeight}px`,
      };
      setIframeRatio(resolveIframeRatio);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', resizeWindow);
    window.addEventListener('load', resizeWindow);

    return () => {
      window.removeEventListener('resize', resizeWindow);
      window.removeEventListener('load', resizeWindow);
    };
  }, []);

  useEffect(() => {
    if (isLoading) {
      setLocalLoading(true);
      setTimeout(() => {
        setLocalLoading(false);
      }, 1500);
    }
  }, [isLoading]);

  useEffect(() => {
    if (descriptionRef.current) {
      const galleryImages = get(details, 'galleryImages');
      const listImages = document.querySelectorAll('.preview img');
      const imageSources: string[] = [];
      forEach(listImages, (image: any) => imageSources.push(get(image, 'src')));
      forEach(galleryImages, (item: any) => {
        const images = get(item, 'images');
        imageSources.push(...[...images]);
      });
      setListImages(imageSources);
    }
  }, [descriptionRef.current]);

  // Parse string to HTML
  const createMarkup = (html: any) => {
    const defaultAllowAttributes = sanitizeHtml.defaults.allowedAttributes;
    const resolveIframeAttributes = assign(defaultAllowAttributes, {
      iframe: ['src', 'frameborder', 'allowfullscreen'],
      img: ['style', 'data-percentage', 'data-rotatey', 'origin-size', 'data-origin', 'data-size', 'data-align', 'src'],
      figure: ['style', 'min-width'],
      div: ['style', 'data-percentage', 'data-rotatey', 'origin-size'],
    });
    const options = {
      allowedTags: sanitizeHtml.defaults.allowedTags.concat(['img', 'iframe']),
      allowedAttributes: resolveIframeAttributes,
    };
    return {
      __html: sanitizeHtml(html, options),
    };
  };

  const renderDownloadContent = () => {
    const downloadContent = get(details, 'downloadContent');
    const currentDownloadContent = get(downloadContent, locale);
    if (!isEmpty(currentDownloadContent))
      return (
        <>
          <h3>{t('label.viewFiles')}</h3>
          <Description
            className={`preview mt-2`}
            ref={descriptionRef}
            dangerouslySetInnerHTML={createMarkup(currentDownloadContent)}
          />
          <LineSection />
        </>
      );
    return null;
  };

  const renderGalleryLayout = () => {
    const content = get(details, `content.${locale}`);
    if (content) {
      const galleryImages = get(details, 'galleryImages');
      const splitContentByGallery = content.split(/##(GALLERY[0-9])##/g);
      const render = map(splitContentByGallery, (item, index) => {
        if (includes(item, 'GALLERY') && !isEmpty(galleryImages)) {
          const findGallery = find(galleryImages, image => {
            const itemVariable = get(image, 'variable');
            if (includes(itemVariable, item)) return item;
          });
          const type = get(findGallery, 'type');
          return (
            <GridImageLayout
              key={`layout-${type}-${index}`}
              data={findGallery}
              onClick={(url: string) => {
                setSelectedImage(url);
                setIsShowPreviewListImages(true);
              }}
            />
          );
        }
        return (
          <Description
            className={`preview preview-${index}`}
            ref={descriptionRef}
            width={get(iframeRatio, 'width')}
            height={get(iframeRatio, 'height')}
            dangerouslySetInnerHTML={createMarkup(item)}
            key={`description-${index}`}
          ></Description>
        );
      });
      return render;
    }
  };

  // Render related thumbnail
  const renderRelatedImage = (croppedData: any, thumbnail: string, id: string, categories: string) => {
    const croppedArea = get(croppedData, 'croppedAreaData');
    if (!croppedArea) return <img src={thumbnail} alt="" />;
    else {
      const scale = 100 / croppedArea.width;
      const transform = {
        x: `${-croppedArea.x * scale}%`,
        y: `${-croppedArea.y * scale}%`,
        scale,
        width: 'calc(100% + 0.5px)',
        height: 'auto',
      };

      const imageStyle = {
        transform: `translate3d(${transform.x}, ${transform.y}, 0) scale3d(${transform.scale},${transform.scale},1)`,
        width: transform.width,
        height: transform.height,
      };

      return (
        <Link
          to={{
            pathname: `${get(CATEGORY_LINK, categories)}/${id}`,
            state: { from: pathname, query: search },
          }}
        >
          <OutputImage className="output">
            <img src={thumbnail} alt="news thumbnail" style={imageStyle} />
          </OutputImage>
        </Link>
      );
    }
  };

  return (
    <DetailsContentColumn>
      {isShowPreviewListImages && (
        <PreviewListImage
          selected={selectedImage}
          payload={listImages}
          onClose={() => {
            setSelectedImage('');
            setIsShowPreviewListImages(false);
          }}
        />
      )}
      <DetailsContent>
        {isLoading || localLoading ? (
          <SkeletonNewsDetails />
        ) : (
          <>
            <Title>{get(details, `title.${locale}`)}</Title>
            <TimeStamp className="mt-1 mb-2">
              <i className="fas fa-calendar-alt" /> {convertTime(get(details, 'createdAt'))}
            </TimeStamp>
            <div className="news-content">{renderGalleryLayout()}</div>
          </>
        )}
      </DetailsContent>
      {isLoading || localLoading ? (
        <RelatedContent>
          <SkeletonNewsDetails isRelated />
        </RelatedContent>
      ) : (
        !isEmpty(resolveRecentNews) &&
        isArray(resolveRecentNews) && (
          <RelatedContent>
            {renderDownloadContent()}
            <h3>{recentLabel}</h3>

            {map(resolveRecentNews, item => {
              const { title, createdAt, thumbnail, id, croppedData, categories } = item;
              return (
                <RelatedPost key={`recent-${id}`} className="mt-3">
                  {renderRelatedImage(croppedData, thumbnail, id, categories)}

                  <FlexVertical className="pl-2 related-news">
                    <Link
                      to={{
                        pathname: `${get(CATEGORY_LINK, categories)}/${id}`,
                        state: { from: pathname, query: search },
                      }}
                    >
                      {get(title, locale)}
                    </Link>
                    <TimeStamp className="timestamp">
                      <i className="fas fa-calendar-alt" /> {convertTime(createdAt)}
                    </TimeStamp>
                  </FlexVertical>
                </RelatedPost>
              );
            })}
          </RelatedContent>
        )
      )}
    </DetailsContentColumn>
  );
};

export default NewsContent;
