/* eslint import/no-anonymous-default-export: off */
export default {
  ARTICLES: [
    {
      label: "",
      value: "id",
      isCenter: true,
    },
    {
      label: "title",
      value: "title",
    },
    {
      label: "reached",
      value: "reached",
      width: 95,
      isCenter: true,
      isSort: true,
    },
    {
      label: "lastUpdated",
      value: "updatedAt",
      width: 175,
      isSort: true,
    },
    {
      label: "status",
      value: "status",
      width: 80,
      isCenter: true,
    },
    {
      label: "index",
      value: "isShowOnHomepage",
      width: 80,
      isCenter: true,
    },
    {
      label: "edit",
      value: "edit",
      width: 80,
      isCenter: true,
      isFunction: true,
    },
    {
      label: "remove",
      value: "remove",
      width: 80,
      isCenter: true,
      isFunction: true,
    },
  ],
  ACTIVITY: [
    {
      label: "activity",
      value: "title",
    },
    {
      label: "date",
      value: "createdAt",
      isCenter: true,
    },
    {
      label: "",
      value: "view",
      isCenter: true,
    },
  ],
  CAREER: [
    {
      label: "",
      value: "id",
      isCenter: true,
    },
    {
      label: "jobName",
      value: "title",
    },
    {
      label: "reached",
      value: "reached",
      isCenter: true,
      isSort: true,
    },
    {
      label: "updatedAt",
      value: "updatedAt",
      isCenter: true,
      isSort: true,
    },
    {
      label: "status",
      value: "status",
      isCenter: true,
    },
    {
      label: "edit",
      value: "edit",
      width: 80,
      isCenter: true,
      isFunction: true,
    },
    {
      label: "remove",
      value: "remove",
      width: 80,
      isCenter: true,
      isFunction: true,
    },
  ],
  CLIENTS: [
    {
      label: "",
      value: "id",
      isCenter: true,
    },
    {
      label: "imagePreview",
      value: "companyLogo",
      isCenter: true,
    },
    {
      label: "updatedAt",
      value: "updatedAt",
    },
    {
      label: "edit",
      value: "edit",
      isCenter: true,
    },
    {
      label: "remove",
      value: "remove",
      isCenter: true,
      isFunction: true,
    },
  ],
  ORDER_NEWS: [
    {
      label: "title",
      value: "title",
    },
    {
      label: "topic",
      value: "topic",
      width: 235,
    },
    {
      label: "lastUpdated",
      value: "updatedAt",
      width: 154,
    },
    {
      label: "position",
      value: "orderNumber",
      width: 154,
      isCenter: true,
    },
    {
      label: "status",
      value: "status",
      width: 80,
      isCenter: true,
    },
    {
      label: "remove",
      value: "remove",
      width: 100,
      isCenter: true,
    },
  ],
  TEAM_MEMBERS: [
    {
      label: "",
      value: "id",
      isCenter: true,
    },
    {
      label: "fullname",
      value: "fullname",
    },
    {
      label: "position",
      value: "position",
      width: 154,
    },
    {
      label: "description",
      value: "description",
      width: 235,
    },
    {
      label: "lastUpdated",
      value: "updatedAt",
      width: 154,
    },
    {
      label: "status",
      value: "statusCheck",
      width: 80,
      isCenter: true,
    },
    {
      label: "edit",
      value: "edit",
      width: 80,
      isCenter: true,
    },
    {
      label: "remove",
      value: "remove",
      width: 100,
      isCenter: true,
    },
  ],
  PAGES_COLUMN: [
    {
      label: "page",
      value: "page",
    },
    {
      label: "router",
      value: "router",
    },
    {
      label: "edit",
      value: "edit",
      isCenter: true,
    },
  ],
  RECENT_LOGS: [
    {
      label: "requestType",
      value: "requestType",
    },
    {
      label: "section",
      value: "section",
    },
    {
      label: "title",
      value: "title",
    },
    {
      label: "logAt",
      value: "updatedAt",
    },
  ],
  ALUMNI_NETWORK: [
    {
      label: "name",
      value: "name",
      isSort: true,
    },
    {
      label: "email",
      value: "email",
      isSort: true,
    },
    {
      label: "country",
      value: "country",
      isSort: true,
    },
    // {
    //   label: "phoneNumber",
    //   value: "mobile",
    // },
    {
      label: "submittedDate",
      value: "createdAt",
      isSort: true,
    },
    {
      label: "status",
      value: "status",
      isCenter: true,
      isSort: true,
    },
    {
      label: "edit",
      value: "edit",
      isCenter: true,
      isFunction: true,
    },
  ],
};
