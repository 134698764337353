/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  CarrotSolidButton,
  GeyserSolidButton,
  GridLayout,
  Step,
} from "../../../Common";
import { ButtonBar, HeaderPopup, PopupWrapper } from "../DefaultPopup.styles";
import {
  GalleryContainer,
  GalleryContent,
  GalleryContentItem,
  SelectedMedia,
  MediaBox,
  GalleryOptions,
  GalleryOptionItem,
} from "./GalleryImages.styles";
import { MediaActions } from "../../../../actions";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { Icons } from "../../../../themes";
import GridImageLayout from "../../../../containers/UserPage/NewsDetails/GridImageLayout";
import { useTranslation } from "react-i18next";

interface SectionProps {
  className?: string;
  data: any;
  onClose(data?: { type: string; images: string[]; variable?: string }): void;
}

const STEP = [
  {
    label: "selectImages",
    value: "images",
  },
  {
    label: "selectLayout",
    value: "layout",
  },
];

const IMAGES_LAYOUT = [
  {
    label: "grid",
    value: "grid",
    icon: Icons.grid.default,
  },
  // {
  //   label: 'Masonry',
  //   value: 'masonry',
  //   icon: Icons.masonry,
  // },
  {
    label: "thumbnails",
    value: "thumbnails",
    icon: Icons.thumbnails.default,
  },
  // {
  //   label: 'Slideshow',
  //   value: 'slideshow',
  //   icon: Icons.slideshow,
  // },
  // {
  //   label: 'Slider',
  //   value: 'slider',
  //   icon: Icons.slider,
  // },
];

const { getMediaByType, setMediaFilter } = MediaActions;

const GalleryImages = (props: SectionProps) => {
  const { t } = useTranslation("admin");
  const { className, onClose, data } = props;
  const dispatch = useDispatch();
  const mediaPayload = useSelector((state) =>
    _.get(state, "Media.mediaPayload")
  );

  const [selectedMedia, setSelectedMedia] = useState<string[]>([]);
  const [step, setStep] = useState<string>("images");
  const [layoutType, setLayoutType] = useState<string>("grid");

  useEffect(() => {
    const newFilter = { keyword: "", type: "image" };
    dispatch(setMediaFilter(newFilter));
    dispatch(getMediaByType(newFilter));

    if (data) {
      const images = _.get(data, "images");
      const layout = _.get(data, "type");
      if (images) setSelectedMedia(images);
      if (layout) setLayoutType(layout);
    }

    return () => {
      dispatch(setMediaFilter(newFilter));
    };
  }, []);

  const onSelectMedia = (mediaUrl: string) => {
    const isExist = _.includes(selectedMedia, mediaUrl);
    if (isExist) {
      const filterMedia = _.filter(selectedMedia, (item) => item !== mediaUrl);
      setSelectedMedia(filterMedia);
    } else setSelectedMedia([...selectedMedia, mediaUrl]);
  };

  const onSubmit = () => {
    if (step === "images") setStep("layout");
    else
      onClose({
        type: layoutType,
        images: selectedMedia,
        variable: _.get(data, "variable"),
      });
  };

  const renderMain = () => {
    return (
      <GalleryContainer>
        <HeaderPopup>{t("headerPopup.galleryImages")}</HeaderPopup>
        <Step
          step={STEP}
          active={step}
          onChangeStep={(newStep: string) => setStep(newStep)}
        />
        <GalleryContent>
          {step === "images" ? (
            <GalleryContentItem>
              <MediaBox>
                <GridLayout width="100%" col="5" colGap="15px" rowGap="30px">
                  {_.map(mediaPayload, (item) => {
                    const mediaUrl = _.get(item, "mediaUrl");
                    const findIndex = _.findIndex(
                      selectedMedia,
                      (e) => e === mediaUrl,
                      0
                    );
                    return (
                      <div
                        className="media"
                        key={`media-${_.get(item, "id")}`}
                        onClick={() => onSelectMedia(mediaUrl)}
                      >
                        <img src={_.get(item, "mediaUrl")} alt="" />
                        <span>{_.get(item, "mediaName")}</span>
                        {findIndex >= 0 && (
                          <div className="index">{findIndex + 1}</div>
                        )}
                      </div>
                    );
                  })}
                </GridLayout>
              </MediaBox>
              <SelectedMedia>
                <GridLayout width="100%" col="1" colGap="15px" rowGap="15px">
                  {_.map(selectedMedia, (item, index) => {
                    return (
                      <div className="media" key={`media-${index}`}>
                        <img src={item} alt="" />
                        <div className="index">{index + 1}</div>
                        <div
                          className="remove"
                          onClick={() => onSelectMedia(item)}
                        >
                          <i className="fas fa-times" />
                        </div>
                      </div>
                    );
                  })}
                </GridLayout>
              </SelectedMedia>
            </GalleryContentItem>
          ) : (
            <GalleryContentItem className="flex-column">
              <GalleryOptions>
                {_.map(IMAGES_LAYOUT, (item) => (
                  <GalleryOptionItem
                    className={layoutType === item.value ? "active" : ""}
                    key={`image-option-${item.label}`}
                    onClick={() => setLayoutType(item.value)}
                  >
                    <img src={item.icon} alt="" />
                    <span>{item.label}</span>
                  </GalleryOptionItem>
                ))}
              </GalleryOptions>
              {/* <ImageLayout type={layoutType} images={selectedMedia} /> */}
              <GridImageLayout
                style={{ maxWidth: "720px" }}
                data={{ images: selectedMedia, type: layoutType }}
              />
            </GalleryContentItem>
          )}
        </GalleryContent>
        <ButtonBar className="flex-justify-end">
          <GeyserSolidButton onClick={() => onClose()}>
            {t("button.close")}
          </GeyserSolidButton>
          <CarrotSolidButton className="ml-1" onClick={() => onSubmit()}>
            {step === "layout" ? t("button.save") : t("button.next")}
          </CarrotSolidButton>
        </ButtonBar>
      </GalleryContainer>
    );
  };

  return (
    <PopupWrapper className={`popup-wrapper ${className}`}>
      {renderMain()}
    </PopupWrapper>
  );
};

export default GalleryImages;
