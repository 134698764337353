/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import {
  SliderContainer,
  BackgroundImage,
  ImageItem,
  ContentWrapper,
  ContentSlider,
  ContentItem,
  Title,
  Content,
  SliderBar,
  BarItem,
} from './Slider.styles';
import { Link } from 'react-router-dom';
import { SiteSettingsActions } from '../../../actions';
import { ROUTES } from '../../../constants';

const { getSiteSettings } = SiteSettingsActions;

const SolutionCard: React.FC = () => {
  const { t } = useTranslation('user');
  // Declare dispatch, reducer
  const dispatch = useDispatch();
  const payload = useSelector(state => _.get(state, 'SiteSettings.payload'));
  const lang = useSelector(state => _.get(state, 'PageSettings.userLang'));
  const contentUnLoaded = useSelector(state => _.get(state, 'PageSettings.firstLoading'));
  // Declare state
  const [bannerId, setBannerId] = useState<string>('');
  const [bannerIndex, setBannerIndex] = useState<number>(0);

  useEffect(() => {
    dispatch(getSiteSettings());
  }, []);

  useEffect(() => {
    if (!_.isEmpty(payload)) {
      const bannerVideos = document.querySelectorAll(`video.banner-video`);
      let currentIndex = bannerIndex;
      if (!contentUnLoaded) {
        const firstElement = _.first(payload);
        const id = _.get(firstElement, 'id');
        if (_.isEmpty(bannerId)) setBannerId(_.toString(id));
      }
      const intervalId = setInterval(() => {
        if (currentIndex === payload.length - 1) currentIndex = 0;
        else currentIndex++;

        _.forEach(bannerVideos, (el: any) => {
          const isCurrentActive = el.classList.contains(`banner-video-${currentIndex}`);
          if (!isCurrentActive) {
            el.currentTime = 0;
            el.pause();
          }
        });

        const findActiveVideo = document.querySelector(`.banner-video-${currentIndex}`) as HTMLVideoElement;
        if (findActiveVideo) findActiveVideo.play();

        const nextBanner = payload[currentIndex];
        if (!_.isEmpty(nextBanner)) {
          const nextBannerId = _.get(nextBanner, 'id');
          setBannerId(_.toString(nextBannerId));
        }
      }, 6000);

      return () => clearInterval(intervalId);
    }
  }, [bannerIndex, payload, contentUnLoaded]);

  const handleChangeIndex = (index: string) => {
    const bannerVideos = document.querySelectorAll(`video.banner-video`);
    _.forEach(bannerVideos, (el: any) => {
      const isCurrentActive = el.classList.contains(`banner-video-${index}`);
      if (!isCurrentActive) {
        el.currentTime = 0;
        el.pause();
      } else {
        el.currentTime = 0;
        el.play();
      }
    });
    const nextBanner = payload[index];
    if (!_.isEmpty(nextBanner)) {
      const nextBannerId = _.get(nextBanner, 'id');
      setBannerId(_.toString(nextBannerId));
    }
    setBannerIndex(_.parseInt(index));
  };

  return (
    <SliderContainer>
      <BackgroundImage>
        {!_.isEmpty(payload) &&
          _.map(payload, (item, index) => {
            const { heroMedia, id } = item;
            const activeClass = bannerId === id ? 'active' : '';
            const { link, type } = heroMedia;
            if (type === 'image')
              return (
                <ImageItem key={`background-${index}`} className={activeClass}>
                  <LazyLoadImage width="100%" height="100%" alt={`alt-banner-${id}`} src={link} />
                </ImageItem>
              );
            return (
              <ImageItem key={`background-${index}`} className={activeClass}>
                <video
                  className={`banner-video banner-video-${index}`}
                  width="100%"
                  height="100%"
                  autoPlay
                  loop
                  muted
                  playsInline
                >
                  <source src={link} />
                </video>
              </ImageItem>
            );
          })}
      </BackgroundImage>
      <ContentWrapper>
        <Content>
          <ContentSlider>
            {_.map(payload, (item, index) => {
              const { title, id, linkSection, sectionColor } = item;
              const activeClass = bannerId === id ? 'show' : 'hide';
              return (
                <ContentItem color={sectionColor} key={`content-${index}`} className={activeClass}>
                  <Title className="title">{_.get(title, lang)}</Title>
                  {/* <Link
                    aria-label={_.get(title, lang)}
                    to={linkSection}
                    target="_blank"
                    ref={linkRef}
                    rel="noopener noreferrer"
                  /> */}
                  {linkSection && (
                    <Link
                      className="subtitle"
                      to={{
                        pathname: linkSection,
                        state: {
                          from: ROUTES.USER.HOMEPAGE,
                          query: '',
                        },
                      }}
                    >
                      <span>{t('label.learnMore')}</span>
                      <i className="fas fa-arrow-right"></i>
                    </Link>
                  )}
                  <SliderBar>
                    {_.map(payload, (item, index) => {
                      const { id } = item;
                      const activeClass = bannerId === id ? 'active' : '';
                      return (
                        <BarItem
                          key={`bar-${index}`}
                          className={`${activeClass}`}
                          onClick={() => {
                            if (bannerId !== id) handleChangeIndex(index);
                          }}
                        >
                          <span />
                        </BarItem>
                      );
                    })}
                  </SliderBar>
                </ContentItem>
              );
            })}
          </ContentSlider>
        </Content>
      </ContentWrapper>
    </SliderContainer>
  );
};

export default SolutionCard;
