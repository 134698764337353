/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Content, FilterNews, NewsCard, UserPagination } from '../../../components/Common';
import { DefaultUserLayout } from '../../../components/Layout/DefaultLayout';
import { NewsContainer, ContentCol, SVGImage } from './News.styles';
import SkeletonLoading from './SkeletonLoading';
import { FILTERS, SECOND_HEADER } from '../../../constants/Constants';
import SecondHeader from '../../../components/Layout/CommonLayoutPart/SecondHeader';
import history from '../../../history';
import { ArticlesActions } from '../../../actions';
import { ROUTES } from '../../../constants';
import { Images } from '../../../themes';
import { Utils } from '../../../libs';
import { useLocation } from 'react-router-dom';

// Declare actions
const { filterArticles } = ArticlesActions;
// Declare constants
const defaultFilter = { features: 'news', categories: 'developmentNews', topic: '' };

const DevelopmentNews = () => {
  // Declare translation
  const { t } = useTranslation('user');
  const location = useLocation();
  const dispatch = useDispatch();
  const isRequestLoading = useSelector(state => _.get(state, 'Articles.isLoading'));
  const newsPayload = useSelector(state => _.get(state, 'Articles.payload'));
  const meta = useSelector(state => _.get(state, 'Articles.meta'));
  const pagination = useSelector(state => _.get(state, 'Articles.userMetaPagination'));
  const language = useSelector(state => _.get(state, 'PageSettings.userLang'));
  const [isNoneFilter, setIsNoneFilter] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentFilter, setCurrentFilter] = useState<any>({ features: 'news', categories: 'developmentNews' });
  const [firstLoad, setFirstLoad] = useState<boolean>(false);

  const getSearchLocation = async () => {
    const search = location.search;
    const params = new URLSearchParams(search);
    const features = params.get('features');
    const topic = params.get('topic');
    const resolveFilter = { ...currentFilter };
    if (!features) {
      history.push(`${ROUTES.USER.DEVELOPMENT_NEWS}?features=news`);
      _.assign(resolveFilter, { features: 'news' });
    }
    if (features) _.assign(resolveFilter, { features });
    if (topic) _.assign(resolveFilter, { topic });
    setCurrentFilter({ ...resolveFilter });
    setFirstLoad(true);
  };

  useEffect(() => {
    getSearchLocation();
  }, []);

  useEffect(() => {
    if (isRequestLoading) {
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
        Utils.scrollToBottomOfBanner();
      }, 1500);
    }
  }, [isRequestLoading]);

  useEffect(() => {
    if (firstLoad) dispatch(filterArticles({ ...pagination, ...currentFilter, language, type: 'user' }));
    const currentCategory = _.get(currentFilter, 'features');
    if (_.includes(['insights', 'successStories'], currentCategory)) setIsNoneFilter(true);
    else setIsNoneFilter(false);
  }, [currentFilter, language]);

  const onViewDetails = (id: string) => {
    document.documentElement.style.scrollBehavior = 'unset';
    const search = location.search;
    history.push({
      pathname: `${ROUTES.USER.DEVELOPMENT_NEWS}/${id}`,
      state: { from: ROUTES.USER.DEVELOPMENT_NEWS, query: search },
    });
  };

  const onChangeFilter = async (newFilter: any, key: string) => {
    if (key === 'sub_header') {
      const currentCategory = _.get(currentFilter, 'features');
      if (newFilter !== currentCategory) {
        const resolveFilter = {
          ...currentFilter,
          features: newFilter,
        };
        const searchQuery = '?';
        const query: any[] = [];
        _.map(resolveFilter, (item, key) => {
          if (!_.isEmpty(item)) query.push(`${key}=${item}`);
        });
        history.push({ pathname: ROUTES.USER.DEVELOPMENT_NEWS, search: searchQuery.concat(_.join(query, '&')) });
        Utils.scrollToBottomOfBanner();

        if (_.includes(['insights', 'successStories'], currentCategory)) _.assign(resolveFilter, { topic: '' });
        setCurrentFilter(resolveFilter);
      }
    } else {
      const searchQuery = '?';
      const query: any[] = [];
      _.map(newFilter, (item, key) => {
        if (!_.isEmpty(item) && key !== 'categories') query.push(`${key}=${item}`);
      });
      history.push({ pathname: `${ROUTES.USER.DEVELOPMENT_NEWS}`, search: searchQuery.concat(_.join(query, '&')) });
      setCurrentFilter(newFilter);
    }
  };

  // Render filter column
  const renderFilterColumn = () => {
    const currentFeatures = _.get(currentFilter, 'features');
    if (_.includes(['news', 'publications'], currentFeatures))
      return (
        <FilterNews
          data={FILTERS.DEVELOPMENT_NEWS}
          activeFilter={currentFilter}
          onChange={(value: any) => onChangeFilter(value, 'filter')}
          onClick={() => onChangeFilter(defaultFilter, 'filter')}
        />
      );
    return null;
  };

  // Render content right column
  const renderContentColumn = () => {
    return (
      <ContentCol marginLeft={isNoneFilter ? 0 : 120}>
        {!_.isEmpty(newsPayload) ? (
          _.map(newsPayload, (item, index) => {
            return (
              <NewsCard
                key={`news-${index}`}
                data={item}
                onClick={(location: string) => onViewDetails(location)}
                pathname={ROUTES.USER.DEVELOPMENT_NEWS}
                searchQuery={location.search}
              />
            );
          })
        ) : (
          <SVGImage src={Images.noResultFound.default} alt="no result found illustrators" />
        )}
        {!_.isEmpty(newsPayload) && (
          <div className="mt-2">
            <UserPagination
              currentPage={_.get(meta, 'currentPage')}
              totalPages={_.get(meta, 'totalPages')}
              onClick={(currentPage: number) => setCurrentFilter({ ...currentFilter, currentPage })}
            />
          </div>
        )}
      </ContentCol>
    );
  };

  const renderMain = () => {
    return (
      <NewsContainer>
        <SecondHeader
          data={SECOND_HEADER.NEWS}
          active={_.get(currentFilter, 'features')}
          onChange={(value: string) => onChangeFilter(value, 'sub_header')}
        />
        <Content className="mt-8 news">
          {renderFilterColumn()}
          {isRequestLoading || isLoading ? (
            <SkeletonLoading marginLeft={isNoneFilter ? 0 : 120} />
          ) : (
            renderContentColumn()
          )}
        </Content>
      </NewsContainer>
    );
  };

  return (
    <DefaultUserLayout
      content={renderMain()}
      currentPage={ROUTES.USER.DEVELOPMENT_NEWS}
      titlePage={t('titlePage.developmentNews')}
      headerTitle="Development News"
      metaDescription="Discover more about our latest news"
    />
  );
};

export default DevelopmentNews;
