const API = {
  AUTHTHENTICATION: {
    BASIC: "authentication",
  },
  DASHBOARD: {
    BASIC: "dashboard",
  },
  LOG_ACTIVITY: {
    BASIC: "logs-activity",
  },
  LOGS: {
    BASIC: "logs",
  },
  SITE_SETTINGS: {
    BASIC: "site-settings",
  },
  ACCOUNT: {
    UPDATE_PASSWORD: "user/changePassword",
  },
  CAREER: {
    BASIC: "career",
    FILTER: "career/filter",
    REMOVE: "career/remove",
    APPLY: "career/apply",
  },
  NEWS: {
    BASIC: "articles",
    FILTER: "articles/filter",
    REMOVE: "articles/remove",
    ORDERED: "articles/ordered",
    UNORDERED: "articles/unordered",
  },
  CLIENT: {
    BASIC: "clients",
    UPDATE_MULTIPLE: "clients/multiple",
    GET_FOR_USER: "clients/user-content",
  },
  ORDER_NEWS: {
    BASIC: "order-news",
    FOR_ORDER: "order-news/for-order",
  },
  MEDIA: {
    BASIC: "media",
    VIDEO: "media/video",
  },
  SEARCH: {
    BASIC: "search",
  },
  CONTACT: {
    BASIC: "contact",
  },
  TEAM_MEMBERS: {
    BASIC: "team-members",
    REMOVE_MULTIPLE: "team-members/remove",
    GET_FOR_USER: "team-members/user-content",
  },
  SOLUTIONS: {
    BASIC: "team-members",
    REMOVE_MULTIPLE: "team-members/remove",
  },
  CONTENT_EDITABLE: {
    FILTER: "content-editable/filter",
    IDRA_PAGES: "content-editable/idra-pages",
    WHO_WE_ARE: "content-editable/who-we-are",
    CONTACT: "content-editable/contact",
    CAREER: "content-editable/career",
    SERVICES_SOLUTIONS: "content-editable/services-solutions",
    HOMEPAGE: "content-editable/homepage",
    GET_FOR_USER: "content-editable/user-content",
    ALUMNI_NETWORK: "content-editable/alumni-content",
  },
  ALUMNI_NETWORK: {
    BASIC: "alumni-network",
    DENY: "alumni-network/deny",
    APPROVE: "alumni-network/approve",
  },
};

export default API;
