/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import _ from "lodash";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import history from "../../../history";
import { Icons } from "../../../themes";
import { useTranslation } from "react-i18next";
import Aos from "aos";

import { Utils } from "../../../libs";
import {
  FlexVertical,
  FlexHorizontal,
  OurlineGrowButton,
  Content,
  RegularParagraph,
  ClientsIndustries,
  BlackRegularParagraph,
  GrannyRegularParagraph,
  GridLayout,
  GridIDRAPage,
  OurTeamPagination,
  MemberCard,
} from "../../../components/Common";
import { DefaultUserLayout } from "../../../components/Layout/DefaultLayout";
import {
  WhoWeAreContainer,
  OurValueBox,
  // MemberCard,
  PartOfContent,
  Image,
  ButtonBar,
  Button,
  Label,
  TimelineForm,
  TimelineItem,
  TimelineLabel,
  AboutUsContent,
  BoxArrow,
  ArrowLabel,
  PositionForm,
  BoxAnimation,
} from "./WhoWeAre.styles";
import SecondHeader from "../../../components/Layout/CommonLayoutPart/SecondHeader";
import {
  SECOND_HEADER,
  CLIENTS_INDUSTRY_HEADER,
} from "../../../constants/Constants";
import { ROUTES, FRAME_MOTIONS, DropdownOptions } from "../../../constants";

const WhoWeAre = () => {
  // Declare translation
  const { t } = useTranslation("user");
  // Declare reducers
  const clientPayload = useSelector((state) =>
    _.get(state, `Clients.payload.${ROUTES.USER.WHO_WE_ARE}`)
  );
  const isRequestLoading = useSelector((state) =>
    _.get(state, "Clients.isLoading")
  );
  const membersPayload = useSelector((state) =>
    _.get(state, "Members.payload")
  );
  const isRequestTeamMebers = useSelector((state) =>
    _.get(state, "Members.isLoading")
  );
  const lang = useSelector((state) => _.get(state, "PageSettings.userLang"));
  const payload = useSelector((state) =>
    _.get(state, `EditContent.payload.${ROUTES.USER.WHO_WE_ARE}`)
  );
  // Declare location
  const location = useLocation();
  const queryStringValue = queryString.parse(location.search);
  // Declare states
  const [category, setCurrentCategory] = useState<string>("aboutUs");
  const [currentType, setCurrentType] = useState<string>(
    "internationalOrganizationsOrInstitutions"
  );
  const [clientTab, setClientTab] = useState<string>("management");
  const [isRightClick, setIsRightClick] = useState<boolean>(false);
  const [isLeftClick, setIsLeftClick] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [strokeDashOffset, setStrokeDashOffset] = useState<number>(4684);
  const [isHideArrow, setIsHideArrow] = useState<boolean>(false);
  const [currentMemberShow, setCurrentMemberShow] = useState<string>("");
  const [contentMemberHeight, setContentMemberHeight] = useState<number>(0);
  // Declare refs
  const containerRef = useRef<HTMLDivElement>(null);
  const pathRef = useRef<SVGPathElement>(null);

  const handleStrokeDashOffset = () => {
    if (window.pageYOffset >= 100 && window.pageYOffset < 200) {
      setIsHideArrow(true);
      setStrokeDashOffset(4650);
    } else if (window.pageYOffset >= 200 && window.pageYOffset < 300)
      setStrokeDashOffset(4580);
    else if (window.pageYOffset >= 300 && window.pageYOffset < 400)
      setStrokeDashOffset(4280);
    else if (window.pageYOffset >= 400 && window.pageYOffset < 500)
      setStrokeDashOffset(3680);
    else if (window.pageYOffset >= 500 && window.pageYOffset < 600)
      setStrokeDashOffset(3580);
    else if (window.pageYOffset >= 600 && window.pageYOffset < 700)
      setStrokeDashOffset(3380);
    else if (window.pageYOffset >= 700 && window.pageYOffset < 800)
      setStrokeDashOffset(2980);
    else if (window.pageYOffset >= 800 && window.pageYOffset < 900)
      setStrokeDashOffset(2580);
    else if (window.pageYOffset >= 1000 && window.pageYOffset < 1100)
      setStrokeDashOffset(2180);
    else if (window.pageYOffset >= 1100 && window.pageYOffset < 1200)
      setStrokeDashOffset(1950);
    else if (window.pageYOffset >= 1200 && window.pageYOffset < 1300)
      setStrokeDashOffset(1600);
    else if (window.pageYOffset >= 1300 && window.pageYOffset < 1400)
      setStrokeDashOffset(1500);
    else if (window.pageYOffset >= 1400 && window.pageYOffset < 1500)
      setStrokeDashOffset(1000);
    else if (window.pageYOffset >= 1500 && window.pageYOffset < 1600)
      setStrokeDashOffset(600);
    else if (window.pageYOffset >= 1600 && window.pageYOffset < 1700)
      setStrokeDashOffset(500);
    else if (window.pageYOffset >= 1700) setStrokeDashOffset(0);
  };

  useEffect(() => {
    const circle = document.getElementById("circle") as HTMLDivElement;
    const circleStyle = circle.style;

    document.addEventListener("mousemove", (e: any) => {
      const isHoverOnMemberCard =
        e?.target?.offsetParent?.classList.contains("member-card");

      window.requestAnimationFrame(() => {
        if (!isHoverOnMemberCard) circleStyle.borderColor = "transparent";
        else circleStyle.borderColor = "white";

        circleStyle.top = `${e.clientY - circle.offsetHeight / 2}px`;
        circleStyle.left = `${e.clientX - circle.offsetWidth / 2}px`;
      });
    });
  }, []);

  useEffect(() => {
    setTimeout(() => {
      const paramCategory = _.get(queryStringValue, "category");
      if (paramCategory) setCurrentCategory(_.toString(paramCategory));
    }, 300);
  }, [queryStringValue]);

  useEffect(() => {
    Aos.init({ duration: 300 });
    setCurrentCategory(category);
    let lastTop = 0;
    const handleLoad = () => {
      handleStrokeDashOffset();
    };
    const handleScroll = (event: any) => {
      if (window.pageYOffset > lastTop) {
        handleStrokeDashOffset();
        lastTop = window.pageYOffset;
      }
    };
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("load", handleLoad);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("load", handleLoad);
    };
  }, []);

  const handleChangeCategory = (value: string) => {
    history.push(`${ROUTES.USER.WHO_WE_ARE}?category=${value}`);
    Utils.scrollToBottomOfBanner();
  };

  const handleChangeTeamType = (direction: string) => {
    Utils.scrollToBottomOfBanner();
    const { MEMBERS_TYPE } = DropdownOptions;
    const findIndex = _.findIndex(MEMBERS_TYPE, { value: clientTab });
    let currentIndex = 0;
    if (direction === "right") {
      if (findIndex === MEMBERS_TYPE.length - 1) currentIndex = 0;
      else currentIndex = findIndex + 1;
      setIsRightClick(true);
    } else {
      if (findIndex === 0) currentIndex = MEMBERS_TYPE.length - 1;
      else currentIndex = findIndex - 1;
      setIsLeftClick(true);
    }

    setClientTab(_.get(MEMBERS_TYPE[currentIndex], "value"));
    setTimeout(() => {
      setIsLeftClick(false);
      setIsRightClick(false);
    }, 1600);
  };

  const renderReason = () => {
    return (
      <Content className="mt-6 mb-8 content" direction="column">
        <BlackRegularParagraph
          dangerouslySetInnerHTML={{
            __html: _.get(payload, `aboutUs.descriptionType.${lang}`),
          }}
        ></BlackRegularParagraph>
        <FlexHorizontal justifyCenter className="mt-5 mb-3">
          <BoxArrow className={`${isHideArrow ? "hide" : ""}`}>
            <ArrowLabel>{t("label.ourStory")}</ArrowLabel>
            <BoxAnimation>
              <img src={Icons.downArrowAboutUs.default} alt="down arrow" />
              <span>{t("label.scroll")}</span>
            </BoxAnimation>
          </BoxArrow>
        </FlexHorizontal>
        <TimelineForm>
          <svg
            width="732"
            height="1456"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="line-desktop"
          >
            <g clipPath="url(#clip0)">
              <path
                id="path"
                ref={pathRef}
                d="M6 6V186H726V491.5H6V797.5H726V1103H6V1408.5H366V1450"
                stroke="#9FB6BC"
                strokeWidth="12"
                strokeDashoffset={strokeDashOffset}
              />
            </g>
            <defs>
              <clipPath id="clip0">
                <rect
                  width="732"
                  height="1456"
                  viewBox="0 0 1 1"
                  fill="#9FB6BC"
                />
              </clipPath>
            </defs>
          </svg>
          <svg
            width="612"
            height="1456"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="line-tablet"
          >
            <g clipPath="url(#clip0)">
              <path
                id="path"
                ref={pathRef}
                d="M6 6v180h600v305.5H6v306h600V1103H6v305.5h300v41.5"
                stroke="#9FB6BC"
                strokeWidth="12"
                strokeDashoffset={strokeDashOffset}
              />
            </g>
            <defs>
              <clipPath id="clip0">
                <rect
                  width="612"
                  height="1456"
                  viewBox="0 0 1 1"
                  fill="#9FB6BC"
                />
              </clipPath>
            </defs>
          </svg>
          <svg
            width={412}
            height={1460}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="line-large-mobile"
          >
            <g clipPath="url(#clip0)">
              <path
                d="M6 6v180.499h400v306.346H6v306.848h400v306.347H6v306.35h200V1454"
                stroke="#9FB6BC"
                strokeWidth={12}
                strokeLinecap="round"
              />
            </g>
            <defs>
              <clipPath id="clip0">
                <rect
                  width="412"
                  height="1673"
                  viewBox="0 0 1 1"
                  fill="#9FB6BC"
                />
              </clipPath>
            </defs>
          </svg>

          <svg
            width={288}
            height={1448}
            // transform="translate3d(0px, 0px, 0px)"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="line-mobile"
          >
            <g clipPath="url(#clip0)">
              <path
                id="path"
                ref={pathRef}
                d="M4 4v178.38h280v304.923H4v304.924h280v304.923H4v306.45h140v40.4"
                stroke="#9FB6BC"
                strokeWidth="12"
                strokeDashoffset={strokeDashOffset}
              />
            </g>
            <defs>
              <clipPath id="clip0">
                <rect
                  width="288"
                  height="1448"
                  viewBox="0 0 1 1"
                  fill="#9FB6BC"
                />
              </clipPath>
            </defs>
          </svg>
          <PositionForm>
            {!_.isEmpty(_.get(payload, "aboutUs.timeline")) &&
              _.map(_.get(payload, "aboutUs.timeline"), (item, index) => {
                const description = _.get(item, `description.${lang}`);
                const year = _.get(item, "year");
                return (
                  <TimelineItem key={`timeline-${index}`}>
                    <TimelineLabel
                      data-aos="fade-up"
                      data-aos-offset="0"
                      data-aos-duration="250"
                      data-aos-easing="ease"
                      data-aos-mirror="false"
                      data-aos-once="true"
                      data-aos-anchor-placement="top-center"
                    >
                      {year}
                    </TimelineLabel>
                    <BlackRegularParagraph
                      data-aos="fade-down"
                      data-aos-offset="-150"
                      data-aos-duration="250"
                      data-aos-easing="ease"
                      data-aos-mirror="false"
                      data-aos-once="true"
                      data-aos-anchor-placement="bottom-center"
                      textAlignJustify
                      dangerouslySetInnerHTML={{ __html: description }}
                      className="mt-3"
                    ></BlackRegularParagraph>
                  </TimelineItem>
                );
              })}
          </PositionForm>
        </TimelineForm>
        <AboutUsContent
          data-aos="fade-down"
          data-aos-offset="-250"
          data-aos-duration="250"
          data-aos-easing="ease"
          data-aos-mirror="false"
          data-aos-once="true"
          data-aos-anchor-placement="bottom-center"
          className="flex-column flex-justify-between"
        >
          <BlackRegularParagraph
            dangerouslySetInnerHTML={{
              __html: _.get(
                payload,
                `aboutUs.currentResult.description.${lang}`
              ),
            }}
          ></BlackRegularParagraph>
          <FlexHorizontal justifySpaceBetween className="ourmission-desktop">
            {_.map(
              _.get(payload, "aboutUs.currentResult.missionAndVision"),
              (item, index) => {
                const title = _.get(item, `title.${lang}`);
                return (
                  <TimelineLabel
                    className="mt-4"
                    key={`description-result-desktop-${index}`}
                  >
                    {title}
                  </TimelineLabel>
                );
              }
            )}
          </FlexHorizontal>
        </AboutUsContent>
        <FlexVertical justifySpaceBetween className="mt-2 ourmission-mobile">
          {_.map(
            _.get(payload, "aboutUs.currentResult.missionAndVision"),
            (item, index) => {
              const title = _.get(item, `title.${lang}`);
              const description = _.get(item, `description.${lang}`);
              const order = _.get(item, `order`);
              return (
                <FlexVertical
                  data-aos="fade-left"
                  data-aos-offset="50"
                  data-aos-duration="250"
                  data-aos-easing="ease"
                  data-aos-mirror="true"
                  data-aos-once="true"
                  data-aos-anchor-placement="bottom-center"
                  key={`current-result-${index}`}
                >
                  <TimelineLabel className="mt-6">{title}</TimelineLabel>
                  <BlackRegularParagraph
                    textAlignRight={order === 2}
                    maxWidth="360px"
                  >
                    {description}
                  </BlackRegularParagraph>
                </FlexVertical>
              );
            }
          )}
        </FlexVertical>
        <FlexHorizontal className="flex-justify-between mt-2 ourmission-desktop">
          {_.map(
            _.get(payload, "aboutUs.currentResult.missionAndVision"),
            (item, index) => {
              const description = _.get(item, `description.${lang}`);
              return (
                <BlackRegularParagraph
                  data-aos="fade-right"
                  data-aos-offset="-250"
                  data-aos-duration="250"
                  data-aos-easing="ease"
                  data-aos-mirror="false"
                  data-aos-once="true"
                  data-aos-anchor-placement="bottom-center"
                  textAlignLeft={_.parseInt(index) === 0}
                  textAlignRight={_.parseInt(index) === 1}
                  maxWidth="360px"
                  key={`description-result-${index}`}
                >
                  {description}
                </BlackRegularParagraph>
              );
            }
          )}
        </FlexHorizontal>
      </Content>
    );
  };

  const renderOurValues = () => {
    return (
      <Content className="mt-6 mb-8 content" direction="column">
        <GridIDRAPage col="2" rowGap="35px" colGap="140px">
          {_.map(_.get(payload, "ourValues"), (item, index) => {
            const icon = _.get(item, "icon");
            const title = _.get(item, `title.${lang}`);
            const description = _.get(item, `description.${lang}`);
            return (
              <OurValueBox key={`our-values-${index}`}>
                <div className="description">
                  <div className="image-value">
                    <Image src={icon} alt="our-values icon" />
                  </div>
                  <h3 className="title">{title}</h3>
                </div>
                <p className="subtitle">{description}</p>
              </OurValueBox>
            );
          })}
        </GridIDRAPage>
      </Content>
    );
  };

  const renderQualityAssurance = () => {
    return (
      <Content className="mt-6 mb-8 content" direction="column">
        <motion.div
          variants={FRAME_MOTIONS.CONTAINER}
          initial="hidden"
          animate="visible"
        >
          <motion.div variants={FRAME_MOTIONS.ITEM_EFFECT}>
            <RegularParagraph
              dangerouslySetInnerHTML={{
                __html: _.get(payload, `qualityAssurance.${lang}`),
              }}
            />
          </motion.div>
        </motion.div>
      </Content>
    );
  };

  const renderMember = () => {
    const memberPayload = _.get(membersPayload, clientTab);
    const sortBy = _.sortBy(memberPayload, ["fullname"]);
    const render = _.map(sortBy, (item) => {
      return (
        // <motion.div  variants={ANIMATION.item}>
        // </motion.div>
        <MemberCard
          key={`member-${_.get(item, "id")}`}
          data={item}
          active={currentMemberShow}
          onActive={(id: string) => setCurrentMemberShow(id)}
          onChangeHeight={(newHeight: number) => {
            if (newHeight > contentMemberHeight)
              setContentMemberHeight(newHeight);
          }}
          height={contentMemberHeight}
        />
      );
    });
    return render;
  };

  const renderOurTeam = () => {
    const { MEMBERS_TYPE } = DropdownOptions;
    const findTeam = _.find(MEMBERS_TYPE, { value: clientTab });
    return (
      <Content className="mb-4 content" direction="column">
        <ButtonBar className="mt-4 mb-6">
          <Button
            className={`mr-1 left ${isLeftClick ? "animate" : ""}`}
            onClick={() => handleChangeTeamType("left")}
          >
            <div className="arrow">
              <i />
            </div>
            <div className="content"></div>
          </Button>
          <Button
            className={`first_load ${isRightClick ? "animate" : ""}`}
            onClick={() => handleChangeTeamType("right")}
          >
            <div className="arrow">
              <i />
            </div>
            <div className="content"></div>
          </Button>
          <Label>{t(`label.${_.get(findTeam, "label")}`)}</Label>
        </ButtonBar>
        <GridIDRAPage
          mimax="310px"
          className="pb-2"
          rowGap="50px"
          colGap="35px"
        >
          {!_.isEmpty(_.get(membersPayload, clientTab)) ? (
            renderMember()
          ) : (
            <h3>{t("label.noResultFound")}</h3>
          )}
        </GridIDRAPage>
        <OurTeamPagination
          active={clientTab}
          onChange={(tab: string) => {
            Utils.scrollToBottomOfBanner();
            setTimeout(() => setClientTab(tab), 500);
          }}
        />
      </Content>
    );
  };

  const renderOutPartner = () => {
    return (
      <Content className="mt-6 mb-8 content" direction="column">
        <motion.div
          variants={FRAME_MOTIONS.CONTAINER}
          initial="hidden"
          animate="visible"
        >
          <GridLayout
            col="2"
            colGap="100px"
            rowGap="20px"
            className="pb-2 custom-ourpartners"
          >
            {_.map(_.get(payload, "ourPartners"), (item, index) => {
              const logo = _.get(item, "logo");
              const link = _.get(item, "link");
              const description = _.get(item, `description.${lang}`);
              return (
                <motion.div
                  variants={FRAME_MOTIONS.ITEM_EFFECT}
                  key={`partners-${index}`}
                >
                  <PartOfContent>
                    <div className="logo">
                      <img src={logo} alt="pertner logo" />
                    </div>
                    <GrannyRegularParagraph
                      dangerouslySetInnerHTML={{ __html: description }}
                    ></GrannyRegularParagraph>
                    <OurlineGrowButton
                      className="mt-2"
                      label="visitWebsite"
                      isLink
                      url={link}
                    />
                  </PartOfContent>
                </motion.div>
              );
            })}
          </GridLayout>
        </motion.div>
      </Content>
    );
  };

  const renderClient = () => {
    return (
      <Content minHeight="600px" className="pt-4 pb-4" direction="column">
        <ClientsIndustries
          header={CLIENTS_INDUSTRY_HEADER.WHO_WE_ARE}
          data={_.get(clientPayload, currentType) || []}
          currentType={currentType}
          onChange={(value: string) => setCurrentType(value)}
          currentPage={ROUTES.USER.WHO_WE_ARE}
          onLoaded={() => setIsLoading(false)}
        />
      </Content>
    );
  };

  const renderMain = () => {
    return (
      <WhoWeAreContainer ref={containerRef}>
        <SecondHeader
          active={category}
          data={SECOND_HEADER.WHO_WE_ARE}
          onChange={(value: string) => handleChangeCategory(value)}
        />
        {category === "aboutUs" && renderReason()}
        {category === "ourValues" && renderOurValues()}
        {category === "qualityAssurance" && renderQualityAssurance()}
        {category === "ourTeam" && renderOurTeam()}
        {category === "ourPartners" && renderOutPartner()}
        {category === "ourClients" && renderClient()}
        <div id="circle"></div>
      </WhoWeAreContainer>
    );
  };

  return (
    <DefaultUserLayout
      content={renderMain()}
      currentPage={ROUTES.USER.WHO_WE_ARE}
      loading={isLoading || isRequestLoading || isRequestTeamMebers}
      headerTitle="Who we are"
      metaDescription="IDRA Research & Consulting is a leading Albanian company which provides Insights into why things happen"
    />
  );
};

export default WhoWeAre;
