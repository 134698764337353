/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { SecondHeader } from "../../../../components/Layout/CommonLayoutPart";
import { ROUTES } from "../../../../constants";
import { SECOND_HEADER } from "../../../../constants/Constants";
import {
  EditServicesSolutionsContainer,
  SolutionCategory,
  SolutionSubCategory,
  SolutionContent,
  GifImage,
  HeadingWrapper,
  AddMoreContent,
  RemoveButton,
} from "./EditServicesSolutions.styles";
import { Icons } from "../../../../themes";
import { EditContentActions, PageSettingsActions } from "../../../../actions";
import {
  FlexHorizontal,
  FlexVertical,
  Richtextbox,
} from "../../../../components/Common";
import { statusAlert } from "../../../../libs";
import { useTranslation } from "react-i18next";

interface SectionProps {
  className?: string;
}

const { getAllContentForUser, setEditContentPayload } = EditContentActions;
const { setStatusEdited, setContentEdited } = PageSettingsActions;

const EditServicesSolutions = (props: SectionProps) => {
  // Declare translation
  const { t } = useTranslation("admin");
  // Declare props
  const { className } = props;
  const dispatch = useDispatch();
  const editingPage = useSelector((state) =>
    _.get(state, "EditContent.pageEditing")
  );
  const lang = useSelector((state) => _.get(state, "EditContent.langEditing"));
  const payload = useSelector((state) =>
    _.get(state, `EditContent.payload.${editingPage}`)
  );
  const contentPayload = useSelector((state) =>
    _.get(state, "EditContent.payload")
  );
  const isEditing = useSelector((state) =>
    _.get(state, "PageSettings.isEdited")
  );
  const initialValue = useSelector((state) =>
    _.get(state, "PageSettings.contentEdited")
  );
  const [secondHeaderType, setSecondHeaderType] = useState<string>("ourValues");
  const [secondHeaderData, setSecondHeaderData] = useState<any>({});

  useEffect(() => {
    return () => {
      dispatch(setStatusEdited(false));
    };
  }, []);

  useEffect(() => {
    dispatch(getAllContentForUser());
    let data: any[] = [];
    if (editingPage === ROUTES.USER.RESEARCH_SOLUTIONS)
      data = SECOND_HEADER.RESEARCH_SOLUTIONS;
    if (editingPage === ROUTES.USER.RESEARCH_SERVICES)
      data = SECOND_HEADER.RESEARCH_SERVICES;
    if (editingPage === ROUTES.USER.DEVELOPMENT_SOLUTIONS)
      data = SECOND_HEADER.DEVELOPMENT_SOLUTIONS;
    if (data) {
      const firstData = _.first(data);
      setSecondHeaderType(_.get(firstData, "value"));
    }
    setSecondHeaderData(data);
  }, [editingPage]);

  const handleChangeContentEditable = (
    value: any,
    row: number,
    defaultValue?: string
  ) => {
    const isEdited = !_.isEqual(defaultValue, initialValue);
    if (!isEditing && isEdited) dispatch(setStatusEdited(true));
    const getContent = _.get(payload, secondHeaderType);
    getContent[row] = value;
    const newContent = {
      ...payload,
      [secondHeaderType]: getContent,
    };
    dispatch(
      setEditContentPayload({ ...contentPayload, [editingPage]: newContent })
    );
  };

  const handleAddMore = () => {
    const newContent = {
      title: {
        en: "",
        al: "",
      },
      subtitle: {
        en: "",
        al: "",
      },
      description: {
        en: "",
        al: "",
      },
      details: {
        en: "",
        al: "",
      },
    };
    const resolveContent = {
      ...payload,
      [secondHeaderType]: [...payload[secondHeaderType], newContent],
    };
    dispatch(
      setEditContentPayload({
        ...contentPayload,
        [editingPage]: resolveContent,
      })
    );
  };

  const handleRemoveServicesSolutions = async (index: number) => {
    if (_.isNumber(index)) {
      const getContent = _.get(payload, secondHeaderType);
      _.pullAt(getContent, [index]);
      const isAgree = await statusAlert(
        "warning",
        t("alert.warningRemoveServicesSolutions")
      );
      if (isAgree) {
        const resolveContent = {
          ...payload,
          [secondHeaderType]: getContent,
        };

        dispatch(
          setEditContentPayload({
            ...contentPayload,
            [editingPage]: resolveContent,
          })
        );
      }
    }
  };

  const renderContentResearchServicesSolutions = () => {
    const getContent = _.get(payload, secondHeaderType);
    const render = _.map(getContent, (item, index) => {
      const { title, subtitle, description, details } = item;
      const paddingClass = title ? "0" : "30px 0";
      return (
        <FlexVertical
          position="relative"
          key={`content-${index}-en`}
          className="mb-2 services-content"
        >
          <RemoveButton
            onClick={() => handleRemoveServicesSolutions(_.toNumber(index))}
          >
            <i className="fas fa-times" />
          </RemoveButton>
          <HeadingWrapper style={{ padding: paddingClass }}>
            <SolutionCategory
              className="editable"
              key={`title-${index}-${lang}`}
              spellCheck={false}
              contentEditable="true"
              suppressContentEditableWarning={true}
              data-placeholder={t("placeholder.typeTheTitleHere")}
              onFocus={(e: any) =>
                dispatch(setContentEdited(_.trim(e.currentTarget.innerText)))
              }
              onBlur={(e: any) =>
                handleChangeContentEditable(
                  {
                    ...item,
                    title: {
                      ...item.title,
                      [lang]: e.currentTarget.innerText,
                    },
                  },
                  _.toNumber(index),
                  _.trim(e.currentTarget.innerText)
                )
              }
            >
              {_.get(title, lang)}
            </SolutionCategory>
            <SolutionSubCategory
              className="editable"
              data-placeholder={t("placeholder.typeTheTitleHere")}
              key={`subtitle-${index}-${lang}`}
              spellCheck={false}
              contentEditable="true"
              suppressContentEditableWarning={true}
              onBlur={(e: any) =>
                handleChangeContentEditable(
                  {
                    ...item,
                    subtitle: {
                      ...item.subtitle,
                      [lang]: e.currentTarget.innerText,
                    },
                  },
                  _.toNumber(index),
                  _.trim(e.currentTarget.innerText)
                )
              }
            >
              {_.get(subtitle, lang)}
            </SolutionSubCategory>
          </HeadingWrapper>

          <SolutionContent>
            <Richtextbox
              onFocus={(value: any) => dispatch(setContentEdited(value))}
              onChange={(value: any) =>
                handleChangeContentEditable(
                  {
                    ...item,
                    description: {
                      ...item.description,
                      [lang]: value,
                    },
                  },
                  _.toNumber(index),
                  _.trim(value)
                )
              }
              data={_.get(description, lang) || ""}
              toolbar={[
                "bold",
                "link",
                "|",
                "numberedList",
                "bulletedList",
                "|",
                "undo",
                "redo",
              ]}
              placeholder={t("placeholder.typeTheDescriptionHere")}
              className="description"
            />
            {editingPage !== ROUTES.USER.DEVELOPMENT_SOLUTIONS && (
              <FlexHorizontal className="mt-3">
                <GifImage>
                  <img src={Icons.doubleRight.default} alt="double right gif" />
                </GifImage>
                <FlexVertical flex="1">
                  <Richtextbox
                    onFocus={(value: any) =>
                      dispatch(setContentEdited(_.trim(value)))
                    }
                    onChange={(value: any) =>
                      handleChangeContentEditable(
                        {
                          ...item,
                          details: {
                            ...item.details,
                            [lang]: value,
                          },
                        },
                        _.toNumber(index)
                      )
                    }
                    data={
                      _.get(details, lang) ||
                      "Click here to edit YOU CAN content"
                    }
                    className="normal-paragraph"
                    fontSize="16px"
                  />
                </FlexVertical>
              </FlexHorizontal>
            )}
          </SolutionContent>
        </FlexVertical>
      );
    });
    return (
      <FlexVertical>
        {render}
        <FlexHorizontal className="mt-2">
          <AddMoreContent onClick={() => handleAddMore()}>
            Add more
          </AddMoreContent>
        </FlexHorizontal>
      </FlexVertical>
    );
  };

  return (
    <EditServicesSolutionsContainer className={className}>
      <SecondHeader
        adminPosition
        active={secondHeaderType}
        onChange={(value: string) => setSecondHeaderType(value)}
        data={secondHeaderData}
      />
      {renderContentResearchServicesSolutions()}
      {/* {(editingPage === ROUTES.USER.RESEARCH_SOLUTIONS || editingPage === ROUTES.USER.RESEARCH_SERVICES) &&
        renderContentResearchServicesSolutions()}
      {editingPage === ROUTES.USER.DEVELOPMENT_SOLUTIONS && renderContentDevelopmentSolutions()} */}
    </EditServicesSolutionsContainer>
  );
};

export default EditServicesSolutions;
