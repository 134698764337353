/* eslint import/no-anonymous-default-export: off */
import { ActionTypes } from '../constants';
import API from '../api';
import { statusAlert } from '../libs';

// SINGLE ACTIONS
const setMediaFilter = (payload: any) => {
  return {
    type: ActionTypes.SET_MEDIA_FILTER,
    payload,
  };
};
const clearMediaFilter = () => {
  return {
    type: ActionTypes.CLEAR_MEDIA_FILTER,
  };
};
const setMediaPayload = (payload: any) => {
  return {
    type: ActionTypes.SET_MEDIA_PAYLOAD,
    payload,
  };
};
const clearMediaPayload = () => {
  return {
    type: ActionTypes.CLEAR_MEDIA_PAYLOAD,
  };
};

// ASYNC ACTIONS
const getMediaByTypeFailure = () => {
  return {
    type: ActionTypes.GET_MEDIA_BY_TYPE_FAILURE,
  };
};

const getMediaByTypeIsLoading = () => {
  return {
    type: ActionTypes.GET_MEDIA_BY_TYPE_REQUEST,
  };
};

const getMediaByTypeSuccess = (payload: any) => {
  return {
    type: ActionTypes.GET_MEDIA_BY_TYPE_SUCCESS,
    payload,
  };
};

const getMediaByType = (data: any) => {
  return async (dispatch: any) => {
    dispatch(getMediaByTypeIsLoading());
    await API.getMediaByType(data).then(async (res: any) => {
      const { status, payload } = res;
      if (status) dispatch(getMediaByTypeSuccess(payload));
      else dispatch(getMediaByTypeFailure());
    });
  };
};

const uploadMediaFailure = () => {
  return {
    type: ActionTypes.UPLOAD_MEDIA_FAILURE,
  };
};

const uploadMediaIsLoading = () => {
  return {
    type: ActionTypes.UPLOAD_MEDIA_REQUEST,
  };
};

const uploadMediaSuccess = () => {
  return {
    type: ActionTypes.UPLOAD_MEDIA_SUCCESS,
  };
};

const uploadMedia = (data: any) => {
  return async (dispatch: any) => {
    dispatch(uploadMediaIsLoading());
    await API.uploadMedia(data).then(async (res: any) => {
      const { status, message } = res;
      if (status) {
        dispatch(uploadMediaSuccess());
        statusAlert('success', message);
      } else {
        dispatch(uploadMediaFailure());
        statusAlert('error', message);
      }
    });
  };
};

const deleteMediaFailure = () => {
  return {
    type: ActionTypes.REMOVE_MEDIA_FAILURE,
  };
};

const deleteMediaIsLoading = () => {
  return {
    type: ActionTypes.REMOVE_MEDIA_REQUEST,
  };
};

const deleteMediaSuccess = () => {
  return {
    type: ActionTypes.REMOVE_MEDIA_SUCCESS,
  };
};

const deleteMedia = (mediaKey: string) => {
  return async (dispatch: any) => {
    dispatch(deleteMediaIsLoading());
    await API.deleteMedia(mediaKey).then(async (res: any) => {
      const { status, message } = res;
      if (status) {
        dispatch(deleteMediaSuccess());
        statusAlert('success', message);
      } else {
        dispatch(deleteMediaFailure());
        statusAlert('error', message);
      }
    });
  };
};

const getVideosFailure = () => {
  return {
    type: ActionTypes.GET_VIDEOS_FAILURE,
  };
};

const getVideosIsLoading = () => {
  return {
    type: ActionTypes.GET_VIDEOS_REQUEST,
  };
};

const getVideosSuccess = (payload: any) => {
  return {
    type: ActionTypes.GET_VIDEOS_SUCCESS,
    payload
  };
};

const getVideos = (data?: any) => {
  return async (dispatch: any) => {
    dispatch(getVideosIsLoading());
    await API.getVideos(data).then(async (res: any) => {
      const { status, message, payload } = res;
      if (status) {
        dispatch(getVideosSuccess(payload));
      } else {
        dispatch(getVideosFailure());
        statusAlert('error', message);
      }
    });
  };
};


const singleActions = {
  setMediaFilter,
  clearMediaFilter,
  setMediaPayload,
  clearMediaPayload,
};

const asyncActions = {
  uploadMedia,
  getMediaByType,
  deleteMedia,
  getVideos,
};

export default { ...singleActions, ...asyncActions };
