import React, { useEffect, useRef } from "react";
import _ from "lodash";
import {
  NewsHomepageCard,
  DefaultNewsCard,
  NewsButton,
  OutputImage,
  OutputNewsImage,
} from "./NewsCard.styles";
import { Icons } from "../../../themes";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { GenerateValue } from "../../../libs";
import { Link } from "react-router-dom";
import { ROUTES } from "../../../constants";

interface LanguageStructure {
  en: string;
  al: string;
}

interface CroppedData {
  cropped: {
    x: number;
    y: number;
  };
  zoomed: number;
  croppedAreaData: {
    x: number;
    y: number;
    width: number;
    height: number;
  };
}

interface DataStructure {
  id: string;
  title: LanguageStructure;
  description?: LanguageStructure;
  thumbnail?: string;
  createdAt: string;
  croppedData?: CroppedData;
  categories: string;
  slug?: string;
}
interface SectionProps {
  data: DataStructure;
  onClick?(value: string): unknown;
  isHomepage?: boolean;
  pathname?: string;
  searchQuery?: string;
}

const { convertTime } = GenerateValue;

const CATEGORY_LINK = {
  developmentNews: "/development-news",
  researchNews: "/research-news",
  general: "/news",
};

const NewsCard: React.FC<SectionProps> = (props: SectionProps) => {
  const { t } = useTranslation("user");
  const locale = useSelector((state) => _.get(state, "PageSettings.userLang"));
  const { onClick, isHomepage, data, pathname, searchQuery } = props;
  const {
    id,
    slug,
    title,
    createdAt,
    thumbnail,
    description,
    croppedData,
    categories,
  } = data;

  const titleRef = useRef<HTMLAnchorElement>(null);
  const dateTimeRef = useRef<HTMLSpanElement>(null);
  const descriptionRef = useRef<HTMLParagraphElement>(null);
  const buttonRef = useRef<HTMLDivElement>(null);
  const imageRef = useRef<HTMLDivElement>(null);

  const resizeWindow = () => {
    if (
      imageRef.current &&
      titleRef.current &&
      dateTimeRef.current &&
      buttonRef.current &&
      descriptionRef &&
      descriptionRef.current
    ) {
      const imageHeight = imageRef.current?.clientHeight;
      let inforHeight =
        titleRef.current?.clientHeight +
        dateTimeRef.current?.clientHeight +
        buttonRef.current?.clientHeight;
      const lineClamp = _.round((imageHeight - inforHeight - 80) / 23);
      descriptionRef.current.style.webkitLineClamp = `${
        lineClamp < 1 ? 1 : lineClamp
      }`;
    }
  };

  useEffect(() => {
    resizeWindow();
    window.addEventListener("resize", resizeWindow);

    return () => {
      window.removeEventListener("resize", resizeWindow);
    };
  }, []);

  const handleLimitWords = (text: string) => {
    const maxLength = 150;
    const trimmedString = text.substr(0, maxLength);
    const result = trimmedString.substr(
      0,
      Math.min(trimmedString.length, trimmedString.lastIndexOf(" "))
    );
    if (text.length > maxLength) return result + "...";
    return result;
  };

  const renderNewsImage = () => {
    const croppedArea = _.get(croppedData, "croppedAreaData");
    if (!croppedArea) return <img src={thumbnail} alt="" />;
    else {
      const scale = 100 / croppedArea.width;
      const transform = {
        x: `${-croppedArea.x * scale}%`,
        y: `${-croppedArea.y * scale}%`,
        scale,
        width: "calc(100% + 0.5px)",
        height: "auto",
      };

      const imageStyle = {
        transform: `translate3d(${transform.x}, ${transform.y}, 0) scale3d(${transform.scale},${transform.scale},1)`,
        width: transform.width,
        height: transform.height,
      };

      return (
        <Link
          to={{
            pathname: `${_.get(CATEGORY_LINK, categories)}/${slug ? slug : id}`,
            state: { from: ROUTES.USER.HOMEPAGE, query: "" },
          }}
        >
          <OutputImage
            onClick={() => onClick && onClick(slug ? slug : id)}
            className="output homepage"
          >
            <img
              src={thumbnail}
              alt="thumbnail"
              style={croppedArea ? imageStyle : {}}
            />
          </OutputImage>
        </Link>
      );
    }
  };

  const renderImagesInNews = () => {
    const croppedArea = _.get(croppedData, "croppedAreaData");
    if (!croppedArea) return <img src={thumbnail} alt="" />;
    else {
      const scale = 100 / croppedArea.width;
      const transform = {
        x: `${-croppedArea.x * scale}%`,
        y: `${-croppedArea.y * scale}%`,
        scale,
        width: "calc(100% + 0.5px)",
        height: "auto",
      };

      const imageStyle = {
        transform: `translate3d(${transform.x}, ${transform.y}, 0) scale3d(${transform.scale},${transform.scale},1)`,
        width: transform.width,
        height: transform.height,
      };
      return (
        <Link
          className="title"
          to={{
            pathname: `${_.get(CATEGORY_LINK, categories)}/${slug ? slug : id}`,
            state: { from: pathname, query: searchQuery },
          }}
        >
          <OutputNewsImage
            ref={imageRef}
            onClick={() => onClick && onClick(slug ? slug : id)}
            className="output homepage"
          >
            <img
              src={thumbnail}
              alt="thumbnail"
              style={croppedArea ? imageStyle : {}}
            />
          </OutputNewsImage>
        </Link>
      );
    }
  };

  if (isHomepage)
    return (
      <NewsHomepageCard onClick={() => onClick && onClick(slug ? slug : id)}>
        <div className="thumbnail">
          {renderNewsImage()}
          <div className="arrow">
            <img src={Icons.whiteRightArrow.default} alt="thumbnail" />
          </div>
        </div>
        <div className="content">
          <Link
            to={{
              pathname: `${_.get(CATEGORY_LINK, categories)}/${
                slug ? slug : id
              }`,
              state: { from: ROUTES.USER.HOMEPAGE, query: "" },
            }}
            className="title"
          >
            {_.get(title, locale)}
          </Link>
          <p>{handleLimitWords(_.get(description, locale))}</p>
        </div>
      </NewsHomepageCard>
    );

  return (
    <DefaultNewsCard>
      {renderImagesInNews()}
      <div className="content flex-column ml-2 news-content">
        <Link
          className="title"
          to={{
            pathname: `${_.get(CATEGORY_LINK, categories)}/${slug ? slug : id}`,
            state: { from: pathname, query: searchQuery },
          }}
          ref={titleRef}
        >
          {_.get(title, locale)}
        </Link>
        <span ref={dateTimeRef}>
          <i className="fas fa-calendar-alt" /> {`${convertTime(createdAt)}`}
        </span>
        <p ref={descriptionRef} className="subtitle">
          {_.get(description, locale)}
        </p>
        <div className="link flex-column">
          <NewsButton
            ref={buttonRef}
            className="mt-2"
            onClick={() => onClick && onClick(slug ? slug : id)}
          >
            <div className="icon_box">
              <i className="fas fa-chevron-right"></i>
            </div>
            <p>{t("button.readMore")}</p>
          </NewsButton>
        </div>
      </div>
    </DefaultNewsCard>
  );
};

export default NewsCard;
