import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import _ from "lodash";
import { COMPANY_ADDRESS } from "../../../../constants/Constants";
import {
  Content,
  WhiteRegularParagraph,
  BlackBoldParagraph,
} from "../../../Common";
import { FlexHorizontal } from "../../../Common";
import { ROUTES } from "../../../../constants";
import {
  FooterContainer,
  FooterTop,
  FooterBottom,
  FooterCol,
  CertificateCircle,
  SocialBox,
  CompanyBox,
  IconFontAwesome,
  FooterContentTop,
  CompanyDetails,
  InformationBox,
} from "./Footer.styles";
import { Images, Icons } from "../../../../themes";
import history from "../../../../history";
import { useLocation } from "react-router-dom";

const Footer: React.FC = () => {
  const { t } = useTranslation("user");
  const { pathname } = useLocation();
  // Declare state
  const footerContent = useSelector((state) =>
    _.get(state, `EditContent.payload.${ROUTES.USER.HOMEPAGE}.footer`)
  );
  const lang = useSelector((state) => _.get(state, "PageSettings.userLang"));

  // Render company address
  const renderCompanyAddress = () => {
    return (
      <>
        {_.map(COMPANY_ADDRESS, (company, index) => {
          const {
            className,
            companyName,
            mail,
            phone,
            isRight,
            isLeft,
            isCenter,
            direction,
          } = company;
          const style = {};
          if (isLeft) _.assign(style, { left: "10px" });
          if (isRight) _.assign(style, { right: "10px" });
          if (isCenter) _.assign(style, { right: "calc(50% - 140px)" });
          return (
            <CompanyBox key={`address-${index}`} className={className}>
              <h3 className={isLeft ? "big-pin-reverse-content" : ""}>
                {companyName}
              </h3>
              <InformationBox className="address" style={style}>
                {mail && (
                  <CompanyDetails>
                    <div className="icons">
                      <img src={Icons.mailContact.default} alt="" />
                    </div>
                    <p>{mail}</p>
                  </CompanyDetails>
                )}
                {phone && (
                  <CompanyDetails>
                    <div className="icons">
                      <img src={Icons.phoneContact.default} alt="" />
                    </div>
                    <p>{phone}</p>
                  </CompanyDetails>
                )}
                <CompanyDetails>
                  <a href={direction} target="_blank" rel="noopener noreferrer">
                    {t("label.getDirections")}
                  </a>
                  <div className="icons">
                    <img src={Icons.directionContact.default} alt="" />
                  </div>
                </CompanyDetails>
              </InformationBox>
            </CompanyBox>
          );
        })}
      </>
    );
  };

  // RENDER CERTIFICATE
  const renderCertificate = () => {
    const certsFooter = _.get(footerContent, "certs");
    const render = _.map(certsFooter, (cert) => {
      const link = _.get(cert, "link");
      return (
        <CertificateCircle
          href={link ? link : "#"}
          target="_blank"
          key={_.get(cert, "label")}
        >
          <span>{_.get(cert, "label")}</span>
          <i className="fas fa-check-circle" />
        </CertificateCircle>
      );
    });
    return <FlexHorizontal justifyFlexEnd>{render}</FlexHorizontal>;
  };

  const renderSocialNetwork = () => {
    return (
      <FlexHorizontal justifyFlexEnd>
        <SocialBox
          href="https://www.facebook.com/idraresearchandconsulting"
          target="_blank"
          className="flex-align-end"
          aria-label="facebook"
        >
          <IconFontAwesome className="fab fa-facebook-f" />
        </SocialBox>
        <SocialBox
          href="https://www.linkedin.com/company/idra-research-&-consulting/"
          target="_blank"
          aria-label="linkedin"
        >
          <IconFontAwesome className="fab fa-linkedin-in" />
        </SocialBox>
        <SocialBox
          href="https://www.instagram.com/idra_company/"
          target="_blank"
          aria-label="instagram"
        >
          <IconFontAwesome className="fab fa-instagram" />
        </SocialBox>
      </FlexHorizontal>
    );
  };

  // RENDER INFORMATION ABOUT COMPANY OF IDRA
  const renderCompanyInformations = () => {
    return (
      <FooterTop>
        <img
          className="footer-image"
          alt="footer-background"
          src={Images.footerBg.default}
        />
        <FooterContentTop>
          {renderCompanyAddress()}
          <Content maxWidth="1920px" className="flex-justify-end">
            <FooterCol width="500px" className="flex-align-end">
              <div
                className="logo_desktop"
                onClick={() => {
                  document.documentElement.style.scrollBehavior = "unset";
                  if (pathname === ROUTES.USER.HOMEPAGE)
                    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                  else history.push(ROUTES.USER.HOMEPAGE);
                }}
              >
                <img
                  src={Images.logoFooter.default}
                  alt="IDRA logo"
                  className="logo"
                />
              </div>
              <div
                onClick={() => {
                  document.documentElement.style.scrollBehavior = "unset";
                  if (pathname === ROUTES.USER.HOMEPAGE)
                    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                  else history.push(ROUTES.USER.HOMEPAGE);
                }}
                className="logo_mobile"
              >
                <img
                  src={Images.logoMobile.default}
                  alt="IDRA logo"
                  className="logo"
                />
              </div>
              <WhiteRegularParagraph textAlignRight className="mt-1 mb-1">
                {_.get(footerContent, lang)}
              </WhiteRegularParagraph>
              <h3 className="mt-1">{t("label.certified")}</h3>
              {renderCertificate()}
              <h3 className="mt-1">{t("label.followUs")}</h3>
              {renderSocialNetwork()}
            </FooterCol>
          </Content>
        </FooterContentTop>
      </FooterTop>
    );
  };

  // RENDER COPYRIGHT, SOCIAL NETWORK AND TERMS
  const renderCopyright = () => {
    return (
      <FooterBottom>
        <Content
          maxWidth="1920px"
          direction="column"
          className="flex-justify-center flex-align-center pt-2 pb-2"
        >
          <FooterCol>
            <BlackBoldParagraph>
              {new Date().getFullYear()} © {t("label.allRightsReserved")}
            </BlackBoldParagraph>
          </FooterCol>
        </Content>
      </FooterBottom>
    );
  };

  return (
    <FooterContainer>
      {renderCompanyInformations()}
      {renderCopyright()}
    </FooterContainer>
  );
};

export default Footer;
