import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { DropdownOptions } from '../../../constants';
import { ColorName } from '../../Variables';

const PaginationContainer = styled.div`
  display: flex;
  margin-top: 15px;
  @media screen and (max-width: 436px) {
    place-content: center;
  }
`;

const PaginationButton = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: ${ColorName.black};
  span,
  i {
    transition: all ease 0.25s;
    padding-bottom: 3px;
    opacity: 0.4;
  }
  i {
    opacity: 1;
    font-size: 14px;
    transform: translateY(2px);
  }
  &.active {
    span {
      opacity: 1;
      border-bottom: 1px solid ${ColorName.black};
    }
  }
  &:hover {
    cursor: pointer;
    span {
      opacity: 1;
    }
  }
`;

interface SectionProps {
  active: string;
  onChange(tab: string): void;
}

const { MEMBERS_TYPE } = DropdownOptions;

const Pagination: React.FC<SectionProps> = (props: SectionProps) => {
  const { active, onChange } = props;
  const [index, setIndex] = useState<number>(0);

  const handleChangeType = (value?: string | number) => {
    let newIndex = index;
    if (value === 'left' && index === 0) newIndex = 4;
    if (value === 'left' && index !== 0) newIndex = index - 1;
    if (value === 'right' && index === 4) newIndex = 0;
    if (value === 'right' && index !== 4) newIndex = index + 1;
    if (_.isNumber(value)) newIndex = value;

    setIndex(newIndex);
    const currentType = MEMBERS_TYPE[newIndex];
    if (!_.isEmpty(currentType) && onChange) onChange(_.get(currentType, 'value'));
  };

  useEffect(() => {
    if (active) {
      const findType = _.findIndex(MEMBERS_TYPE, { value: active });
      setIndex(findType);
    }
  }, [active]);

  const renderMain = () => {
    return (
      <PaginationContainer>
        <PaginationButton className="arrow" onClick={() => handleChangeType('left')}>
          <i className="fas fa-arrow-left" />
        </PaginationButton>
        {_.map([1, 2, 3, 4, 5], (value, key) => {
          const active = index === _.toNumber(key) ? 'active' : '';
          return (
            <PaginationButton
              className={`${active}`}
              onClick={() => handleChangeType(value - 1)}
              key={`client-type-page-${key}`}
            >
              <span>{value}</span>
            </PaginationButton>
          );
        })}
        <PaginationButton className="arrow" onClick={() => handleChangeType('right')}>
          <i className="fas fa-arrow-right" />
        </PaginationButton>
      </PaginationContainer>
    );
  };

  return renderMain();
};

export default Pagination;
