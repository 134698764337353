/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { DefaultAdminLayout } from '../../../components/Layout/DefaultLayout';
import { NormalTable } from '../../../components/Layout/CommonLayoutPart/Table';
import {
  AdminDropdown,
  FlexHorizontal,
  FlexVertical,
  DarkElectriSolidcButton,
  GeyserSolidButton,
  CarrotSolidButton,
  InputCalendar,
  InputSearchField,
  AdminPagination,
  ContentContainer,
  AdminLabel,
} from '../../../components/Common';
import history from '../../../history';
import { ArticlesActions } from '../../../actions';
import { GenerateValue, statusAlert } from '../../../libs';
import { ColumnsTable } from '../../../constants';
import { ROUTES, DropdownOptions } from '../../../constants';

const {
  setAdminArticlesFilter,
  filterArticles,
  removeSingleArticles,
  setAdminArticlesPagination,
  setRemoveArticlesIds,
  removeMultipleArticles,
  clearRemoveArticlesIds,
} = ArticlesActions;
const { generateDropdown } = GenerateValue;

const ManageArticles = () => {
  // Declare translation
  const { t } = useTranslation('admin');
  // Declare dispatch, reducers
  const filter = useSelector(state => _.get(state, 'Articles.filter.admin'));
  const pagination = useSelector(state => _.get(state, 'Articles.pagination'));
  const meta = useSelector(state => _.get(state, 'Articles.meta'));
  const isRequestLoading = useSelector(state => _.get(state, 'Articles.isLoading'));
  const tablePayload = useSelector(state => _.get(state, 'Articles.payload'));
  const removeIds = useSelector(state => _.get(state, 'Articles.removeIds'));
  const timezone = useSelector(state => _.get(state, 'PageSettings.ip.timezone'));
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(filterArticles({ ...filter, ...pagination }));
  }, []);

  const handleSearch = async () => {
    await dispatch(filterArticles({ ...filter, ...pagination }));
  };

  const handleChangeFilter = async (value: any, key: string) => {
    const newPayload = {
      ...filter,
      [key]: value,
    };
    if (key === 'features' && (value === 'insights' || value === 'successStories')) {
      _.assign(newPayload, { topic: '' });
    }
    if (key === 'categories' && (value === 'DiGISNews' || value === 'general')) {
      _.assign(newPayload, { topic: '' });
    }
    if (key !== 'keyword') {
      if (key === 'dateFrom' || key === 'dateTo') {
        const getDate = new Date(value).toLocaleDateString('en-US', { timeZone: timezone });
        const objectDate = key === 'dateTo' ? `${getDate} 23:59:59` : `${getDate} 00:00:00`;
        _.assign(newPayload, { [key]: objectDate });
      }
      await dispatch(setAdminArticlesPagination({ ...pagination, currentPage: 1 }));
      await dispatch(filterArticles({ ...newPayload, currentPage: 1, limit: 10 }));
    }
    await dispatch(setAdminArticlesFilter(newPayload));
  };

  const handleViewDetails = async (value: any, key: string) => {
    if (value && _.isString(value)) {
      if (key === 'delete') {
        const isAgree = await statusAlert('warning', t('alert.warningDeleteArticle'));
        if (isAgree) {
          let currentPage = _.get(pagination, 'currentPage');
          if (tablePayload.length === 1 && currentPage !== 1) currentPage = currentPage - 1;
          if (currentPage !== _.get(pagination, 'currentPage'))
            dispatch(setAdminArticlesPagination({ ...pagination, currentPage }));
          await dispatch(removeSingleArticles(value));
          await dispatch(filterArticles({ ...filter, ...pagination, currentPage }));
        }
        const findRemoveIds = _.includes(removeIds, value);
        if (findRemoveIds) {
          const resolveRemoveIds = _.filter(removeIds, id => id !== value);
          dispatch(setRemoveArticlesIds(resolveRemoveIds));
        }
      }
      if (key === 'edit') history.push(`${ROUTES.ADMIN.ARTICLES}/details?id=${value}`);
    }
  };

  const handleSort = async (key: string) => {
    const sortBy = _.get(filter, 'sortBy');
    const resolveFilter = {
      ...filter,
      sortBy: key,
    };
    if (sortBy === key) {
      _.assign(resolveFilter, { sortBy: '', orderBy: 'ASC' });
      await dispatch(filterArticles({ ...resolveFilter, sortBy: key, orderBy: 'ASC', ...pagination }));
    } else {
      _.assign(resolveFilter, { orderBy: 'DESC' });
      await dispatch(filterArticles({ ...resolveFilter, ...pagination }));
    }
    await dispatch(setAdminArticlesFilter(resolveFilter));
  };

  const handleClickPagination = async (currentPage: number) => {
    await dispatch(setAdminArticlesPagination({ ...pagination, currentPage }));
    await dispatch(filterArticles({ ...filter, ...pagination, currentPage }));
    await dispatch(setRemoveArticlesIds([]));
  };

  const checkDisabledTopic = () => {
    const { features, categories } = filter;
    const checkFeatures = _.includes(['insights', 'successStories'], features);
    if (checkFeatures) return true;
    if (categories === 'DiGISNews' || categories === 'general') return true;

    return false;
  };

  const generateTopicDropdown = () => {
    const categories = _.get(filter, 'categories');
    const resolveOptions = [
      {
        label: 'all',
        value: '',
      },
    ];
    if (categories === 'news') resolveOptions.push(...DropdownOptions.TOPIC.NEWS);
    else if (categories === 'developmentNews') resolveOptions.push(...DropdownOptions.TOPIC.DEVELOPMENT_NEWS);
    else if (categories === 'researchNews') resolveOptions.push(...DropdownOptions.TOPIC.RESEARCH_NEWS);
    else if (categories === 'researchNews') return [];
    else resolveOptions.push(...DropdownOptions.TOPIC.NEWS);
    return resolveOptions;
  };

  const handleSelectAll = () => {
    if (_.isArray(removeIds)) {
      const isMaxLength = removeIds.length === tablePayload.length;
      if (isMaxLength) dispatch(setRemoveArticlesIds([]));
      else {
        const ids: string[] = [];
        _.map(tablePayload, item => {
          const id = _.get(item, 'id');
          ids.push(id);
        });
        dispatch(setRemoveArticlesIds(ids));
      }
    }
  };

  const handleDelete = async () => {
    const isAgree = await statusAlert('warning', t('alert.warningDeleteAllArticles'));
    if (isAgree) {
      let currentPage = _.get(pagination, 'currentPage');
      if (tablePayload.length === removeIds.length && currentPage !== 1) currentPage = currentPage - 1;
      else currentPage = _.get(pagination, 'currentPage');
      if (currentPage !== _.get(pagination, 'currentPage'))
        dispatch(setAdminArticlesPagination({ ...pagination, currentPage }));
      await dispatch(removeMultipleArticles(removeIds));
      await dispatch(clearRemoveArticlesIds());
      await dispatch(filterArticles({ ...filter, ...pagination, currentPage }));
    }
  };

  const handleResetFilter = async () => {
    const defaultFilter = {
      features: '',
      categories: '',
      topic: '',
      dateFrom: null,
      dateTo: null,
      keyword: '',
      sortBy: '',
      language: 'en',
      orderBy: 'DESC',
    };
    await dispatch(setAdminArticlesFilter(defaultFilter));
    await dispatch(filterArticles(defaultFilter));
  };

  const renderFilterBar = () => {
    return (
      <FlexHorizontal>
        <FlexVertical width="unset" className="mr-2">
          <FlexHorizontal alignCenter>
            <AdminLabel width="60px">{t('label.solution')}</AdminLabel>
            <AdminDropdown
              width="200px"
              data={DropdownOptions.FILTER_SOLUTION}
              selectedValue={_.get(filter, 'categories')}
              onChange={(value: any) => handleChangeFilter(value, 'categories')}
            />
          </FlexHorizontal>
          <FlexHorizontal alignCenter className="mt-1">
            <AdminLabel width="60px">{t('label.from')}</AdminLabel>
            <InputCalendar
              value={_.get(filter, 'dateFrom') || null}
              onChange={(value: any) => handleChangeFilter(value, 'dateFrom')}
              isMaxDate={new Date(new Date().getTime() - 24 * 60 * 60 * 1000).toISOString()}
              className="date_from"
            />
          </FlexHorizontal>
          <FlexHorizontal flex="1" alignCenter className="mt-1">
            <AdminLabel width="60px">{t('label.language')}</AdminLabel>
            <AdminDropdown
              width="200px"
              data={[
                { label: 'english', value: 'en' },
                { label: 'albanian', value: 'al' },
              ]}
              selectedValue={_.get(filter, 'language')}
              onChange={(value: any) => handleChangeFilter(value, 'language')}
            />
          </FlexHorizontal>
        </FlexVertical>
        <FlexVertical width="unset" className="mr-2">
          <FlexHorizontal alignCenter>
            <AdminLabel width="60px">{t('label.features')}</AdminLabel>
            <AdminDropdown
              data={generateDropdown(DropdownOptions.FEATURES, true)}
              width="200px"
              selectedValue={_.get(filter, 'features')}
              onChange={(value: any) => handleChangeFilter(value, 'features')}
            />
          </FlexHorizontal>
          <FlexHorizontal alignCenter className="mt-1">
            <AdminLabel width="60px">{t('label.to')}</AdminLabel>
            <InputCalendar
              value={_.get(filter, 'dateTo') || null}
              onChange={(value: any) => handleChangeFilter(value, 'dateTo')}
              isMinDate={
                _.get(filter, 'dateFrom')
                  ? new Date(new Date(_.get(filter, 'dateFrom')).getTime() + 24 * 60 * 60 * 1000).toISOString()
                  : new Date().toISOString()
              }
              className="date_to"
              isMaxDate={new Date().toISOString()}
              disabled={!_.get(filter, 'dateFrom')}
            />
          </FlexHorizontal>
        </FlexVertical>
        <FlexVertical width="unset" className="mr-2">
          <FlexHorizontal alignCenter>
            <AdminLabel width="60px">{t('label.topic')}</AdminLabel>
            <AdminDropdown
              width="25rem"
              disabled={checkDisabledTopic()}
              data={generateTopicDropdown()}
              selectedValue={_.get(filter, 'topic')}
              onChange={(value: any) => handleChangeFilter(value, 'topic')}
            />
          </FlexHorizontal>

          <FlexHorizontal alignCenter className="mt-1">
            <AdminLabel width="60px">{t('label.search')}</AdminLabel>
            <InputSearchField
              width="250px"
              placeholder={t('placeholder.searchByTitle')}
              value={_.get(filter, 'keyword')}
              onChange={(e: any) => handleChangeFilter(e.target.value, 'keyword')}
              onClick={() => handleSearch()}
              onKeydown={() => handleSearch()}
            />
          </FlexHorizontal>
        </FlexVertical>
        <FlexVertical className="mr-2">
          <CarrotSolidButton width="100px" className="mb-1" onClick={() => history.push(ROUTES.ADMIN.ARTICLES_DETAILS)}>
            {t('button.create')}
          </CarrotSolidButton>
          <GeyserSolidButton width="100px" onClick={() => handleResetFilter()}>
            {t('button.reset')}
          </GeyserSolidButton>
        </FlexVertical>
      </FlexHorizontal>
    );
  };

  const renderTable = () => {
    return (
      <NormalTable
        className="mt-3"
        column={ColumnsTable.ARTICLES}
        data={tablePayload}
        onClick={(value: any, key: string) => handleViewDetails(value, key)}
        onSort={(key: string) => handleSort(key)}
        sortBy={_.get(filter, 'sortBy')}
        fragment="0.1fr 3fr 1fr 1.2fr 0.7fr 0.4fr 0.4fr 0.4fr"
        checked={removeIds}
        handleChecked={(value: string[]) => dispatch(setRemoveArticlesIds(value))}
        lang={_.get(filter, 'language')}
      />
    );
  };

  const renderPagination = () => {
    if (!_.isEmpty(tablePayload))
      return (
        <FlexHorizontal minWidth="1035px" justifySpaceBetween className="mt-3 pb-3">
          <FlexHorizontal>
            <DarkElectriSolidcButton onClick={() => handleSelectAll()} className="mr-1">
              {removeIds.length === tablePayload.length ? t('button.deselectAll') : t('button.selectAll')}
            </DarkElectriSolidcButton>
            <CarrotSolidButton
              onClick={() => !_.isEmpty(removeIds) && handleDelete()}
              className={`${_.isEmpty(removeIds) ? 'disabled' : ''}`}
            >
              {t('button.delete')} ({_.isArray(removeIds) && removeIds.length})
            </CarrotSolidButton>
          </FlexHorizontal>
          <AdminPagination
            maxPage={_.get(meta, 'totalPages')}
            currentPage={_.get(meta, 'currentPage')}
            numberPageDisplay={2}
            totalCount={_.get(meta, 'totalItems')}
            clickLinkPagination={(page: number) => handleClickPagination(page)}
            clickLinkNext={(page: number) => handleClickPagination(page)}
            clickLinkPrevious={(page: number) => handleClickPagination(page)}
            clickLinkLast={() => handleClickPagination(_.get(meta, 'totalPages'))}
            clickLinkFirst={() => handleClickPagination(1)}
          />
        </FlexHorizontal>
      );
  };

  const renderMain = () => {
    return (
      <ContentContainer>
        {renderFilterBar()}
        {renderTable()}
        {renderPagination()}
      </ContentContainer>
    );
  };

  return <DefaultAdminLayout content={renderMain()} currentPage={ROUTES.ADMIN.ARTICLES} loading={isRequestLoading} />;
};

export default ManageArticles;
