/* eslint import/no-anonymous-default-export: off */
import ActionTypes from "../constants/ActionTypes";
import API from "../api";
import { statusAlert } from "../libs/AlertStatus";

// SINGLE ACTIONS
const setLanguageEditing = (payload: any) => {
  return {
    type: ActionTypes.SET_LANGUAGE_EDITING,
    payload,
  };
};
const setPageEditing = (payload: any) => {
  return {
    type: ActionTypes.SET_PAGE_EDITING,
    payload,
  };
};
const setEditContentPayload = (payload: any) => {
  return {
    type: ActionTypes.SET_EDIT_CONTENT_PAYLOAD,
    payload,
  };
};
const clearEditContentPayload = (payload: any) => {
  return {
    type: ActionTypes.CLEAR_EDIT_CONTENT_PAYLOAD,
    payload,
  };
};

// ASYNC ACTIONS
const updateContentIDRAPagesFail = () => {
  return {
    type: ActionTypes.UPDATE_CONTENT_IDRA_PAGES_FAILURE,
  };
};

const updateContentIDRAPagesRequest = () => {
  return {
    type: ActionTypes.UPDATE_CONTENT_IDRA_PAGES_REQUEST,
  };
};

const updateContentIDRAPagesSuccess = (payload: any) => {
  return {
    type: ActionTypes.UPDATE_CONTENT_IDRA_PAGES_SUCCESS,
    payload,
  };
};

const updateContentIDRAPages = (data: any) => {
  return async (dispatch: any) => {
    dispatch(updateContentIDRAPagesRequest());
    await API.updateIDRAPages(data)
      .then(async (response: any) => {
        const { status, message, payload } = response;
        if (status) {
          dispatch(updateContentIDRAPagesSuccess(payload));
          statusAlert("success", message);
        } else {
          dispatch(updateContentIDRAPagesFail());
          statusAlert("error", message);
        }
      })
      .catch(async () => dispatch(updateContentIDRAPagesFail()));
  };
};

const updateContentContactPageFail = () => {
  return {
    type: ActionTypes.UPDATE_CONTENT_CONTACT_FAILURE,
  };
};

const updateContentContactPageRequest = () => {
  return {
    type: ActionTypes.UPDATE_CONTENT_CONTACT_REQUEST,
  };
};

const updateContentContactPageSuccess = (payload: any) => {
  return {
    type: ActionTypes.UPDATE_CONTENT_CONTACT_SUCCESS,
    payload,
  };
};

const updateContentContactPage = (data: any) => {
  return async (dispatch: any) => {
    dispatch(updateContentContactPageRequest());
    await API.updateContentContact(data)
      .then(async (response: any) => {
        const { status, message, payload } = response;
        if (status) {
          dispatch(updateContentContactPageSuccess(payload));
          statusAlert("success", message);
        } else {
          dispatch(updateContentContactPageFail());
          statusAlert("error", message);
        }
      })
      .catch(async () => dispatch(updateContentContactPageFail()));
  };
};

const updateContentCareerPageFail = () => {
  return {
    type: ActionTypes.UPDATE_CONTENT_CAREERS_FAILURE,
  };
};

const updateContentCareerPageRequest = () => {
  return {
    type: ActionTypes.UPDATE_CONTENT_CAREERS_REQUEST,
  };
};

const updateContentCareerPageSuccess = (payload: any) => {
  return {
    type: ActionTypes.UPDATE_CONTENT_CAREERS_SUCCESS,
    payload,
  };
};

const updateContentCareerPage = (data: any) => {
  return async (dispatch: any) => {
    dispatch(updateContentCareerPageRequest());
    await API.updateContentCareer(data)
      .then(async (response: any) => {
        const { status, message, payload } = response;
        if (status) {
          dispatch(updateContentCareerPageSuccess(payload));
          statusAlert("success", message);
        } else {
          dispatch(updateContentCareerPageFail());
          statusAlert("error", message);
        }
      })
      .catch(async () => dispatch(updateContentCareerPageFail()));
  };
};

const updateContentWhoWeArePageFail = () => {
  return {
    type: ActionTypes.UPDATE_CONTENT_WHO_WE_ARE_FAILURE,
  };
};

const updateContentWhoWeArePageRequest = () => {
  return {
    type: ActionTypes.UPDATE_CONTENT_WHO_WE_ARE_REQUEST,
  };
};

const updateContentWhoWeArePageSuccess = (payload: any) => {
  return {
    type: ActionTypes.UPDATE_CONTENT_WHO_WE_ARE_SUCCESS,
    payload,
  };
};

const updateContentWhoWeArePage = (data: any) => {
  return async (dispatch: any) => {
    dispatch(updateContentWhoWeArePageRequest());
    await API.updateContentWhoWeAre(data)
      .then(async (response: any) => {
        const { message, status, payload } = response;
        if (status) {
          dispatch(updateContentWhoWeArePageSuccess(payload));
          statusAlert("success", message);
        } else {
          dispatch(updateContentWhoWeArePageFail());
          statusAlert("error", message);
        }
      })
      .catch(async () => dispatch(updateContentWhoWeArePageFail()));
  };
};

const updateContentServicesSolutionsFail = () => {
  return {
    type: ActionTypes.UPDATE_CONTENT_SERVICES_SOLUTIONS_FAILURE,
  };
};

const updateContentServicesSolutionsRequest = () => {
  return {
    type: ActionTypes.UPDATE_CONTENT_SERVICES_SOLUTIONS_REQUEST,
  };
};

const updateContentServicesSolutionsSuccess = (payload: any) => {
  return {
    type: ActionTypes.UPDATE_CONTENT_SERVICES_SOLUTIONS_SUCCESS,
    payload,
  };
};

const updateServicesSolutions = (data: any) => {
  return async (dispatch: any) => {
    dispatch(updateContentServicesSolutionsRequest());
    await API.updateContentServicesSolutions(data)
      .then(async (response: any) => {
        const { message, status, payload } = response;
        if (status) {
          dispatch(updateContentServicesSolutionsSuccess(payload));
          statusAlert("success", message);
        } else {
          dispatch(updateContentServicesSolutionsFail());
          statusAlert("error", message);
        }
      })
      .catch(async () => dispatch(updateContentServicesSolutionsFail()));
  };
};

const updateContentHomepageFail = () => {
  return {
    type: ActionTypes.UPDATE_CONTENT_HOMEPAGE_FAILURE,
  };
};

const updateContentHomepageRequest = () => {
  return {
    type: ActionTypes.UPDATE_CONTENT_HOMEPAGE_REQUEST,
  };
};

const updateContentHomepageSuccess = (payload: any) => {
  return {
    type: ActionTypes.UPDATE_CONTENT_HOMEPAGE_SUCCESS,
    payload,
  };
};

const updateContentHomepage = (data: any) => {
  return async (dispatch: any) => {
    dispatch(updateContentHomepageRequest());
    await API.updateContentHomepage(data)
      .then(async (response: any) => {
        const { message, status, payload } = response;
        if (status) {
          dispatch(updateContentHomepageSuccess(payload));
          statusAlert("success", message);
        } else {
          dispatch(updateContentHomepageFail());
          statusAlert("error", message);
        }
      })
      .catch(async () => dispatch(updateContentHomepageFail()));
  };
};

const updateContentAlumniNetworkFail = () => {
  return {
    type: ActionTypes.UPDATE_ALUMNI_NETWORK_CONTENT_FAILURE,
  };
};

const updateContentAlumniNetworkRequest = () => {
  return {
    type: ActionTypes.UPDATE_ALUMNI_NETWORK_CONTENT_REQUEST,
  };
};

const updateContentAlumniNetworkSuccess = (payload: any) => {
  return {
    type: ActionTypes.UPDATE_ALUMNI_NETWORK_CONTENT_SUCCESS,
    payload,
  };
};

const updateContentAlumniNetwork = (data: any) => {
  return async (dispatch: any) => {
    dispatch(updateContentAlumniNetworkRequest());
    await API.updateContentAlumniNetwork(data)
      .then(async (response: any) => {
        const { message, status, payload } = response;
        if (status) {
          dispatch(updateContentAlumniNetworkSuccess(payload));
          statusAlert("success", message);
        } else {
          dispatch(updateContentAlumniNetworkFail());
          statusAlert("error", message);
        }
      })
      .catch(async () => dispatch(updateContentAlumniNetworkFail()));
  };
};

const gettAllContentForUserFail = () => {
  return {
    type: ActionTypes.GET_EDIT_CONTENT_FAILURE,
  };
};

const gettAllContentForUserRequest = () => {
  return {
    type: ActionTypes.GET_EDIT_CONTENT_REQUEST,
  };
};

const gettAllContentForUserSuccess = () => {
  return {
    type: ActionTypes.GET_EDIT_CONTENT_SUCCESS,
  };
};

const getAllContentForUser = () => {
  return async (dispatch: any) => {
    dispatch(gettAllContentForUserRequest());
    await API.getContentForUser().then(async (response: any) => {
      const { status, payload } = response;
      if (status) {
        dispatch(setEditContentPayload(payload));
        dispatch(gettAllContentForUserSuccess());
      } else dispatch(gettAllContentForUserFail());
    });
  };
};

const singleActions = {
  setLanguageEditing,
  setPageEditing,
  setEditContentPayload,
  clearEditContentPayload,
};

const asyncActions = {
  updateContentIDRAPages,
  updateContentCareerPage,
  updateContentContactPage,
  updateContentWhoWeArePage,
  updateServicesSolutions,
  updateContentHomepage,
  updateContentAlumniNetwork,
  getAllContentForUser,
};

export default {
  ...singleActions,
  ...asyncActions,
};
