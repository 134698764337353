/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import _ from "lodash";

import SkeletonLoading from "./SkeletonLoading";
import {
  FilterNews,
  WorkCard,
  UserPagination,
  Content,
} from "../../../components/Common";
import { DefaultUserLayout } from "../../../components/Layout/DefaultLayout";
import { OurWorkContainer, ContentCol, SVGImage } from "./OurWork.styles";
import history from "../../../history";
import { FILTERS } from "../../../constants/Constants";
import { ROUTES } from "../../../constants";
import { OurWorkActions } from "../../../actions";
import { Images } from "../../../themes";
import { Utils } from "../../../libs";
import { useLocation } from "react-router-dom";

interface ItemsStructure {
  value: string;
  label: string;
  icon?: string;
}
interface DataStructure {
  title: string;
  key: string;
  items: ItemsStructure[] | any[];
}

const defaultFilter = {
  status: "",
  topic: "",
  from_year: "",
  to_year: "",
  page: "",
  country: "",
};

// Declare actions
const {
  getAllWorkProject,
  clearOurWorkFilter,
  setOurWorkMetaPagination,
  setOurWorkDetails,
} = OurWorkActions;

const ResearchWork = () => {
  const { t } = useTranslation("user");
  // Declare dispatch, reducers
  const location = useLocation();
  const dispatch = useDispatch();
  const requestIsLoading = useSelector((state) =>
    _.get(state, "OurWork.isLoading")
  );
  const payload = useSelector((state) => _.get(state, "OurWork.payload"));
  const country = useSelector((state) => _.get(state, "OurWork.country"));
  const meta = useSelector((state) => _.get(state, "OurWork.meta"));
  const [currentFilter, setCurrentFilter] = useState<any>({ page: "research" });
  const [firstLoad, setFirstLoad] = useState<boolean>(false);

  const getSearchLocation = async () => {
    const search = location.search;
    const params = new URLSearchParams(search);
    const topic = params.get("topic");
    const status = params.get("status");
    const resolveFilter = { ...currentFilter };
    if (topic) _.assign(resolveFilter, { topic });
    if (status) _.assign(resolveFilter, { status });
    setCurrentFilter({ ...resolveFilter });
    setFirstLoad(true);
  };

  useEffect(() => {
    getSearchLocation();
  }, []);

  useEffect(() => {
    if (!requestIsLoading) Utils.scrollToBottomOfBanner();
  }, [requestIsLoading]);

  useEffect(() => {
    if (firstLoad)
      dispatch(
        getAllWorkProject({
          filter: currentFilter,
          meta,
        })
      );
  }, [currentFilter]);

  // Handle view details
  const handleClickDetails = async (id: string) => {
    const findProject = _.find(payload, { id });
    if (!_.isEmpty(findProject)) {
      const search = location.search;
      await dispatch(setOurWorkDetails(findProject));
      document.documentElement.style.scrollBehavior = "unset";
      history.push({
        pathname: `${ROUTES.USER.RESEARCH_WORK}/${id}`,
        state: { from: ROUTES.USER.RESEARCH_WORK, query: search },
      });
    }
  };

  // Handle change filter
  const handleChangeFilter = (newFilter: any) => {
    dispatch(setOurWorkMetaPagination({ ...meta, currentPage: 1 }));
    const searchQuery = "?";
    const query: any[] = [];
    _.map(newFilter, (item, key) => {
      if (!_.isEmpty(item) && key !== "page") query.push(`${key}=${item}`);
    });
    history.push({
      pathname: `${ROUTES.USER.RESEARCH_WORK}`,
      search: searchQuery.concat(_.join(query, "&")),
    });
    setCurrentFilter(newFilter);
  };

  const onClearFilter = async () => {
    await dispatch(clearOurWorkFilter());
    dispatch(
      getAllWorkProject({
        filter: { ...defaultFilter, page: "research" },
        meta: { currentPage: 1, totalPages: 0 },
      })
    );
  };

  // Handle change pagination
  const handleChangePage = async (page: number) => {
    const newFilterParams = {
      filter: currentFilter,
      meta: {
        ...meta,
        currentPage: page,
      },
    };
    Utils.scrollToBottomOfBanner();
    await dispatch(getAllWorkProject(newFilterParams));
  };

  const renderFilter = () => {
    const filterData: DataStructure[] = [...FILTERS.RESEARCH_WORK];
    if (_.isArray(country) && !_.isEmpty(country))
      filterData.push({
        title: "implementedIn",
        key: "country",
        items: [{ label: "all", value: "" }, ...country],
      });
    return (
      <FilterNews
        isWorkPage
        data={filterData}
        activeFilter={currentFilter}
        onChange={(value: any) => handleChangeFilter(value)}
        onClick={() => onClearFilter()}
      />
    );
  };

  // Render content right column
  const renderContentColumn = () => {
    return (
      <ContentCol>
        {!_.isEmpty(payload) ? (
          _.map(payload, (work, index) => {
            const id = _.get(work, "id");
            return (
              <WorkCard
                key={`news-${id}`}
                data={work}
                onClick={(location: string) => handleClickDetails(location)}
                searchQuery={location.search}
                pathname={ROUTES.USER.RESEARCH_WORK}
              />
            );
          })
        ) : (
          <SVGImage
            src={Images.noResultFound.default}
            alt="no result found illustrators"
          />
        )}
        {!_.isEmpty(payload) && (
          <div className="mt-2">
            <UserPagination
              onClick={(page: number) => handleChangePage(page)}
              currentPage={_.get(meta, "currentPage")}
              totalPages={_.get(meta, "totalPages")}
            />
          </div>
        )}
      </ContentCol>
    );
  };

  const renderMain = () => {
    return (
      <OurWorkContainer>
        <Content className="news">
          {renderFilter()}
          {requestIsLoading ? <SkeletonLoading /> : renderContentColumn()}
        </Content>
      </OurWorkContainer>
    );
  };

  return (
    <DefaultUserLayout
      content={renderMain()}
      currentPage={ROUTES.USER.RESEARCH_WORK}
      titlePage={t("titlePage.researchWork")}
      loading={requestIsLoading}
      headerTitle="Research Work"
      metaDescription="Since 2006, our team have worked on over 5000 research projects. Discover more about our work"
    />
  );
};

export default ResearchWork;
