/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { DefaultAdminLayout } from '../../../components/Layout/DefaultLayout';
import history from '../../../history';
import {
  FlexHorizontal,
  CarrotSolidButton,
  GeyserSolidButton,
  DarkElectriSolidcButton,
  AdminDropdown,
  AdminPagination,
  AdminLabel,
  ContentContainer,
} from '../../../components/Common';
import { NormalTable } from '../../../components/Layout/CommonLayoutPart/Table';
import { ROUTES, DropdownOptions, ColumnsTable } from '../../../constants';
import { ClientsActions } from '../../../actions';
import { statusAlert } from '../../../libs';
import { LOGOS_PAGE_KEY } from '../../../constants/Constants';

// Declare actions
const {
  getAllClients,
  setClientsFilter,
  updateClient,
  setClientsPagination,
  removeMultipleClients,
  setRemoveClientsIds,
  clearRemoveClientsIds,
} = ClientsActions;

const ClientManagement = () => {
  // Declare translation
  const { t } = useTranslation('admin');
  // Declare dispatch, reducers
  const dispatch = useDispatch();
  const isRequest = useSelector(state => _.get(state, 'Clients.isLoading'));
  const filter = useSelector(state => _.get(state, 'Clients.filter'));
  const payload = useSelector(state => _.get(state, 'Clients.payload'));
  const removeIds = useSelector(state => _.get(state, 'Clients.removeIds'));
  const pagination = useSelector(state => _.get(state, 'Clients.pagination'));
  const meta = useSelector(state => _.get(state, 'Clients.meta'));
  // Declare states
  const [isDisabledDropdown, setIsDisabledDropdown] = useState<boolean>(false);

  useEffect(() => {
    dispatch(getAllClients({ ...filter, ...pagination }));
    const checkDisabledFilter = _.get(filter, 'page') === LOGOS_PAGE_KEY.HOMEPAGE;
    setIsDisabledDropdown(checkDisabledFilter);
  }, []);

  const handleSort = async (key: string) => {
    const sortBy = _.get(filter, 'sortBy');
    const resolveFilter = {
      ...filter,
      sortBy: key,
      orderBy: 'DESC',
    };
    if (sortBy === key) _.assign(resolveFilter, { sortBy: '', orderBy: 'ASC' });
    await dispatch(setClientsFilter(resolveFilter));
    await dispatch(getAllClients({ ...resolveFilter, ...pagination, sortBy: key }));
  };

  const handleChangeFilter = async (value: any, key: string) => {
    const newFilter = {
      ...filter,
      [key]: value,
    };
    if (key === 'page') {
      _.assign(newFilter, { type: '' });
      dispatch(setClientsPagination({ limit: 10, currentPage: 1 }));
    }
    if (value === LOGOS_PAGE_KEY.HOMEPAGE) setIsDisabledDropdown(true);
    else if (isDisabledDropdown) setIsDisabledDropdown(false);
    await dispatch(setClientsFilter(newFilter));
    await dispatch(getAllClients({ ...newFilter, ...pagination }));
  };

  const handleViewDetails = async (value: any, key: string) => {
    if (key === 'edit' && value) history.push(`${ROUTES.ADMIN.CLIENTS}/details?id=${value}`);
    if (key === 'delete' && value) {
      const isAgree = await statusAlert('warning', t('alert.warningDeleteLogo'));
      if (isAgree) {
        const findClient = _.find(payload, { id: value });
        if (findClient) {
          const pageKey = _.get(filter, 'page');
          const pageClient = _.get(findClient, 'pages');
          const newPageClient = _.filter(pageClient, item => {
            if (item !== pageKey) return item;
          });
          const resolveNewClient = {
            ...findClient,
            pages: newPageClient,
          };
          let currentPage = _.get(pagination, 'currentPage');
          if (payload.length === 1 && currentPage !== 1) currentPage = currentPage - 1;
          if (currentPage !== _.get(pagination, 'currentPage'))
            dispatch(setClientsPagination({ ...pagination, currentPage }));
          await dispatch(updateClient(resolveNewClient));
          await dispatch(getAllClients({ ...filter, ...pagination, currentPage }));
          const findRemoveIds = _.includes(removeIds, value);
          if (findRemoveIds) {
            const resolveRemoveIds = _.filter(removeIds, id => id !== value);
            dispatch(setRemoveClientsIds(resolveRemoveIds));
          }
        }
      }
    }
  };

  const handleClickPagination = async (currentPage: number) => {
    await dispatch(setClientsPagination({ ...pagination, currentPage }));
    await dispatch(getAllClients({ ...filter, ...pagination, currentPage }));
    await dispatch(setRemoveClientsIds([]));
  };

  const handleSelectAll = () => {
    if (_.isArray(removeIds)) {
      const isMaxLength = removeIds.length === payload.length;
      if (isMaxLength) dispatch(setRemoveClientsIds([]));
      else {
        const ids: string[] = [];
        _.map(payload, item => {
          const id = _.get(item, 'id');
          ids.push(id);
        });
        dispatch(setRemoveClientsIds(ids));
      }
    }
  };

  const handleDelete = async () => {
    const isAgree = await statusAlert('warning', t('alert.warningDeleteAllLogo'));
    const currentPage = _.get(filter, 'page');
    const resolvePayload: any[] = [];
    _.map(removeIds, id => {
      const findClient = _.find(payload, { id });
      if (findClient) {
        const pages = _.get(findClient, 'pages');
        const filterPages = _.filter(pages, page => page !== currentPage);
        const resolveRemovePage = {
          ...findClient,
          pages: filterPages,
        };
        resolvePayload.push(resolveRemovePage);
      }
    });

    if (isAgree) {
      let currentPage = _.get(pagination, 'currentPage');
      if (payload.length === removeIds.length && currentPage !== 1) currentPage = currentPage - 1;
      else currentPage = _.get(pagination, 'currentPage');
      if (currentPage !== _.get(pagination, 'currentPage'))
        dispatch(setClientsPagination({ ...pagination, currentPage }));
      await dispatch(removeMultipleClients(resolvePayload));
      await dispatch(clearRemoveClientsIds());
      await dispatch(getAllClients({ ...filter, ...pagination, currentPage }));
    }
  };

  const handleResetFilter = () => {
    const defaultFilter = {
      type: '',
      page: LOGOS_PAGE_KEY.HOMEPAGE,
    };
    const defaultPagination = {
      currentPage: 1,
      limit: 10,
    };
    setIsDisabledDropdown(true);
    dispatch(setClientsFilter(defaultFilter));
    dispatch(getAllClients({ ...defaultFilter, ...defaultPagination }));
  };

  const renderFilterBar = () => {
    return (
      <FlexHorizontal>
        <FlexHorizontal alignCenter flex="1" className="mr-1">
          <AdminLabel>{t('label.page')}</AdminLabel>
          <AdminDropdown
            width="20rem"
            data={DropdownOptions.CLIENT_PAGES}
            selectedValue={_.get(filter, 'page')}
            onChange={(value: any) => handleChangeFilter(value, 'page')}
          />
        </FlexHorizontal>
        <FlexHorizontal className="mr-1" flex="1" alignCenter>
          <AdminLabel>{t('label.type')}</AdminLabel>
          <AdminDropdown
            width="30rem"
            disabled={isDisabledDropdown}
            data={_.get(DropdownOptions.CATEGORY_CLIENTS, _.get(filter, 'page'))}
            selectedValue={_.get(filter, 'type')}
            onChange={(value: any) => handleChangeFilter(value, 'type')}
          />
        </FlexHorizontal>
        <FlexHorizontal alignCenter>
          <GeyserSolidButton onClick={() => handleResetFilter()}>{t('button.reset')}</GeyserSolidButton>
          <CarrotSolidButton className="ml-1" onClick={() => history.push(ROUTES.ADMIN.CLIENTS_DETAILS)}>
            {t('button.create')}
          </CarrotSolidButton>
        </FlexHorizontal>
      </FlexHorizontal>
    );
  };

  const renderTable = () => {
    return (
      <NormalTable
        className="mt-3"
        column={ColumnsTable.CLIENTS}
        data={payload}
        onClick={(value: any, key: string) => handleViewDetails(value, key)}
        onSort={(key: string) => handleSort(key)}
        sortBy={_.get(filter, 'sortBy')}
        fragment="0.2fr 0.4fr  1.5fr 0.2fr 0.2fr"
        checked={removeIds}
        handleChecked={(ids: string[]) => dispatch(setRemoveClientsIds(ids))}
      />
    );
  };

  const renderPagination = () => {
    return (
      <>
        {!_.isEmpty(payload) && (
          <FlexHorizontal minWidth="1035px" justifySpaceBetween className="mt-3 pb-3">
            <FlexHorizontal>
              <DarkElectriSolidcButton onClick={() => handleSelectAll()} className="mr-1">
                {removeIds.length === payload.length ? t('button.deselectAll') : t('button.selectAll')}
              </DarkElectriSolidcButton>
              <CarrotSolidButton
                onClick={() => !_.isEmpty(removeIds) && handleDelete()}
                className={`${_.isEmpty(removeIds) ? 'disabled' : ''}`}
              >
                {t('button.delete')} ({_.isArray(removeIds) && removeIds.length})
              </CarrotSolidButton>
            </FlexHorizontal>
            <AdminPagination
              maxPage={_.get(meta, 'totalPages')}
              currentPage={_.get(meta, 'currentPage')}
              numberPageDisplay={2}
              totalCount={_.get(meta, 'totalItems')}
              clickLinkPagination={(page: number) => handleClickPagination(page)}
              clickLinkNext={(page: number) => handleClickPagination(page)}
              clickLinkPrevious={(page: number) => handleClickPagination(page)}
              clickLinkLast={() => handleClickPagination(_.get(meta, 'totalPages'))}
              clickLinkFirst={() => handleClickPagination(1)}
            />
          </FlexHorizontal>
        )}
      </>
    );
  };

  const renderMain = () => {
    return (
      <ContentContainer>
        {renderFilterBar()}
        {renderTable()}
        {renderPagination()}
      </ContentContainer>
    );
  };

  return <DefaultAdminLayout content={renderMain()} currentPage={ROUTES.ADMIN.CLIENTS} loading={isRequest} />;
};

export default ClientManagement;
