/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { ClientsIndustriesContainer, ButtonBar, Button, Label, FlexItem } from './ClientsIndustries.styles';
import { GenerateValue } from '../../../libs';
import { FlexHorizontal } from '../../Common';
import { useTranslation } from 'react-i18next';

interface DataStructure {
  title?: string;
  value?: string;
  companyLogo?: string;
  id?: string;
}

interface HeaderStructure {
  title: string;
  value: string;
}

interface SectionProps {
  data: DataStructure[];
  currentType: string;
  onChange(value: string): unknown;
  header?: HeaderStructure[];
  currentPage: string;
  onLoaded?(): unknown;
}

const ClientsIndustries: React.FC<SectionProps> = (props: SectionProps) => {
  const { t } = useTranslation('user');
  const { data, currentType, onChange, header, currentPage, onLoaded } = props;
  const [isRightClick, setIsRightClick] = useState<boolean>(false);
  const [isLeftClick, setIsLeftClick] = useState<boolean>(false);
  const [logoLoaded, setLogoLoaded] = useState<number>(0);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [resolveData, setResolveData] = useState<any>([]);

  useEffect(() => {
    setLogoLoaded(0);
    if (data) setResolveData(data);
    else setResolveData([]);
  }, [data]);

  useEffect(() => {
    if (logoLoaded >= resolveData.length && onLoaded) {
      const loadImage = (image: any) => {
        return new Promise((resolve, reject) => {
          const loadImg = new Image();
          const src = _.get(image, 'companyLogo');
          loadImg.src = src;
          loadImg.onload = () => resolve(src);
          loadImg.onerror = err => reject(err);
        });
      };
      Promise.all(data.map((image: any) => loadImage(image)))
        .then(() => {
          onLoaded();
          setIsLoaded(true);
        })
        .catch(err => console.log('Failed to load images', err));
    }
  }, [logoLoaded]);

  const handleClickLeft = (currentIndex: number) => {
    setIsLoaded(false);
    if (header) {
      if (currentIndex === 0) onChange(_.get(header[header.length - 1], 'value'));
      else onChange(_.get(header[currentIndex - 1], 'value'));
      setIsLeftClick(true);
      setTimeout(() => {
        setIsLeftClick(false);
      }, 1600);
    }
  };

  const handleClickRight = (currentIndex: number) => {
    setIsLoaded(false);
    if (header) {
      if (currentIndex === header.length - 1) onChange(_.get(header[0], 'value'));
      else onChange(_.get(header[currentIndex + 1], 'value'));
      setIsRightClick(true);
      setTimeout(() => {
        setIsRightClick(false);
      }, 1600);
    }
  };

  const renderHeader = () => {
    const findLabel = _.find(header, { value: currentType });
    const currentLabel = _.get(findLabel, 'title');
    const currentIndex = _.findIndex(header, { value: currentType });
    return (
      <ButtonBar>
        <FlexItem>
          <Button
            className={`mr-1 left ${isLeftClick ? 'animate' : ''}`}
            onClick={() => isLoaded && handleClickLeft(currentIndex)}
          >
            <div className="arrow">
              <i />
            </div>
            <div className="content"></div>
          </Button>
          <Button
            className={`first_load ${isRightClick ? 'animate' : ''}`}
            onClick={() => isLoaded && handleClickRight(currentIndex)}
          >
            <div className="arrow">
              <i />
            </div>
            <div className="content"></div>
          </Button>
        </FlexItem>
        <Label>{t(`label.${currentLabel}`)}</Label>
      </ButtonBar>
    );
  };

  const renderImages = () => {
    const generateData = GenerateValue.generateLogos(resolveData, currentPage, currentType);
    if (generateData) {
      const render = _.map(generateData, (client, index) => {
        const id = _.get(client, 'id');
        const companyLogo = _.get(client, 'companyLogo');
        const companyName = _.get(client, 'companyName');
        return (
          <img
            key={`logo-${id ? id : index}`}
            className={`logo ${logoLoaded === generateData.length ? 'show' : ''}`}
            width="auto"
            height="100px"
            src={companyLogo}
            alt={companyName}
            onLoad={() => setLogoLoaded(logoLoaded + 1)}
          />
        );
      });
      return render;
    }
    return <h3 className="not-result">{t('label.noResultFound')}</h3>;
  };

  return (
    <ClientsIndustriesContainer className={` ${logoLoaded === data.length ? 'show' : ''}`}>
      {renderHeader()}
      {!_.isEmpty(data) ? (
        <FlexHorizontal flexWrap className="mt-4 mb-4">{renderImages()}</FlexHorizontal>
      ) : (
        <h3 className="mt-4 mb-4 not-result">{t('label.noResultFound')}</h3>
      )}
    </ClientsIndustriesContainer>
  );
};

export default ClientsIndustries;
