/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useTranslation } from "react-i18next";

import { DefaultAdminLayout } from "../../../components/Layout/DefaultLayout";
import {
  FlexHorizontal,
  FlexVertical,
  CarrotSolidButton,
  DefaultInput,
  TabBar,
  AdminLabel,
  ContentContainer,
  ContentForm,
  RemoveButton,
  BorderBox,
  DisabledInputField,
  PreviewImage,
  UpdatedLabel,
  AdminDropdown,
  OutlineButton,
} from "../../../components/Common";
import { ROUTES, CONSTANTS } from "../../../constants";
import { ColorName } from "../../../components/Variables";
import { SelectMedia, SelectNews } from "../../../components/Layout/Popups";
import { SiteSettingsActions, PageSettingsActions } from "../../../actions";
import { statusAlert } from "../../../libs";
import { TAB_BAR } from "../../../constants/Constants";
import PreviewSiteSetting from "./PreviewSiteSetting";
import { PreviewButton } from "./PreviewSiteSetting.styles";

const {
  getSiteSettings,
  editSiteSettings,
  setSiteSettingsPayload,
  deleteSiteSetting,
} = SiteSettingsActions;
const { setStatusEdited, setContentEdited } = PageSettingsActions;

const SiteSettings = () => {
  // Declare translation
  const { t } = useTranslation("admin");
  // Declare dispatch, reducers
  const dispatch = useDispatch();
  const payload = useSelector((state) => _.get(state, "SiteSettings.payload"));
  const requestIsLoading = useSelector((state) =>
    _.get(state, "SiteSettings.isLoading")
  );
  const articlesIsLoading = useSelector((state) =>
    _.get(state, "Articles.isLoading")
  );
  const isUploadingMedia = useSelector((state) =>
    _.get(state, "Media.isLoading")
  );
  const isEditing = useSelector((state) =>
    _.get(state, "PageSettings.isEdited")
  );
  const contentEdited = useSelector((state) =>
    _.get(state, "PageSettings.contentEdited")
  );
  const timezone = useSelector((state) =>
    _.get(state, "PageSettings.ip.timezone")
  );
  // Declare ref
  const thumbnailRef = useRef<HTMLInputElement>(null);
  // Declare states
  const [currentLocale, setCurrentLocale] = useState<string>("en");
  const [isShowPopup, setIsShowPopup] = useState<boolean>(false);
  const [isShowPreview, setIsShowPreview] = useState<boolean>(false);
  const [currentRow, setCurrentRow] = useState<string>("0");
  const [previewingData, setPreviewingData] = useState<any>({});
  const [isShowSelectNews, setIsShowSelectNews] = useState<boolean>(false);
  const [selectedNews, setSelectedNews] = useState<string>("");

  useEffect(() => {
    dispatch(getSiteSettings());

    return () => {
      dispatch(setStatusEdited(false));
    };
  }, []);

  const handleOnChange = (value: any, row: any, defaultValue?: any) => {
    const isEdited = !_.isEqual(defaultValue, contentEdited);
    if (!isEditing && isEdited) dispatch(setStatusEdited(true));
    const cloneData = [...payload];
    const findRow = cloneData[row];
    const resolveNewPayload = {
      ...findRow,
      ...value,
    };
    cloneData[row] = resolveNewPayload;
    dispatch(setSiteSettingsPayload(cloneData));
  };

  const handleHidePopup = (value: string, type: string) => {
    if (value && type)
      handleOnChange(
        {
          heroMedia: {
            link: value,
            type,
          },
        },
        currentRow
      );
    setIsShowPopup(false);
  };

  const checkRequired = () => {
    let flag = true;
    let message = `${t("alert.foundEmptyFields")}: \n`;
    _.map(payload, (item, index) => {
      const titleEn = _.get(item, "title.en");
      const titleAl = _.get(item, "title.al");
      const heroMedia = _.get(item, "heroMedia.link");
      if (!titleEn)
        message += `${t("alert.titleEnglishOfSection")} ${index + 1}\n`;
      if (!titleAl)
        message += `${t("alert.titleShqipOfSection")} ${index + 1}\n`;
      if (!heroMedia)
        message += `${t("alert.heroMediaOrVideoOfSection")} ${index + 1}\n`;
      if (!titleEn || !titleAl || !heroMedia) flag = false;
    });
    return { flag, message };
  };

  const handleAddNewRow = async () => {
    const { flag, message } = checkRequired();
    if (!flag)
      return await statusAlert(
        "warning",
        `${message}\n${t("alert.fillRequiredFieldsBeforeAddOtherSettings")}`
      );
    const newPayload = [...payload];
    newPayload.push({
      title: {
        al: "",
        en: "",
      },
      subtitle: {
        al: "",
        en: "",
      },
      linkSection: "",
      heroMedia: {
        link: "",
        type: "",
      },
      sectionColor: "#000000",
    });
    dispatch(setSiteSettingsPayload(newPayload));
  };

  const handleSubmit = async () => {
    const { flag, message } = checkRequired();
    if (!flag) return await statusAlert("warning", message);
    await dispatch(editSiteSettings(payload));
    await dispatch(getSiteSettings());
    await dispatch(setStatusEdited(false));
  };

  const onDelete = async (id: string, index?: number) => {
    const isAgree = await statusAlert(
      "warning",
      t("alert.warningDeleteSiteSettings")
    );
    if (isAgree) {
      if (!id && _.isNumber(index)) {
        const newPayload = [...payload];
        _.pullAt(newPayload, [index]);
        dispatch(setSiteSettingsPayload(newPayload));
      } else {
        await dispatch(deleteSiteSetting(id));
        await dispatch(getSiteSettings());
      }
    }
  };

  const onClosePreView = (color?: string) => {
    if (color) {
      const previewId = _.get(previewingData, "id");
      const currentPayload = _.find(payload, { id: previewId });
      const index = _.findIndex(payload, { id: previewId });
      if (!_.isEmpty(currentPayload))
        handleOnChange(
          { sectionColor: color },
          index,
          _.get(currentPayload, "sectionColor")
        );
    }
    setIsShowPreview(false);
    setPreviewingData({});
  };

  const renderOrderList = () => {
    const totalItems = payload.length;
    const rangeItems = _.range(1, totalItems + 1);
    const resolveOrderList: any[] = [];
    _.forEach(rangeItems, (item) =>
      resolveOrderList.push({ label: item.toString(), value: item })
    );
    return resolveOrderList;
  };

  const renderPopup = () => {
    return (
      <>
        {isShowPopup && (
          <SelectMedia
            className={`${isShowPopup ? "show" : ""}`}
            hidePopup={(value: string, type: string) =>
              handleHidePopup(value, type)
            }
            selectedTab="image"
            tabBar={TAB_BAR.SITE_SETTINGS_POPUP}
            isSiteSettings
            onClose={() => setIsShowPopup(false)}
          />
        )}
        {isShowPreview && (
          <PreviewSiteSetting
            data={previewingData}
            onClose={(color?: string) => onClosePreView(color)}
          />
        )}
        {isShowSelectNews && (
          <SelectNews
            className={`${isShowSelectNews ? "show" : ""}`}
            selectedNews={selectedNews}
            onClose={(id?: string) => {
              handleOnChange(
                {
                  linkSection: id,
                },
                currentRow
              );
              setIsShowSelectNews(false);
            }}
          />
        )}
      </>
    );
  };

  const renderTabBar = () => {
    return (
      <TabBar
        selectedTab={currentLocale}
        data={CONSTANTS.TAB_BAR.SITE_SETTINGS}
        onChange={(value: string) => setCurrentLocale(value)}
        className="mb-2"
      />
    );
  };

  const renderPreviewMedia = (item: any) => {
    const heroMedia = _.get(item, "heroMedia");
    const { link, type } = heroMedia;
    if (type === "image")
      return (
        <PreviewImage className={`${link ? "" : "border"}`}>
          {link && (
            <LazyLoadImage
              src={link}
              key={`key-${link}`}
              effect="blur"
              alt={`heading-image`}
            />
          )}
          <PreviewButton
            onClick={() => {
              setPreviewingData(item);
              setIsShowPreview(true);
            }}
          >
            <i className="fas fa-eye" />
          </PreviewButton>
        </PreviewImage>
      );
    return (
      <PreviewImage className={`${link ? "" : "border"}`}>
        <video width="100%" autoPlay loop>
          <source src={link} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <PreviewButton
          onClick={() => {
            setPreviewingData(item);
            setIsShowPreview(true);
          }}
        >
          <i className="fas fa-eye" />
        </PreviewButton>
      </PreviewImage>
    );
  };

  const renderContent = () => {
    const render = _.map(payload, (item, index) => {
      const key = `setting-${index}`;
      const lastUpdated = _.get(item, "updatedAt");
      return (
        <BorderBox key={key}>
          <RemoveButton
            onClick={() => onDelete(_.get(item, "id"), _.toNumber(index))}
          >
            <i className="fas fa-times" />
          </RemoveButton>
          {lastUpdated && (
            <FlexHorizontal justifySpaceBetween>
              <FlexHorizontal flex="1">
                <UpdatedLabel className="mb-1">
                  {t("label.lastUpdated")}:{" "}
                  {new Date(lastUpdated).toLocaleString("en-GB", {
                    timeZone: timezone,
                  })}
                </UpdatedLabel>
              </FlexHorizontal>
              <FlexHorizontal width="unset" alignCenter>
                <AdminLabel>{t("label.order")}</AdminLabel>
                <AdminDropdown
                  width="10rem"
                  isNoneTranslate
                  data={renderOrderList()}
                  onChange={(e: any) =>
                    handleOnChange(
                      {
                        ...item,
                        order: e,
                      },
                      index,
                      _.trim(e)
                    )
                  }
                  selectedValue={_.get(item, "order")}
                />
              </FlexHorizontal>
            </FlexHorizontal>
          )}
          <FlexHorizontal alignCenter className="mt-1">
            <AdminLabel width="130px">{t("label.title")}*</AdminLabel>
            <DefaultInput
              flex="1"
              placeholder={t("placeholder.title")}
              onChange={(e: any) =>
                handleOnChange(
                  {
                    title: {
                      ...item.title,
                      [currentLocale]: e.target.value,
                    },
                  },
                  index,
                  _.trim(e.target.value)
                )
              }
              onFocus={(e: any) =>
                dispatch(setContentEdited(_.trim(e.target.value)))
              }
              value={_.get(item, `title.${currentLocale}`)}
            />
          </FlexHorizontal>
          <FlexHorizontal alignCenter className="mt-1">
            <AdminLabel width="130px">{t("label.newsId")}</AdminLabel>
            <DefaultInput
              flex="1"
              placeholder={t("label.link")}
              onChange={(e: any) =>
                handleOnChange(
                  { ...item, linkSection: e.target.value },
                  index,
                  _.trim(e.target.value)
                )
              }
              onFocus={(e: any) =>
                dispatch(setContentEdited(_.trim(e.target.value)))
              }
              value={_.get(item, `linkSection`)}
              disabled
            />
            <OutlineButton
              width="40px"
              className="ml-1"
              onClick={() => {
                setIsShowSelectNews(true);
                setCurrentRow(index);
                setSelectedNews(_.get(item, "linkSection"));
              }}
            >
              <i className="fas fa-newspaper" />
            </OutlineButton>
          </FlexHorizontal>
          <FlexHorizontal alignCenter className="mt-1">
            <AdminLabel width="130px">
              {t("label.heroImageOrVideo")}*
            </AdminLabel>
            <FlexHorizontal flex="1">
              <DisabledInputField>
                <input
                  value={_.get(item, "heroMedia.link") || "N/I"}
                  ref={thumbnailRef}
                  readOnly={true}
                />
              </DisabledInputField>
              <CarrotSolidButton
                onClick={() => {
                  setIsShowPopup(true);
                  setCurrentRow(index);
                }}
              >
                {t("button.selectMedia")}
              </CarrotSolidButton>
            </FlexHorizontal>
          </FlexHorizontal>
          <FlexHorizontal alignCenter className="mt-1">
            <AdminLabel width="130px">{t("label.preview")}</AdminLabel>
            {renderPreviewMedia(item)}
          </FlexHorizontal>
        </BorderBox>
      );
    });

    return render;
  };

  const renderButton = () => {
    return (
      <FlexVertical>
        <CarrotSolidButton
          className="mt-1 mb-2"
          flex="1"
          width="100%"
          color={ColorName.burntSienna}
          onClick={() => handleAddNewRow()}
        >
          {t("button.addMore")}
        </CarrotSolidButton>
        <FlexHorizontal justifyFlexEnd>
          <CarrotSolidButton
            width="10rem"
            color={ColorName.burntSienna}
            onClick={() => handleSubmit()}
          >
            {t("button.save")}
          </CarrotSolidButton>
        </FlexHorizontal>
      </FlexVertical>
    );
  };

  const renderMain = () => {
    return (
      <ContentContainer>
        {renderPopup()}
        <ContentForm maxWidth="840px">
          {renderTabBar()}
          {renderContent()}
          {renderButton()}
        </ContentForm>
      </ContentContainer>
    );
  };

  return (
    <DefaultAdminLayout
      content={renderMain()}
      currentPage={ROUTES.ADMIN.SITE_SETTINGS}
      loading={isUploadingMedia || requestIsLoading || articlesIsLoading}
    />
  );
};

export default SiteSettings;
