/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';

import { PageContainer, ShowMoreContent } from '../../../components/Common';
import { DefaultUserLayout } from '../../../components/Layout/DefaultLayout';
import SecondHeader from '../../../components/Layout/CommonLayoutPart/SecondHeader';
import { SECOND_HEADER } from '../../../constants/Constants';
import { ROUTES } from '../../../constants/';
import history from '../../../history';
import { Utils } from '../../../libs';

const DevelopmentSolution = () => {
  const { t } = useTranslation('user');
  // Declare query string to get id in param
  const location = useLocation();
  const queryStringValue = queryString.parse(location.search);
  // Declare dispatch, reducer
  const payload = useSelector(state => _.get(state, `EditContent.payload.${ROUTES.USER.DEVELOPMENT_SOLUTIONS}`));
  const [currentCategory, setCurrentCategory] = useState<string>('');
  const [currentActiveTab, setCurrentActiveTab] = useState<any>({});

  useEffect(() => {
    return () => {
      setCurrentCategory('');
    };
  }, []);

  useEffect(() => {
    const category = _.get(queryStringValue, 'category');
    if (_.isString(category) && category !== currentCategory) setCurrentCategory(category);
  }, [currentCategory]);

  // Handle change category
  const handleChangeCategory = (value: string) => {
    Utils.scrollToBottomOfBanner();
    history.push(`${ROUTES.USER.DEVELOPMENT_SOLUTIONS}?category=${value}`);
    setCurrentCategory(value);
  };

  // Render second header
  const renderSecondHeader = () => {
    return (
      <SecondHeader
        data={SECOND_HEADER.DEVELOPMENT_SOLUTIONS}
        active={currentCategory}
        onChange={(value: string) => handleChangeCategory(value)}
      />
    );
  };

  // Handle show or hider section of category
  const handleShowOrHideCategory = (value: string) => {
    const currentValue = _.get(currentActiveTab, currentCategory);
    const newValue = currentValue === value ? '' : value;
    setCurrentActiveTab({
      ...currentActiveTab,
      [currentCategory]: newValue,
    });
  };

  const renderMain = () => {
    return (
      <PageContainer>
        {renderSecondHeader()}
        <ShowMoreContent
          className="mt-3"
          data={_.get(payload, currentCategory)}
          type={currentCategory}
          onChange={(value: string) => handleShowOrHideCategory(value)}
          active={_.get(currentActiveTab, currentCategory)}
        />
      </PageContainer>
    );
  };

  return (
    <DefaultUserLayout
      content={renderMain()}
      currentPage={ROUTES.USER.DEVELOPMENT_SOLUTIONS}
      titlePage={t('titlePage.developmentSolutions')}
      headerTitle="Development Solutions"
      metaDescription="We provide a wide range of development consulting services in a variety of areas"
    />
  );
};

export default DevelopmentSolution;
