/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import {
  EditContainerPageContainer,
  StepForm,
  StepLabel,
  TitleStep,
  DescriptionStep,
  OptionBox,
} from './EditContactPage.styles';
import { EditContentActions, PageSettingsActions } from '../../../../actions';
import { ROUTES } from '../../../../constants';
import { FlexHorizontal, GridLayout } from '../../../../components/Common';
import { useTranslation } from 'react-i18next';

interface SectionProps {
  className?: string;
}
const { getAllContentForUser, setEditContentPayload } = EditContentActions;
const { setStatusEdited, setContentEdited } = PageSettingsActions;

const EditContactPage = (props: SectionProps) => {
  const { t } = useTranslation('admin');
  // Declare props
  const { className } = props;
  // Declare dispatch, reducers
  const dispatch = useDispatch();
  const contentPayload = useSelector(state => _.get(state, 'EditContent.payload'));
  const payload = useSelector(state => _.get(state, `EditContent.payload.${ROUTES.USER.CONTACT}`));
  const lang = useSelector(state => _.get(state, 'EditContent.langEditing'));
  const isEditing = useSelector(state => _.get(state, 'PageSettings.isEdited'));
  const initialValue = useSelector(state => _.get(state, 'PageSettings.contentEdited'));

  useEffect(() => {
    dispatch(getAllContentForUser());

    return () => {
      dispatch(setStatusEdited(false));
    };
  }, []);

  const handleChangeOptionBox = (value: any, index: number, defaultValue: string) => {
    const isEdited = !_.isEqual(defaultValue, initialValue);
    if (!isEditing && isEdited) dispatch(setStatusEdited(true));
    const options = _.get(payload, 'stepOne.options');
    options[index] = value;
    const newPayload = {
      ...payload,
      stepOne: {
        ...payload.stepOne,
      },
      options,
    };
    dispatch(setEditContentPayload({ ...contentPayload, [ROUTES.USER.CONTACT]: newPayload }));
  };

  return (
    <EditContainerPageContainer className={className}>
      <StepForm>
        <StepLabel>Step one</StepLabel>
        <TitleStep
          className="editable"
          spellCheck={false}
          contentEditable
          suppressContentEditableWarning
          data-placeholder={t('placeholder.typeTheTitleHere')}
          dangerouslySetInnerHTML={{ __html: _.get(payload, `stepOne.stepTitle.${lang}`) }}
          onFocus={(e: any) => dispatch(setContentEdited(_.trim(e.currentTarget.innerText)))}
          onBlur={(e: any) => {
            const isEdited = !_.isEqual(_.trim(e.currentTarget.innerText), initialValue);
            if (!isEditing && isEdited) dispatch(setStatusEdited(true));
            dispatch(
              setEditContentPayload({
                ...contentPayload,
                [ROUTES.USER.CONTACT]: {
                  ...payload,
                  stepOne: {
                    ...payload.stepOne,
                    stepTitle: {
                      ...payload.stepOne.stepTitle,
                      [lang]: e.currentTarget.innerText,
                    },
                  },
                },
              })
            );
          }}
        ></TitleStep>
        <DescriptionStep
          className="editable"
          spellCheck={false}
          contentEditable
          suppressContentEditableWarning
          data-placeholder={t('placeholder.typeTheDescriptionHere')}
          dangerouslySetInnerHTML={{ __html: _.get(payload, `stepOne.stepDescription.${lang}`) }}
          onFocus={(e: any) => dispatch(setContentEdited(_.trim(e.currentTarget.innerText)))}
          onBlur={(e: any) => {
            const isEdited = !_.isEqual(_.trim(e.currentTarget.innerText), initialValue);
            if (!isEditing && isEdited) dispatch(setStatusEdited(true));
            dispatch(
              setEditContentPayload({
                ...contentPayload,
                [ROUTES.USER.CONTACT]: {
                  ...payload,
                  stepOne: {
                    ...payload.stepOne,
                    stepDescription: {
                      ...payload.stepOne.stepDescription,
                      [lang]: e.currentTarget.innerText,
                    },
                  },
                },
              })
            );
          }}
        ></DescriptionStep>
        <GridLayout className="mt-9 ml-auto mr-auto " col="4" colGap="10px" rowGap="10px" maxWidth="1006px">
          {_.map(_.get(payload, 'stepOne.options'), (item, index) => {
            const title = _.get(item, `title.${lang}`);
            const description = _.get(item, `description.${lang}`);
            const icon = _.get(item, 'icon');
            return (
              <OptionBox key={`contact-box-${index}`}>
                <div className="content">
                  <FlexHorizontal justifyCenter alignFlexEnd flex="1">
                    <img src={icon} alt={`${title} icon`} />
                  </FlexHorizontal>
                  <h3
                    className="editable"
                    spellCheck={false}
                    contentEditable
                    suppressContentEditableWarning
                    data-placeholder={t('placeholder.typeTheTitleHere')}
                    onFocus={(e: any) => dispatch(setContentEdited(_.trim(e.currentTarget.innerText)))}
                    onBlur={(e: any) => {
                      if (!isEditing) dispatch(setStatusEdited(true));
                      handleChangeOptionBox(
                        {
                          ...item,
                          title: {
                            ...item.title,
                            [lang]: e.currentTarget.innerText,
                          },
                        },
                        _.parseInt(index),
                        _.trim(e.currentTarget.innerText)
                      );
                    }}
                    dangerouslySetInnerHTML={{ __html: title }}
                  ></h3>
                  <FlexHorizontal justifyCenter flex="1">
                    <p
                      className="editable"
                      spellCheck={false}
                      contentEditable
                      suppressContentEditableWarning
                      data-placeholder={t('placeholder.typeTheDescriptionHere')}
                      onFocus={(e: any) => dispatch(setContentEdited(_.trim(e.currentTarget.innerText)))}
                      onBlur={(e: any) => {
                        if (!isEditing) dispatch(setStatusEdited(true));
                        handleChangeOptionBox(
                          {
                            ...item,
                            description: {
                              ...item.description,
                              [lang]: e.currentTarget.innerText,
                            },
                          },
                          _.parseInt(index),
                          _.trim(e.currentTarget.innerText)
                        );
                      }}
                      dangerouslySetInnerHTML={{ __html: description }}
                    ></p>
                  </FlexHorizontal>
                </div>
              </OptionBox>
            );
          })}
        </GridLayout>
      </StepForm>
      <StepForm>
        <StepLabel>Step two</StepLabel>
        <TitleStep
          className="editable"
          spellCheck={false}
          contentEditable
          suppressContentEditableWarning
          dangerouslySetInnerHTML={{ __html: _.get(payload, `stepTwo.stepTitle.${lang}`) }}
          data-placeholder={t('placeholder.typeTheTitleHere')}
          onFocus={(e: any) => dispatch(setContentEdited(_.trim(e.currentTarget.innerText)))}
          onBlur={(e: any) => {
            const isEdited = !_.isEqual(_.trim(e.currentTarget.innerText), initialValue);
            if (!isEditing && isEdited) dispatch(setStatusEdited(true));
            dispatch(
              setEditContentPayload({
                ...contentPayload,
                [ROUTES.USER.CONTACT]: {
                  ...payload,
                  stepTwo: {
                    ...payload.stepTwo,
                    stepTitle: {
                      ...payload.stepTwo.stepTitle,
                      [lang]: e.currentTarget.innerText,
                    },
                  },
                },
              })
            );
          }}
        ></TitleStep>
        <DescriptionStep
          className="editable"
          spellCheck={false}
          contentEditable
          suppressContentEditableWarning
          dangerouslySetInnerHTML={{ __html: _.get(payload, `stepTwo.stepDescription.${lang}`) }}
          data-placeholder={t('placeholder.typeTheDescriptionHere')}
          onFocus={(e: any) => dispatch(setContentEdited(_.trim(e.currentTarget.innerText)))}
          onBlur={(e: any) => {
            const isEdited = !_.isEqual(_.trim(e.currentTarget.innerText), initialValue);
            if (!isEditing && isEdited) dispatch(setStatusEdited(true));
            dispatch(
              setEditContentPayload({
                ...contentPayload,
                [ROUTES.USER.CONTACT]: {
                  ...payload,
                  stepTwo: {
                    ...payload.stepTwo,
                    stepDescription: {
                      ...payload.stepTwo.stepDescription,
                      [lang]: e.currentTarget.innerText,
                    },
                  },
                },
              })
            );
          }}
        ></DescriptionStep>
      </StepForm>
    </EditContainerPageContainer>
  );
};

export default EditContactPage;
