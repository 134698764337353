/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import history from "../../../history";
import {
  DashboardContainer,
  DashboardCardBar,
  TitleActivity,
  DashboardCard,
} from "./Dashboard.styles";
import {
  DarkElectriSolidcButton,
  FlexHorizontal,
  DotPulse,
} from "../../../components/Common";
import { Icons } from "../../../themes";
import { DefaultAdminLayout } from "../../../components/Layout/DefaultLayout";
import { ROUTES, ColumnsTable } from "../../../constants";
import { NormalTable } from "../../../components/Layout/CommonLayoutPart/Table";
import { DashboardActions } from "../../../actions";

// Declare constants
const DASHBOARD_CARDS = [
  {
    label: "peopleVisited",
    value: "totalReached",
    icon: Icons.defaultUser.default,
    isEnabled: false,
    router: "",
  },
  {
    label: "articles",
    value: "totalNews",
    icon: Icons.articles.default,
    isEnabled: true,
    router: ROUTES.ADMIN.ARTICLES,
  },
  {
    label: "careers",
    value: "totalCareer",
    icon: Icons.career.default,
    isEnabled: true,
    router: ROUTES.ADMIN.CAREER,
  },
  {
    label: "clients",
    value: "totalClients",
    icon: Icons.trustedBy.default,
    isEnabled: true,
    router: ROUTES.ADMIN.CLIENTS,
  },
];

// Declare actions
const { getCountDashboard, getLogsActivity } = DashboardActions;

const Dashboard = () => {
  // Declare translation
  const { t } = useTranslation("admin");
  // Declare dispatch, reducers
  const dispatch = useDispatch();
  const isRequest = useSelector((state) => _.get(state, "Dashboard.isLoading"));
  const countDashboard = useSelector((state) =>
    _.get(state, "Dashboard.payloadCount")
  );
  const logsActivity = useSelector((state) =>
    _.get(state, "Dashboard.payloadLogs")
  );
  const lang = useSelector((state) => _.get(state, "PageSettings.adminLang"));

  useEffect(() => {
    dispatch(getCountDashboard());
    dispatch(getLogsActivity({ currentPage: 1, limit: 10 }));
  }, []);

  const renderCount = () => {
    return (
      <DashboardCardBar className="mb-2">
        {_.map(DASHBOARD_CARDS, (item, index) => {
          const { icon, value, label, isEnabled, router } = item;
          const className = isEnabled ? "border" : "";
          return (
            <DashboardCard
              key={`dashboard-card-${index}`}
              className={className}
              onClick={() => isEnabled && history.push(router)}
            >
              <div className="icon">
                <img src={icon} alt="dashboard icon" />
              </div>
              <span>{t(`label.${label}`)}</span>
              {isRequest ? (
                <DotPulse />
              ) : (
                <h2>{_.get(countDashboard, value)}</h2>
              )}
            </DashboardCard>
          );
        })}
      </DashboardCardBar>
    );
  };

  const renderTable = () => {
    return (
      <>
        <TitleActivity className="mb-1">Recent Logs</TitleActivity>
        <NormalTable
          data={logsActivity}
          column={ColumnsTable.RECENT_LOGS}
          fragment="0.8fr 1.1fr 3fr 1fr"
          lang={lang}
        />
        {_.isArray(logsActivity) && logsActivity.length > 8 && (
          <FlexHorizontal className="mt-1 pb-2">
            <DarkElectriSolidcButton
              onClick={() => history.push(ROUTES.ADMIN.RECENT_ACTIVITY)}
            >
              {t("button.viewMore")}
            </DarkElectriSolidcButton>
          </FlexHorizontal>
        )}
      </>
    );
  };

  const renderMain = () => {
    return (
      <DashboardContainer>
        {renderCount()}
        {renderTable()}
      </DashboardContainer>
    );
  };

  return (
    <DefaultAdminLayout
      content={renderMain()}
      currentPage={ROUTES.ADMIN.DASHBOARD}
      loading={isRequest}
    />
  );
};

export default Dashboard;
