/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  FlexHorizontal,
  FlexVertical,
  Richtextbox,
  SectionTitle,
} from "../../../../components/Common";
import { EditContentActions, PageSettingsActions } from "../../../../actions";
import { Icons } from "../../../../themes";
import { ROUTES } from "../../../../constants";

import { ContentEditableWrapper } from "../ContentEditableDetails.styles";
import { AlumniIcon } from "./EditAlumniNetwork.styles";

import { IAlumniContent } from "../../../../interfaces/AlumniNetwork.interfaces";

interface SectionProps {
  className?: string;
}
const { getAllContentForUser, setEditContentPayload } = EditContentActions;
const { setStatusEdited, setContentEdited } = PageSettingsActions;

const DEFAULT_PAYLOAD = {
  title: {
    al: "",
    en: "",
  },
  description: {
    al: "",
    en: "",
  },
  newsAndInformation: {
    al: "",
    en: "",
  },
  alumniStories: {
    al: "",
    en: "",
  },
  careerAdvancement: {
    al: "",
    en: "",
  },
  consultingServices: {
    al: "",
    en: "",
  },
  expertiseEngagement: {
    al: "",
    en: "",
  },
  informationExchange: {
    al: "",
    en: "",
  },
  networkingOpportunities: {
    al: "",
    en: "",
  },
  virtualEvents: {
    al: "",
    en: "",
  },
};

const leftKey = {
  newsAndInformation: "Access to company news and information",
  careerAdvancement: "Career advancement",
  consultingServices: "Consulting services",
  expertiseEngagement: "Expertise engagement",
};

const rightKey = {
  networkingOpportunities: "Networking opportunities",
  informationExchange: "Information and experience exchange",
  virtualEvents: "Virtual events webinars",
  alumniStories: "Alumni stories",
};

const EditAlumniNetworkPage = (props: SectionProps) => {
  // Declare props
  const { className } = props;
  // Declare dispatch, reducers
  const dispatch = useDispatch();
  const { t } = useTranslation("admin");
  const contentPayload = useSelector((state) =>
    _.get(state, "EditContent.payload")
  );
  const contentEdited = useSelector((state) =>
    _.get(state, "PageSettings.contentEdited")
  );
  const payload: IAlumniContent =
    useSelector((state) =>
      _.get(state, `EditContent.payload.${ROUTES.USER.ALUMNI_NETWORK}`)
    ) || DEFAULT_PAYLOAD;

  const editingLang = useSelector((state) =>
    _.get(state, "EditContent.langEditing")
  );
  const isEditing = useSelector((state) =>
    _.get(state, "PageSettings.isEdited")
  );

  useEffect(() => {
    dispatch(getAllContentForUser());

    return () => {
      dispatch(setStatusEdited(false));
    };
  }, []);

  const handleChange = (value: string, key: string) => {
    const isEdited = !_.isEqual(value, contentEdited);
    if (!isEditing && isEdited) dispatch(setStatusEdited(true));
    const newContent = {
      ...payload,
      [key]: {
        ...payload[key],
        [editingLang]: value,
      },
    };
    dispatch(
      setEditContentPayload({
        ...contentPayload,
        [ROUTES.USER.ALUMNI_NETWORK]: newContent,
      })
    );
  };

  return (
    <ContentEditableWrapper className={className}>
      <FlexVertical className="mb-3">
        <SectionTitle
          contentEditable
          suppressContentEditableWarning
          spellCheck={false}
          data-placeholder={t("placeholder.typeTheTitleHere")}
          onFocus={(e: any) =>
            dispatch(setContentEdited(_.trim(e?.currentTarget?.innerText)))
          }
          onBlur={(e: any) =>
            handleChange(e?.currentTarget?.innerText, "title")
          }
          className="mt-4 mb-2 editable"
        >
          {_.get(payload, `title.${editingLang}`)}
        </SectionTitle>
        <Richtextbox
          data={_.get(payload, `description.${editingLang}`) || ""}
          onFocus={(value: any) => dispatch(setContentEdited(_.trim(value)))}
          onChange={(e: any) => {
            handleChange(e, "description");
          }}
          fontSize="15px"
          toolbar={[
            "bold",
            "link",
            "|",
            "numberedList",
            "bulletedList",
            "|",
            "undo",
            "redo",
          ]}
          placeholder={t("placeholder.typeTheDescriptionHere")}
        />
      </FlexVertical>
      <FlexHorizontal style={{ gap: 32 }} className="mb-2">
        <FlexVertical flex="1">
          {_.map(_.keys(leftKey), (key) => (
            <FlexHorizontal
              style={{ gap: 10, flexShrink: 0, width: "100%" }}
              key={key}
            >
              <AlumniIcon
                src={Icons[`${key as keyof typeof leftKey}`].default}
                alt={key}
              />
              <FlexVertical style={{ flexGrow: 1 }}>
                <Richtextbox
                  data={_.get(payload, `${key}.${editingLang}`) || ""}
                  onFocus={(value: any) =>
                    dispatch(setContentEdited(_.trim(value)))
                  }
                  onChange={(e: any) => {
                    handleChange(e, `${key}`);
                  }}
                  fontSize="15px"
                  toolbar={["bold", "undo", "redo"]}
                  placeholder={t("placeholder.typeTheContentHere")}
                />
              </FlexVertical>
            </FlexHorizontal>
          ))}
        </FlexVertical>
        <FlexVertical flex="1">
          {_.map(_.keys(rightKey), (key) => (
            <FlexHorizontal
              style={{ gap: 10, flexShrink: 0, width: "100%" }}
              key={key}
            >
              <AlumniIcon
                src={Icons[`${key as keyof typeof rightKey}`].default}
                alt={key}
              />
              <FlexVertical style={{ flexGrow: 1 }}>
                <Richtextbox
                  data={_.get(payload, `${key}.${editingLang}`) || ""}
                  onFocus={(value: any) =>
                    dispatch(setContentEdited(_.trim(value)))
                  }
                  onChange={(e: any) => {
                    handleChange(e, `${key}`);
                  }}
                  fontSize="15px"
                  toolbar={["bold", "undo", "redo"]}
                  placeholder={t("placeholder.typeTheContentHere")}
                />
              </FlexVertical>
            </FlexHorizontal>
          ))}
        </FlexVertical>
      </FlexHorizontal>
    </ContentEditableWrapper>
  );
};

export default EditAlumniNetworkPage;
