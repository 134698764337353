/* eslint import/no-anonymous-default-export: off */
import React from "react";
import _ from "lodash";
import { TableContainer, TableContent } from "./Table.styles";
import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ArticlesActions from "../../../../actions/Articles.actions";
import { useDispatch } from "react-redux";

const {
  updateArticles,
} = ArticlesActions;

interface SectionProps {
  onClick?(id: string, key: string): void;
  onSort?(key: string): void;
  className?: string;
  data?: any[];
  column?: any[];
  fragment?: string;
  sortBy?: string;
  checked?: string[];
  handleChecked?(value: string[]): void;
  handleCheckedChangeStatus?(value: any): void;
  lang?: string;
}

const NormalTable: React.FC<SectionProps> = (props: SectionProps) => {
  // Declare translation
  const { t } = useTranslation("admin");
  const dispatch = useDispatch();
  // Declare props
  // const lang = useSelector(state => _.get(state, 'PageSettings.adminLang'));
  const {
    onClick,
    onSort,
    className,
    data,
    column,
    fragment,
    sortBy,
    checked,
    handleChecked,
    handleCheckedChangeStatus,
    lang,
  } = props;
  // Declare dispatch, reducers

  const handleClick = (value: any, key: string) => {
    if (onClick) onClick(value, key);
  };

  const handleSort = (key: string) => {
    if (onSort) onSort(key);
  };

  const handleCheckedOnRow = (id: string) => {
    if (handleChecked && _.isArray(checked)) {
      const checkExist = _.includes(checked, id);
      if (checkExist) {
        const filterId = _.filter(checked, (row) => row !== id);
        handleChecked(filterId);
      } else handleChecked([...checked, id]);
    }
  };

  const handleCheckedStatus = (member: any) => {
    if (handleCheckedChangeStatus) {
      handleCheckedChangeStatus(member)
    }
  };

  const updateShowHomePage = (item: any) => {
    // if (handleCheckedShowHomePage) handleCheckedShowHomePage(item)
    dispatch(updateArticles({ ...item, isShowOnHomepage: item.isShowOnHomepage === 1 ? 0 : 1 }))
  }

  const renderHeader = () => {
    const render = _.map(column, (item, index) => {
      const arrowClass = sortBy === item.value ? "up" : "down";
      const { isCenter, isSort } = item;
      const centerClass = isCenter ? "flex-justify-center" : "";
      return (
        <span key={`header-${index}`} className={`header ${centerClass}`}>
          {item.label ? t(`table.${item.label}`) : ""}{" "}
          {isSort ? (
            <i
              className={`fas fa-caret-${arrowClass} ml-2`}
              onClick={() => handleSort(item.value)}
            />
          ) : null}
        </span>
      );
    });
    return render;
  };

  const renderData = () => {
    const render = _.map(data, (item, index) => {
      const innerRender = _.map(column, (cell, innerIndex) => {
        const { isCenter } = cell;
        const centerClass = isCenter ? "flex-justify-center" : "";
        const backgroundClass = index % 2 === 0 ? "background" : "";
        if (_.isObject(_.get(item, cell.value))) {
          return (
            <div
              key={`row-${index}-${innerIndex}`}
              className={`${centerClass} content ${backgroundClass}`}
            >
              <span>{_.get(item, `${cell.value}.${lang}`)}</span>
            </div>
          );
        }
        if (cell.value === 'statusCheck') {
          const status = _.get(item, "status");
          return (
            <div
              key={`row-${index}-${innerIndex}`}
              className={`${centerClass} content ${backgroundClass}`}
            >
              <span onClick={() => handleCheckedStatus(item)}>
                {status === 'active' ? (
                  <i className="far fa-check-square" />
                ) : (
                  <i className="far fa-square" />
                )}
              </span>
            </div>
          );
        }
        if (
          (_.has(item, "descriptionAL") || _.has(item, "positionAL")) &&
          (cell.value === "description" || cell.value === "position")
        ) {
          const cellData = {
            description: {
              en: _.get(item, "description"),
              al: _.get(item, "descriptionAL"),
            },
            position: {
              en: _.get(item, "position"),
              al: _.get(item, "positionAL"),
            },
          };
          return (
            <div
              key={`row-${index}-${innerIndex}`}
              className={`${centerClass} content ${backgroundClass}`}
            >
              <span>
                {_.get(cellData, `${cell.value}[${lang ? lang : "en"}]`)}
              </span>
            </div>
          );
        }
        if (cell.value === "updatedAt" || cell.value === "createdAt") {
          return (
            <div
              key={`row-${index}-${innerIndex}`}
              className={`${centerClass} content ${backgroundClass}`}
            >
              <span>
                {new Date(_.get(item, cell.value)).toLocaleString("en-GB")}
              </span>
            </div>
          );
        }
        if (cell.value === "companyLogo") {
          const value = _.get(item, cell.value);
          return (
            <div
              className={`${centerClass} content ${backgroundClass}`}
              key={`row-${index}-${_.get(item, "id")}`}
            >
              <LazyLoadImage
                src={value}
                effect="blur"
                alt={`logo-${_.get(item, "companyName")}`}
                height="35px"
              />
            </div>
          );
        }
        if (cell.value === "status") {
          const value = _.get(item, cell.value);
          const labelStatus =
            value === "active" || value === 1
              ? t("table.active")
              : t("table.inactive");
          const statusClass =
            value === "active" || value === 1 ? "active" : "inactive";
          return (
            <div
              key={`row-${index}-${innerIndex}`}
              className={`${centerClass} content ${backgroundClass}`}
            >
              <span className={` ${statusClass}`}>{labelStatus}</span>
            </div>
          );
        }
        if (cell.value === "id") {
          const rowId = _.get(item, "id");
          const checkIncludes = _.includes(checked, rowId);
          return (
            <div
              key={`row-${index}-${innerIndex}`}
              className={`${centerClass} content ${backgroundClass}`}
            >
              <span onClick={() => handleCheckedOnRow(rowId)}>
                {checkIncludes ? (
                  <i className="far fa-check-square" />
                ) : (
                  <i className="far fa-square" />
                )}
              </span>
            </div>
          );
        }
        if (cell.value === "index") {
          const value = _.get(item, cell.value);
          return (
            <div
              key={`row-show-homape-${index}-${innerIndex}`}
              className={`${centerClass} content ${backgroundClass}`}
            >
              <span onClick={() => updateShowHomePage(item)}>
                {value === 1 ? (
                  <i className="far fa-check-square" />
                ) : (
                  <i className="far fa-square" />
                )}
              </span>
            </div>
          );
        }
        if (cell.value === "edit") {
          return (
            <div
              key={`row-${index}-${innerIndex}`}
              className={`${centerClass} content ${backgroundClass}`}
            >
              <span onClick={() => handleClick(item.id, "edit")}>
                <i className="fas fa-pen" />
              </span>
            </div>
          );
        }
        if (cell.value === "remove") {
          return (
            <div
              key={`row-${index}-${innerIndex}`}
              className={`${centerClass} content ${backgroundClass}`}
            >
              <span onClick={() => handleClick(item.id, "delete")}>
                <i className="fas fa-trash-alt" />
              </span>
            </div>
          );
        }
        if (cell.value === "view") {
          return (
            <div
              key={`row-${index}-${innerIndex}`}
              className={`${centerClass} content view ${backgroundClass}`}
              onClick={() => handleClick(item.newsId, "view")}
            >
              <span className="button">View Details</span>
            </div>
          );
        }
        //TODO
        if (cell.value === "isShowOnHomepage") {
          return (
            <div
              key={`row-${index}-${innerIndex}`}
              className={`${centerClass} content show_homepage ${backgroundClass}`}
            >
              <span onClick={() => updateShowHomePage(item)}>
                {_.get(item, "isShowOnHomepage") ? (
                  <i className="far fa-check-square" />
                ) : (
                  <i className="far fa-square" />
                )}
              </span>
            </div>
          );
        }
        if (cell.value === "createdAt" || cell.value === "updatedAt") {
          return (
            <div
              key={`row-${index}-${innerIndex}`}
              className={`${centerClass} content ${backgroundClass}`}
            >
              <span>
                {new Date(_.get(item, cell.value)).toLocaleString("en-GB")}
              </span>
            </div>
          );
        }
        if (cell.value === "section") {
          return (
            <div
              key={`row-${index}-${innerIndex}`}
              className={`${centerClass} content ${backgroundClass}`}
            >
              <span>{t(`table.${_.get(item, cell.value)}`)}</span>
            </div>
          );
        }
        if (cell.value === "requestType") {
          return (
            <div
              key={`row-${index}-${innerIndex}`}
              className={`${centerClass} content ${backgroundClass}`}
            >
              <span className={`${_.get(item, cell.value)} request-type`}>
                {t(`table.${_.get(item, cell.value)}`)}
              </span>
            </div>
          );
        }
        return (
          <div
            key={`row-${index}-${innerIndex}`}
            className={`${centerClass} content ${backgroundClass}`}
          >
            <span>{_.get(item, cell.value)}</span>
          </div>
        );
      });
      return innerRender;
    });
    return render;
  };

  return (
    <TableContainer className={className}>
      <TableContent fragment={fragment}>
        {renderHeader()}
        {renderData()}
        {_.isEmpty(data) && (
          <>
            <span />
            <span className="content no-result">
              {t("table.noResultFound")}
            </span>
          </>
        )}
      </TableContent>
    </TableContainer>
  );
};

export default NormalTable;
