/* eslint import/no-anonymous-default-export: off */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import {
  MemberCardContainer,
  OutputMemberImage,
  DefaultInformation,
  DetailsInformation,
  ContentInformation,
} from './MemberCard.styles';
import { useSelector } from 'react-redux';

interface DataStructure {
  fullname: string;
  position: string;
  positionAL: string;
  photo: string;
  description: string;
  descriptionAL: string;
  id: string;
  croppedData: any;
}

interface SectionProps {
  data: DataStructure;
  active: string;
  onActive(id: string): void;
  height: number;
  onChangeHeight(newHeight: number): void;
}

const MemberCard = (props: SectionProps) => {
  const { data, active, onActive, onChangeHeight, height } = props;
  const { fullname, position, positionAL, photo, description, descriptionAL, id, croppedData } = data;
  const locale = useSelector(state => _.get(state, 'PageSettings.userLang'));
  const defaultRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const [top, setTop] = useState<string>('calc(100% - 0px)');

  useEffect(() => {
    if (height) setTop(`calc(100% - ${height}px)`);
  }, [height]);

  const resizeMemberPhoto = () => {
    if (defaultRef && defaultRef.current && contentRef && contentRef.current) {
      const defaultHeight = defaultRef.current.clientHeight;
      onChangeHeight(defaultHeight);
    }
  };

  useEffect(() => {
    resizeMemberPhoto();
    window.addEventListener('resize', resizeMemberPhoto);

    return () => {
      window.removeEventListener('resize', resizeMemberPhoto);
    };
  }, [locale]);

  const renderMemberPhoto = (croppedData: any, thumbnail: string) => {
    const croppedArea = _.get(croppedData, 'croppedAreaData');
    if (!croppedArea) return <img style={{ width: '100%', height: 'unset' }} src={thumbnail} alt="" />;
    else {
      const scale = 100 / croppedArea.width;
      const transform = {
        x: `${-croppedArea.x * scale}%`,
        y: `${-croppedArea.y * scale}%`,
        scale,
        width: 'calc(100% + 0.5px)',
        height: 'auto',
      };

      const imageStyle = {
        transform: `translate3d(${transform.x}, ${transform.y}, 0) scale3d(${transform.scale},${transform.scale},1)`,
        width: transform.width,
        height: transform.height,
      };

      return (
        <OutputMemberImage className="output member-card">
          <img onContextMenu={(e: any) => e.preventDefault()} src={thumbnail} alt="news-thumbnail" style={imageStyle} />
        </OutputMemberImage>
      );
    }
  };

  return (
    <MemberCardContainer
      className={`${active === id ? 'show' : ''}`}
      onClick={() => onActive && onActive(active === id ? '' : id)}
    >
      {renderMemberPhoto(croppedData, photo)}
      <DefaultInformation style={{ height: '100%' }} ref={defaultRef}>
        <h3 className="default_name">{fullname}</h3>
        <span className="default_position">{locale === 'en' ? position : positionAL}</span>
      </DefaultInformation>
      <ContentInformation
        className="information"
        onMouseLeave={(e: any) => (e.currentTarget.scrollTop = 0)}
        ref={contentRef}
        top={top}
      >
        <div className="flex_box"></div>
        <DetailsInformation>
          <div style={{ height: `${height}px` }}>
            <h3>{fullname}</h3>
            <span>{locale === 'en' ? position : positionAL}</span>
          </div>
          <p>{locale === 'en' ? description : descriptionAL}</p>
        </DetailsInformation>
      </ContentInformation>
    </MemberCardContainer>
  );
};

export default MemberCard;
