import styled from "styled-components";

const AlumniIcon = styled.img`
  width: 40px;
  height: 40px;
  object-fit: cover;
  margin-top: 10px;
`;

export { AlumniIcon };
