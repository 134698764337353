/* eslint import/no-anonymous-default-export: off */
import ActionTypes from '../constants/ActionTypes';
import API from '../api';
import { statusAlert } from '../libs/AlertStatus';

const sendMailFail = () => {
  return {
    type: ActionTypes.SEND_MAIL_CONTACT_FAILURE,
  };
};

const sendMailRequest = () => {
  return {
    type: ActionTypes.SEND_MAIL_CONTACT_REQUEST,
  };
};

const sendMailSuccess = () => {
  return {
    type: ActionTypes.SEND_MAIL_CONTACT_SUCCESS,
  };
};

const sendMail = (data: any) => {
  return async (dispatch: any) => {
    dispatch(sendMailRequest());
    await API.sendContact(data)
      .then(async (res: any) => {
        const { status, message } = res;
        if (status) {
          dispatch(sendMailSuccess());
          statusAlert('success', message);
        } else {
          dispatch(sendMailFail());
          statusAlert('error', message);
        }
      })
      .catch(async () => dispatch(sendMailFail()));
  };
};

export default { sendMail };
