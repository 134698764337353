import React from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import IDRAPages from "./EditIDRAPage";
import CareersPage from "./EditCareersPage";
import ContactPage from "./EditContactPage";
import WhoWeArePage from "./EditWhoWeArePage";
import ServicesSolutions from "./EditServicesSolutions";
import Homepage from "./EditHomepage";
import EditAlumniNetworkPage from "./EditAlumniNetwork";

import { DefaultAdminLayout } from "../../../components/Layout/DefaultLayout";
import {
  ManageOrderArticlesContainer,
  Label,
  ScrollContent,
} from "./ContentEditableDetails.styles";
import {
  AdminDropdown,
  FlexHorizontal,
  CarrotSolidButton,
  UpdatedLabel,
} from "../../../components/Common";
import { DropdownOptions, ROUTES } from "../../../constants";
import { EditContentActions, PageSettingsActions } from "../../../actions";
import { PAGES_DATA } from "./PageData";
import { statusAlert } from "../../../libs";

const {
  setPageEditing,
  setLanguageEditing,
  updateContentIDRAPages,
  updateContentCareerPage,
  updateContentContactPage,
  updateContentWhoWeArePage,
  updateServicesSolutions,
  updateContentHomepage,
  updateContentAlumniNetwork,
} = EditContentActions;
const { setStatusEdited } = PageSettingsActions;

const ClientManagement = () => {
  // Declare translation
  const { t } = useTranslation("admin");
  // Declare dispatch, reducers
  const dispatch = useDispatch();
  const mediaIsLoading = useSelector((state) =>
    _.get(state, "Media.isLoading")
  );
  const editingPage = useSelector((state) =>
    _.get(state, "EditContent.pageEditing")
  );
  const editingLanguage = useSelector((state) =>
    _.get(state, "EditContent.langEditing")
  );
  const requestIsLoading = useSelector((state) =>
    _.get(state, "EditContent.isLoading")
  );
  const timezone = useSelector((state) =>
    _.get(state, "PageSettings.ip.timezone")
  );
  const payload = useSelector((state) => _.get(state, "EditContent.payload"));
  const isEditing = useSelector((state) =>
    _.get(state, "PageSettings.isEdited")
  );

  const handleChangeEditingPage = async (value: string) => {
    if (isEditing && editingPage !== value) {
      const isAgree = await statusAlert(
        "warning",
        t("alert.warningConfirmLeaveWhenEditing")
      );
      if (isAgree) return dispatch(setPageEditing(value));
      else return;
    } else dispatch(setPageEditing(value));
  };

  const renderContentEditable = () => {
    switch (editingPage) {
      case ROUTES.USER.RESEARCH_SOLUTIONS:
      case ROUTES.USER.DEVELOPMENT_SOLUTIONS:
      case ROUTES.USER.RESEARCH_SERVICES:
        return <ServicesSolutions className="mt-1" />;
      case ROUTES.USER.IDRA_RESEARCH:
      case ROUTES.USER.IDRA_ADVANCE:
      case ROUTES.USER.IDRA_DIGIS:
        return <IDRAPages className="mt-1" />;
      case ROUTES.USER.CAREER:
        return <CareersPage className="mt-1" />;
      case ROUTES.USER.CONTACT:
        return <ContactPage className="mt-1" />;
      case ROUTES.USER.WHO_WE_ARE:
        return <WhoWeArePage className="mt-1" />;
      case ROUTES.USER.HOMEPAGE:
        return <Homepage className="mt-1" />;
      case ROUTES.USER.ALUMNI_NETWORK:
        return <EditAlumniNetworkPage className="mt-1" />;
      default:
        return null;
    }
  };

  const handleSubmit = () => {
    const currentPayload = _.get(payload, editingPage);
    if (
      editingPage === ROUTES.USER.IDRA_ADVANCE ||
      editingPage === ROUTES.USER.IDRA_RESEARCH ||
      editingPage === ROUTES.USER.IDRA_DIGIS
    )
      dispatch(updateContentIDRAPages(currentPayload));
    if (editingPage === ROUTES.USER.CAREER)
      dispatch(updateContentCareerPage(currentPayload));
    if (editingPage === ROUTES.USER.CONTACT)
      dispatch(updateContentContactPage(currentPayload));
    if (editingPage === ROUTES.USER.WHO_WE_ARE)
      dispatch(updateContentWhoWeArePage(currentPayload));
    if (
      editingPage === ROUTES.USER.RESEARCH_SERVICES ||
      editingPage === ROUTES.USER.RESEARCH_SOLUTIONS ||
      editingPage === ROUTES.USER.DEVELOPMENT_SOLUTIONS
    )
      dispatch(updateServicesSolutions(currentPayload));
    if (editingPage === ROUTES.USER.HOMEPAGE)
      dispatch(updateContentHomepage(currentPayload));
    if (editingPage === ROUTES.USER.ALUMNI_NETWORK)
      dispatch(updateContentAlumniNetwork(currentPayload));
    dispatch(setStatusEdited(false));
  };

  const renderLastUpdated = () => {
    const lastUpdated = _.get(payload, `${editingPage}.updatedAt`);
    if (lastUpdated)
      return (
        <UpdatedLabel className="mt-1 mb-1">
          {t("label.lastUpdated")}:{" "}
          {new Date(lastUpdated).toLocaleString("en-US", {
            timeZone: timezone,
          })}
        </UpdatedLabel>
      );
  };

  const renderMain = () => {
    return (
      <ManageOrderArticlesContainer>
        <FlexHorizontal className="mb-1 pl-5 pr-5">
          <FlexHorizontal flex="1" alignCenter className="mr-1">
            <Label>{t("label.editingPage")}</Label>
            <AdminDropdown
              data={PAGES_DATA}
              width="200px"
              selectedValue={editingPage}
              onChange={(value: any) => handleChangeEditingPage(value)}
            />
          </FlexHorizontal>
          <FlexHorizontal className="mr-1" flex="1" alignCenter>
            <Label>{t("label.language")}</Label>
            <AdminDropdown
              data={DropdownOptions.LANGUAGE}
              width="120px"
              selectedValue={editingLanguage}
              onChange={(value: any) => dispatch(setLanguageEditing(value))}
            />
          </FlexHorizontal>
          <FlexHorizontal width="150px" alignCenter>
            <CarrotSolidButton onClick={() => handleSubmit()}>
              {t("button.save")}
            </CarrotSolidButton>
          </FlexHorizontal>
          <FlexHorizontal className="ml-2">
            {renderLastUpdated()}
          </FlexHorizontal>
        </FlexHorizontal>
        <ScrollContent>{renderContentEditable()}</ScrollContent>
      </ManageOrderArticlesContainer>
    );
  };

  return (
    <DefaultAdminLayout
      content={renderMain()}
      currentPage={ROUTES.ADMIN.CONTENT_EDITABLE}
      loading={requestIsLoading || mediaIsLoading}
    />
  );
};

export default ClientManagement;
