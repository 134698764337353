import styled from 'styled-components';
import { ContentContainer, VerticalContainer } from '../../../components/Common';
import { ColorName } from '../../../components/Variables';

const DashboardContainer = styled(ContentContainer)``;

const DashboardCardBar = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 18px;
`;

const TitleActivity = styled.h3`
  font-size: 22px;
  font-weight: 700;
  line-height: 32px;
  color: ${ColorName.darkElectricBlue};
`;

const DashboardCard = styled(VerticalContainer)`
  padding: 34px 30px;
  box-sizing: border-box;
  background: ${ColorName.aquaHaze};
  border-radius: 10px;
  border: 1px solid transparent;
  transition: all ease 0.25s;
  user-select: none;

  .icon img {
    height: 40px;
    object-fit: contain;
  }

  h2 {
    font-size: 34px;
    line-height: 36px;
    font-weight: 600;
    color: ${ColorName.darkElectricBlue};
  }

  span {
    display: block;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: ${ColorName.casper};
    margin-top: 14px;
    transition: all ease 0.25s;
  }

  &.border:hover {
    cursor: pointer;
    background: ${ColorName.azureishWhite};
    border: 1px solid ${ColorName.darkElectricBlue};

    span {
      color: ${ColorName.darkElectricBlue};
    }
  }
`;

export { DashboardContainer, DashboardCardBar, TitleActivity, DashboardCard };
